import React, { useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../../store/actions';
import { ReactComponent as PlusCircleIcon } from '../../../../assets/icons/plusCircleIcon.svg';
import { ReactComponent as MinusCircleIcon } from '../../../../assets/icons/minusCircleIcon.svg';
import useStyles from './styles';
import Loading from '../Loading';

const { fetchFaqList } = actions;

const Faq = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.user?.user?.language);
  const faqList = useSelector((state) => state.faq.list);
  const isLoading = useSelector(state=>state.faq.loading)
  

  const [currentItemAccordion, setCurrentItemAccordion] = React.useState([]);

  function handleAccordionClick(index) {
    if (currentItemAccordion.includes(index)) {
      setCurrentItemAccordion((prevState) =>
        prevState.filter((item) => item !== index),
      );
    } else {
      setCurrentItemAccordion((prevState) => [...prevState, index]);
    }
  }
  useEffect(() => {
    dispatch(fetchFaqList());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);
  
  if(isLoading&&!locale) return <Loading />
  return (
    <div className={classes.scrollbars}>
      <Scrollbars
        renderTrackVertical={(props) => (
          <div {...props} className={classes.trackVertical} />
        )}
        renderTrackHorizontal={(props) => (
          <div {...props} className={classes.trackHorizontal} />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className={classes.thumbHorizontal} />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className={classes.thumbVertical} />
        )}
        style={{ position: 'relative', height: '510px' }}
      >
        <div className={classes.accordionWrap}>
          {faqList?.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={
                  currentItemAccordion.includes(index) ? (
                    <MinusCircleIcon />
                  ) : (
                    <PlusCircleIcon />
                  )
                }
                aria-label='Expand'
                aria-controls={`additional-actions${index}-content`}
                id={`additional-actions${index}-header`}
                onClick={() => handleAccordionClick(index)}
              >
                {locale==="UA"?item.questionUA:item.questionEN}
              </AccordionSummary>
              <AccordionDetails>
                <Typography color='textSecondary'>{locale==="UA"?item.answerUA:item.answerEN}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Scrollbars>
    </div>
  );
};

export default Faq;
