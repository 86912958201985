import { makeStyles } from '@material-ui/styles';
import imgUrl from '../../../../assets/images/rect_26.png';
export const useStyles = makeStyles(() => ({
  videoContainer: {
    width: '100%',
  },
  noUrlContainer: {
    backgroundImage: `url(${imgUrl})`,
    backgroundSize:'cover',
    border: '1px solid black',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 360,
    flex: 1,
    borderRadius: 10,
    overflow: 'hidden',
  },
  messageContainer: {
    position: 'absolute',
    marginLeft:'auto',
    top: 85,
    color: 'white !important',
    width: 300,
    height: 180,
    backgroundColor: 'rgba(35, 149, 215, 0.8)',
    backdropFilter:"blur(2px)",
    display: 'flex',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontFamily: 'Inter',
    fontSize: '18px',
    padding: 28,
    fontWeight: 500,
    lineHeight: '23px',
    textAlign: 'center',
    paddingTop: 15,
    '&>svg': {
      marginBottom: 13,
    },
  },
}));
