import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { connectModal, hide } from 'redux-modal';
import { CHAT_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Chat from '../../global/Chat'
const ChatModal = ({show,...props}) => {
  const theme = useSelector((state) => state.theme.value);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
const handleClose=() => dispatch(hide(CHAT_MODAL))

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth="xl"
        className={classes.root}
      >
        <DialogContent className={classes.body} >
       
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
          <Chat 
          {...props}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: CHAT_MODAL,
  getModalState: (state) => state.modals,
})(ChatModal);
