import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  controlPanel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 10px 0 ',
    padding: '10px',
    background: '#E4EBF3',
    borderRadius: '5px',

    "@media (max-width: 480px)": {
      display: 'block',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  disabled: {
    color: 'lightgray',
    cursor: 'not-allowed',
  },
  controlPanelItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:first-child': {
      marginRight: '30px',
    },

    "@media (max-width: 480px)": {
      '&:first-child': {
        marginRight: '0',
        marginBottom: '10px',
        borderBottom: '1px solid #E4EBF3',
      },
      '&:last-child': {
        justifyContent: 'center',
      },
    },
  },
  controlPanelScale: {
    margin: '0 10px',
  },
}));

export default useStyles;