const MuiDialogContent = {
  root: {
    padding: '40px 65px',
    '&:first-child': {
      paddingTop: '40px',
    },

    '@media (max-width: 768px)': {
      padding: '40px 45px',
    },
  },
};

export default MuiDialogContent;
