import { makeStyles } from '@material-ui/styles';

import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  accordionWrap: {
    paddingRight: '15px',
    paddingBottom:100,

    '@media (max-width: 1440px)': {
    },
  },
  scrollbars: {
    position: 'relative',
    '&::before': {
      pointerEvents:"none",
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '120px',
      color: 'transparent',
      background:
        'linear-gradient(0deg, #FBFBFD 0%, rgba(251, 251, 253, 0) 100%)',
      zIndex: 1,
    },
  },
  trackVertical: {
    height: '100%',
    top: 0,
    right: 0,
    display: 'none',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
  thumbVertical: {
    borderRadius: 4,
    background: palette.common.grey,
    display: 'none',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
}));

export default useStyles;
