import { makeStyles } from '@material-ui/styles';
import palette from '../../../../theme/palette';

const useStyles = makeStyles(() => ({
  body: {
    width: '380px',
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: palette.common.sky,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    "@media (max-width: 480px)": {
      width: '340px',
    },
  },
  modalImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '65px',
    height: '65px',
    marginBottom: '20px',
    padding: '0',
    color: '#58727F',
    borderRadius: '50%',
    backgroundColor: palette.common.cream,
  },
  form: {
    width: '250px',
    marginTop: '30px',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    color: palette.primary.main,
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '16px',
  },
  checkboxLink: {
    fontWeight: '600',
  },
  visibility: {
    width: '16px',
    height: '16px',
    marginRight: '0',
    color: '#58727F',
  },
}));

export default useStyles;
