class StorageService {
    constructor() {
      this.storage = localStorage;
      this.reg_token = "fil_read_reg_token";
      this.token = "fil_read_token";
      this.uid = "fil_read_uid";
      this.session = "fil_read_session";
    }
  
    /* storage common actions */
    setItem(name, value) {
      if (name) this.storage.setItem(name, value);
    }
  
    getItem(name) {
      return this.storage.getItem(name) || "";
    }
  
    removeItem(name) {
      this.storage.removeItem(name);
    }
  
    /* storage token actions */
    getToken() {
      return this.storage.getItem(this.token) || "";
    }
  
    setToken(token) {
      if (token) this.storage.setItem(this.token, token);
    }
  
    removeToken() {
      this.storage.removeItem(this.token);
    }
  
    /* storage register token actions */
    getRegToken() {
      return this.storage.getItem(this.reg_token) || "";
    }
  
    setRegToken(token) {
      if (token) this.storage.setItem(this.reg_token, token);
    }
  
    removeRegToken() {
      this.storage.removeItem(this.reg_token);
    }
  
    /* storage login uid actions */
    getUID() {
      return this.storage.getItem(this.uid) || "";
    }
  
    setUID(uid) {
      if (uid) this.storage.setItem(this.uid, uid);
    }
  
    removeUID() {
      this.storage.removeItem(this.uid);
    }
  
    /* storage user session actions */
    getSession() {
      return JSON.parse(this.storage.getItem(this.session)) || "";
    }
  
    setSession(session) {
      if (session) this.storage.setItem(this.session, JSON.stringify(session));
    }
  
    removeSession() {
      this.storage.removeItem(this.session);
    }
  }
  
  export default new StorageService();
  