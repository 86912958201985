import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Layout from './common/layout';
import ElectronicPosters from './common/pages/electronicPosters';
import Error from './common/pages/error';
import Exhibition from './common/pages/exhibition';
import ExhibitionParticipant from './common/pages/exhibitionParticipant';
import Home from './common/pages/home';
import PersonalArea from './common/pages/personalArea';
import PrivacyPolicy from './common/pages/privacyPolicy';
import Program from './common/pages/program';
import PublicHome from './common/pages/publicHome';
import SatelliteSymposiums from './common/pages/satelliteSymposiums';
import Translation from './common/pages/translation';
import Webinars from './common/pages/webinars';
import routes from './common/routes';
import theme from './common/theme';
import { LanguageProvider } from './lang';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import PrivateRoute from '../src/common/components/global/PrivateRoute/PrivateRoute';
import { useSelector, useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import notify from './common/helpers/notify';
import { getParameterByName } from './common/helpers/getParameterByName';
import actions from './store/actions';
import { useHistory } from 'react-router-dom';
import StorageService from './services/StorageService';
import { MATCH_STATE } from './lang/locales';
import Materials from './common/pages/materials';
import { fetchContentsListAsync } from './store/actions/contentsActions';
import { setLocale } from './store/actions/localeActions';

const { setResetToken, fetchCurrentUserAsync, editLanguage } = actions;

function App() {
  const isAuth = useSelector((state) => state.user.isAuthorized);
  const resToken = useSelector((state) => state.user.resetToken);
  const locale = useSelector((state) => state.locale.language);
  const userLanguage = useSelector((state) => state.user?.user?.language);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const dispatch = useDispatch();
  // START DEVELOPMENT STAGE ERRORS - silence for prod
  const error = useSelector((state) => state.user.error);
  useEffect(() => {
    if (error && !error.message) {
      notify(error, 'error');
    } else if (error.message) {
      notify(error.message, 'error');
    }
  }, [error]);

  // END development stage errors (modify on prod)
  // Making sure that if local data is corrupted user will be fetched- relogged or logout
  const storageToken = StorageService.getToken();
  const storageSessiont = StorageService.getSession();

  const handleChangeLocal = (value, dispatch) => dispatch(setLocale(value));

  useEffect(() => {
    if (storageToken || storageSessiont) {
      fetchCurrentUserAsync(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const resetToken = getParameterByName('t');
    if (resetToken && !isAuth && !resToken) {
      dispatch(setResetToken(resetToken));
      history.push(routes.publicHome);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Match locale settings everywhere
  useEffect(() => {
    if (user && user?.language !== MATCH_STATE[locale]) {
      dispatch(editLanguage({ language: MATCH_STATE[locale] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);
  useEffect(() => {
    dispatch(fetchContentsListAsync);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userLanguage) {
      handleChangeLocal(userLanguage, dispatch);
    }
  }, [dispatch, userLanguage]);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        <LanguageProvider locale={locale || 'UA'}>
          <Layout>
            <Switch>
              <Route path={routes.publicHome} exact>
                {!isAuth && <PublicHome />}
                {isAuth && <Redirect to={routes.home} />}
              </Route>

              <PrivateRoute path={routes.home} exact>
                <Home />
              </PrivateRoute>
              <PrivateRoute path={routes.translation} exact>
                <Translation />
              </PrivateRoute>
              <PrivateRoute path={routes.translation + `/:hallId`}>
                <Translation />
              </PrivateRoute>
              <PrivateRoute path={routes.program} exact>
                <Program />
              </PrivateRoute>
              <PrivateRoute path={routes.electronicPosters} exact>
                <ElectronicPosters />
              </PrivateRoute>
              <PrivateRoute path={routes.exhibition} exact>
                <Exhibition />
              </PrivateRoute>
              <PrivateRoute path={routes.materials} exact>
                <Materials />
              </PrivateRoute>
              <PrivateRoute path={routes.exhibitionParticipant + `/:id`}>
                <ExhibitionParticipant />
              </PrivateRoute>
              <PrivateRoute path={routes.webinars} exact>
                <Webinars />
              </PrivateRoute>
              <PrivateRoute path={routes.satelliteSymposiums} exact>
                <SatelliteSymposiums />
              </PrivateRoute>
              <PrivateRoute path={routes.personalArea} exact>
                <PersonalArea />
              </PrivateRoute>
              <PrivateRoute path={routes.privacyPolicy} exact>
                <PrivacyPolicy />
              </PrivateRoute>

              {resToken && <Redirect to={routes.publicHome} />}
              <Route path='*'>
                <Error />
              </Route>
            </Switch>
          </Layout>
        </LanguageProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}
export default App;
