import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 35,
    paddingBottom: 35,
  },
  exhibitionTable: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: '30px',
    marginRight:-20,
    '@media (max-width: 768px)': {
      marginRight:0
    }
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '280px',
    height: '120px',
    marginBottom: '20px',
    padding: '10px 35px',
    marginRight:20,
    background: palette.common.cream,
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:`3px 2px 10px 0px ${palette.secondary.main}, 3px -2px 10px 0px ${palette.secondary.main},-3px -2px 10px 0px ${palette.secondary.main},-3px 2px 10px 0px ${palette.secondary.main},inset 0px 0px 15px -4px ${palette.secondary.main}`,
      // textDecoration:"underline",
      "&>svg":{
        color:"white",
        background:palette.secondary.main,
        borderRadius:"100%"
      },
    },
    

    '@media (max-width: 1440px)': {
      width: '300px',
    },
    '@media (max-width: 1000px)': {
      width: '340px',
    },
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      width: '100%',
      marginRight:0
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -25px 5px 0',
    color: '#58727F',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '16px',
  },
  titleText: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  image: {
    display: 'flex',
    justifyContent:'center',
    maxWidth: '190px',
    maxHeight: '75px',
    "& img":{
      objectFit:'contain'
    }
  },
  arrowRight: {
    position: 'absolute',
    right: '10px',
    bottom: '10px',
    color: palette.secondary.main,
  },
}));

export default useStyles;
