// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CLEAR_USER_ERROR:"CLEAR_USER_ERROR",
  ADD_DIPLOMA_LINK:"ADD_DIPLOMA_LINK",
  /* AUTH */
  FETCH_LOGIN_USER_START: "FETCH_LOGIN_USER_START",
  FETCH_LOGIN_USER_SUCCESS: "FETCH_LOGIN_USER_SUCCESS",
  FETCH_LOGIN_USER_ERROR: "FETCH_LOGIN_USER_ERROR",
  FETCH_REGISTER_USER_START: "FETCH_REGISTER_USER_START",
  FETCH_REGISTER_USER_SUCCESS: "FETCH_REGISTER_USER_SUCCESS",
  FETCH_REGISTER_USER_ERROR: "FETCH_REGISTER_USER_ERROR",
  LOGOUT_USER: "LOGOUT_USER",
  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  SET_RESET_TOKEN: "SET_RESET_TOKEN",
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  /* CURRENT USER */
  FETCH_CURRENT_USER_START: "FETCH_CURRENT_USER_START",
  FETCH_CURRENT_USER_SUCCESS: "FETCH_CURRENT_USER_SUCCESS",
  FETCH_CURRENT_USER_ERROR: "FETCH_CURRENT_USER_ERROR",

  /*EDIT USER FOR CABINET*/
  EDIT_USER_START: "EDIT_USER_START",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_ERROR: "EDIT_USER_ERROR",

  /*EDIT USER FOR CABINET*/
  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
};