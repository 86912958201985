import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Faq from '../../components/global/Faq';
import { useIntl } from 'react-intl';
import CompanyLogos from '../../components/global/CompanyLogos';
import useStyles from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Home = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const matches = useMediaQuery('(max-width:1440px)');
  return (
    <section className={classes.section}>
      <Container className={classes.wrapper}>
        <div className={classes.column}>
          <div className={classes.dearColleagues}>
            <Typography variant='h3'>
              {formatMessage({ id: 'home_title_1' })}
            </Typography>
            <Typography variant='h3' style={{ margin: '10px 0' }}>
              {formatMessage({ id: 'home_title_2' })}
            </Typography>
            {/* {formatMessage({id:"home_registration"})} */}
          </div>
          <div className={classes.companyLogos}>
            {!matches && <CompanyLogos />}
          </div>
        </div>
        <div className={classes.column}>
          <Typography variant='h3' style={{ marginBottom: '10px' }}>
            {formatMessage({ id: 'home_faq_title' })}
          </Typography>
          <Faq />
          {matches && <CompanyLogos />}
        </div>
      </Container>
    </section>
  );
};

export default Home;
