import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { show } from 'redux-modal';
import { SATELLITE_SYMPOSIUMS_MODAL } from '../../constants/modalNames';
import { useIntl } from 'react-intl';
import { ReactComponent as ArrowRightCircleIcon } from '../../../assets/icons/arrowRightCircleIcon.svg';
import SatelliteSymposiumsModal from '../../components/modals/SatelliteSymposiumsModal';
import actions from '../../../store/actions';
import DateFormat from '../../../services/DateFormats';
import { en, uk } from 'date-fns/locale';
import classNames from 'classnames';

const { fetchAllSymposiumListAsync } = actions;

const SatelliteSymposiums = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const symposiumList = useSelector((state) => state.symposiums.list);
  const language = useSelector((state) => state.user.user.language);
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(fetchAllSymposiumListAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  const title = language === 'UA' ? 'titleUA' : 'titleEN';
  const dayMonth = (item) =>
    DateFormat.parseDateToTime(item.startedAt * 1000, 'dd MMMM', {
      locale: language === 'UA' ? uk : en,
    });
  const startFinish = (item) =>
    `${DateFormat.parseDateToTime(
      item.startedAt * 1000,
    )} - ${DateFormat.parseDateToTime(item.endAt * 1000)}`;
  const hallTypes = [
    'Великий Зал',
    'Малий Зал',
    'Музейний Зал',
    'Великий Зал',
    'Малий Зал',
    'Музейний Зал',
  ];
  const hall = (item) => hallTypes[item.broadcastId - 1];
  return (
    <div>
      <SatelliteSymposiumsModal />
      <section className={classes.section}>
        <Container style={{ flexDirection: 'column' }}>
          <Typography variant='h3'>
            {formatMessage({ id: 'satellite_symposiums_title' })}
          </Typography>
          <div className={classes.symposiumsTable}>
            {symposiumList.map((item, index) => (
              <div
                className={classes.block}
                key={index}
                onClick={() =>
                  dispatch(show(SATELLITE_SYMPOSIUMS_MODAL, { item }))
                }
              >
                <div className={classes.partner}>
                  {formatMessage({ id: 'satellite_symposiums_partner' })}
                </div>
                <div className={classes.blockInner}>
                  <div className={classes.column}>
                    <div className={classes.image}>
                      <img
                        src={item.partner.logoURL}
                        alt={item[title]}
                        width={170}
                        height={60}
                        style={{ objectFit: 'contain' }}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames(classes.column, classes.titleColumn)}
                  >
                    <Typography className={classes.titleText}>
                      {`${dayMonth(item)}, ${startFinish(item)}, ${hall(item)}`}
                    </Typography>
                    <div>
                      <Typography className={classes.title} variant='h3'>
                        {item[title]}
                      </Typography>
                    </div>
                  </div>
                </div>
                <ArrowRightCircleIcon className={classes.arrowRight} />
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default SatelliteSymposiums;
