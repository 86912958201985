import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { connectModal, hide } from 'redux-modal';
import { FEEDBACK_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';
import useIntl from 'react-intl/lib/src/components/useIntl';
import Typography from '@material-ui/core/Typography';
import Flex from '../../global/Flex';
import FormInput from '../../global/FormInput';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import actions from '../../../../store/actions';
import notify from '../../../helpers/notify';
const { postFeedback } = actions;

const FeedbackModal = ({ show }) => {
  const partnerEmail = useSelector(
    (state) => state.partners.currentPartner.email,
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(formatMessage({ id: 'inputErrors_required' })),
    senderEmail: Yup.string()
      .email(formatMessage({ id: 'inputErrors_required_emil' }))
      .required(formatMessage({ id: 'inputErrors_required' })),
    message: Yup.string().required(
      formatMessage({ id: 'inputErrors_required' }),
    ),
  });
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    dispatch(
      postFeedback(
        { ...data, partnerEmail },
        () => {
          notify(formatMessage({ id: 'feedback_sent' }), 'success');
        },
        (error) => {
          notify(error, 'error');
        },
      ),
    );
    dispatch(hide(FEEDBACK_MODAL));
  };
  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(FEEDBACK_MODAL))}
        maxWidth='xl'
        className={classes.dialog}
      >
        <DialogContent className={classes.body}>
          <div
            className={classes.closer}
            onClick={() => dispatch(hide(FEEDBACK_MODAL))}
          >
            <span className={classes.closerText}>
              {formatMessage({ id: 'close_modal_button' })}
            </span>
            <CloseCircleIcon />
          </div>

          <Flex xs='100%'>
            <Typography className={classes.title} variant='h4'>
              {formatMessage({ id: 'feedback_form_title' })}
            </Typography>
          </Flex>

          <FormControl>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Flex spacing={20} container marginBottom column>
                <Flex
                  spacing={20}
                  container
                  between
                  className={classes.topInputContainer}
                >
                  <Flex xs='100%' tablet768='48%'>
                    <FormInput
                      error={errors.feedbackFirstName}
                      errors={errors}
                      name='name'
                      placeholder={formatMessage({ id: 'form_first_name' })}
                      control={control}
                      className={classes.feedbackFormInput}
                    />
                  </Flex>
                  <Flex xs='100%' tablet768='48%'>
                    <FormInput
                      error={errors.feedbackEmail}
                      errors={errors}
                      name='senderEmail'
                      autoComplete='email'
                      placeholder={formatMessage({ id: 'form_email' })}
                      control={control}
                      className={classes.feedbackFormInput}
                    />
                  </Flex>
                </Flex>
                <Flex xs='100%'>
                  <FormInput
                    error={errors.feedbackQuestion}
                    errors={errors}
                    name='message'
                    placeholder={formatMessage({ id: 'form_question' })}
                    control={control}
                    fullWidth
                    className={classes.feedbackFormTextArea}
                    multiline
                    rows={6}
                  />
                </Flex>
              </Flex>

              <Flex container className={classes.feedbackFormButtonWrapper}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.feedbackFormButton}
                >
                  {formatMessage({ id: 'form_send_button' })}
                </Button>
              </Flex>
            </form>
          </FormControl>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: FEEDBACK_MODAL,
  getModalState: (state) => state.modals,
})(FeedbackModal);
