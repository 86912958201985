import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../store/actions';
import Typography from '@material-ui/core/Typography';
import { connectModal, hide, show as showModal } from 'redux-modal';
import useIntl from 'react-intl/lib/src/components/useIntl';
import Flex from '../../global/Flex';
import {
  LOGIN,
  PASSWORD_RESTORE,
  SIGN_UP,
} from '../../../constants/modalNames';
import useStyles from './styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Button from '@material-ui/core/Button';
import FormInput from '../../global/FormInput';
import { ReactComponent as LoginIcon } from '../../../../assets/icons/loginIcon.svg';
import { ReactComponent as VisibilityOn } from '../../../../assets/icons/visibilityOn.svg';
import { ReactComponent as VisibilityOff } from '../../../../assets/icons/visibilityOff.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress, FormControl } from '@material-ui/core';
import {useHistory} from 'react-router-dom'

const { loginUserByNameAndPasswordAsync } = actions;
const LoginModal = ({ show }) => {
  const theme = useSelector((state) => state.theme.value);
  const history = useHistory()
  const isLoading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'inputErrors_required_emil' }))
      .required(formatMessage({ id: 'inputErrors_required' })),
    password: Yup.string()
      .min(8, formatMessage({ id: 'inputErrors_required_password' }))
      .required(formatMessage({ id: 'inputErrors_required' })),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, control, errors }=methods
  /*Password*/
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setVisiblePassword((visiblePassword) => !visiblePassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = data => {
    dispatch(
      loginUserByNameAndPasswordAsync({...data, password: data.password.trim()},history),
    );

  };
  return (
    <div>
      <Dialog open={show} onClose={() => dispatch(hide(LOGIN))} maxWidth='xl' className={classes.dialog}>
        <DialogContent className={classes.body}>
          {isLoading ? (
            <CircularProgress color='primary' />
          ) : (
            <>
              <div className={classes.modalImage}>
                <LoginIcon />
              </div>

              <Typography className={classes.title} variant='h4' align='center'>
                {formatMessage({ id: 'login_form_title' })}
              </Typography>
              <FormControl {...methods}>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Flex spacing={20} container marginBottom column>
                    <Flex xs='100%'>
                      <FormInput
                        error={errors.email}
                        errors={errors}
                        name='email'
                        placeholder={formatMessage({ id: 'form_email' })}
                        control={control}
                        fullWidth
                      />
                    </Flex>
                    <Flex xs='100%'>
                      <FormInput
                        type={visiblePassword ? 'text' : 'password'}
                        error={errors.password}
                        errors={errors}
                        name='password'
                        placeholder={formatMessage({ id: 'form_password' })}
                        control={control}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {visiblePassword ? (
                                  <VisibilityOn
                                    className={classes.visibility}
                                  />
                                ) : (
                                  <VisibilityOff
                                    className={classes.visibility}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Flex>
                    <Flex container>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        style={{
                          width: '100%',
                          height: '50px',
                        }}
                      >
                        {formatMessage({ id: 'login_form_button_enter' })}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex flex between>
                    <Button
                      variant='text'
                      color='primary'
                      onClick={() => {
                        dispatch(showModal(PASSWORD_RESTORE));
                        setTimeout(() => {
                          dispatch(hide(LOGIN));

                        }, 500);
                      }}
                    >
                      {formatMessage({ id: 'form_forgot_password' })}
                    </Button>
                    <Button
                      variant='text'
                      color='primary'
                      onClick={() => {
                        dispatch(showModal(SIGN_UP));
                        setTimeout(() => {
                          dispatch(hide(LOGIN));

                        }, 500);
                      }}
                    >
                      {formatMessage({ id: 'form_registration' })}
                    </Button>
                  </Flex>
                </form>
              </FormControl>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: LOGIN,
  getModalState: (state) => state.modals,
})(LoginModal);
