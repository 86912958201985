import Container from '@material-ui/core/Container';
import React from 'react';
import { useSelector } from 'react-redux';
import routes from '../../routes';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

const Error = () => {
  const theme = useSelector((state) => state.theme.value);
  const classes = useStyles({ theme });
  const { push, goBack } = useHistory();

  return (
    <section className={classes.section}>
      <Container className={classes.errorWrapper}>
        <div className={classes.errorTiTle}>404</div>
        <div className={classes.errorSubTiTle}>
          На жаль такої сторінки не існує
        </div>
        <div className={classes.errorButtons}>
          <Button
            variant="contained"
            color="primary"
            onClick={goBack}
            style={{
              width: '140px',
              height: '40px',
              marginRight: '20px',
            }}
          >
            Повернутися
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => push(routes.home)}
            style={{
              width: '140px',
              height: '40px',
            }}
          >
            На головну
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default Error;
