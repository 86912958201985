import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { connectModal, hide} from 'redux-modal';
import { SATELLITE_SYMPOSIUMS_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';
import useIntl from 'react-intl/lib/src/components/useIntl';
import VideoPlayer from '../../global/VideoPlayer';

const SatelliteSymposiumsModal = ({ show, item }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const language = useSelector((state) => state.user.user.language);
  const videoURL = language === 'UA' ? 'offlineUrlUA' : 'offlineUrlEN';
  const moderator = language === 'UA' ? 'moderatorUA' : 'moderatorEN';
  const executiveGroup =
    language === 'UA' ? 'executiveGroupUA' : 'executiveGroupEN';
  const title = language === 'UA' ? 'titleUA' : 'titleEN';
  return (
    <div>
      {!!item && (
        <Dialog
          open={show}
          onClose={() => dispatch(hide(SATELLITE_SYMPOSIUMS_MODAL))}
          maxWidth='xl'
        >
          <DialogContent className={classes.body}>
            <div
              className={classes.closer}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(hide(SATELLITE_SYMPOSIUMS_MODAL));
              }}
            >
              <span className={classes.closerText}>
                {formatMessage({ id: 'close_modal_button' })}
              </span>
              <CloseCircleIcon />
            </div>
            <div className={classes.satelliteModalWrapper}>
              <Typography className={classes.title} variant='h3'>
                {item[title]}
              </Typography>
              <div className={classes.satelliteModalInfo}>
                <div>
                  <span>
                    {formatMessage({ id: 'program_modal_moderator' })}:
                  </span>
                  {item[moderator]}
                </div>
                <div>
                  <span>{formatMessage({ id: 'program_modal_pres' })}:</span>
                  {item[executiveGroup]}
                </div>
              </div>
              <div className={classes.satelliteModalInner}>
                <div className={classes.videoControl}>
                  <div className={classes.video}>
                    <VideoPlayer
                      url={item[videoURL]}
                      width={'100%'}
                      controls
                      style={{ flex: 1, borderRadius: 10, overflow: 'hidden' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default connectModal({
  name: SATELLITE_SYMPOSIUMS_MODAL,
  getModalState: (state) => state.modals,
})(SatelliteSymposiumsModal);
