// eslint-disable-next-line import/no-anonymous-default-export
export default {
    WS_CONNECT:"WS_CONNECT",
    WS_CONNECTING:"WS_CONNECTING",
    WS_CONNECTED:"WS_CONNECTED",
    WS_DISCONNECT:"WS_DISCONNECT",
    WS_DISCONNECTED:"WS_DISCONNECTED",
    // Chat types
    WS_JOIN:"WS_JOIN",
    // WS_SEND_MESSAGE:"WS_SEND_MESSAGE", // disabled for now
  };
  
  