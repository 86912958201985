import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ReactComponent as DownIcon } from '../../assets/icons/downIcon.svg';
import { ReactComponent as FileTextIcon } from '../../assets/icons/filetextIcon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/userIcon.svg';
import { ReactComponent as MobileMenuIcon } from '../../assets/icons/mobileMenuIcon.svg';
import Flex from '../components/global/Flex';
import Switcher from '../components/global/Switcher';
import routes from '../routes';
import useStyles from './styles';
import MobileMenu from './MobileMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MOBILE_MENU } from '../constants/modalNames';
import { show } from 'redux-modal';
// Actions
import actions from '../../store/actions';
import history from '../helpers/HistoryHelper';
const { logoutUser } = actions;

const Header = () => {
  const { formatMessage } = useIntl();
  const [scientificProgramEl, setScientificProgramEl] = useState(null);
  const [partnerVideosEl, setPartnerVideosEl] = useState(null);
  const [personalAreaAvatarEl, setPersonalAreaAvatarEl] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const theme = useSelector((state) => state.theme.value);
  const tablet1000 = useMediaQuery('@media (max-width: 1000px)');
  const classes = useStyles({
    isMenuFirstOpen: Boolean(scientificProgramEl),
    isMenuSecondOpen: Boolean(partnerVideosEl),
    isMenuThirdOpen: Boolean(personalAreaAvatarEl),
    theme,
  });

  return (
    <header className={classes.header}>
      <MobileMenu />
      <Container className={classes.headerInner}>
        {tablet1000 && (
          <div
            onClick={() => dispatch(show(MOBILE_MENU))}
            className={classes.mobileMenuToggle}
          >
            <MobileMenuIcon />
          </div>
        )}
        <div className={classes.headerLeft}>
          <Flex>
            <NavLink className={classes.menuItem} to={routes.home}>
              {formatMessage({ id: 'header_menu_home' })}
            </NavLink>
          </Flex>
          <Flex>
            <NavLink className={classes.menuItem} to={routes.translation}>
              {formatMessage({ id: 'header_menu_translation' })}
            </NavLink>
          </Flex>
          <Flex>
            <span
              onClick={(e) => setScientificProgramEl(e.currentTarget)}
              className={classes.menuItem}
            >
              {formatMessage({ id: 'header_menu_scientific_program' })}
              <DownIcon
                className={`${classes.chevron} ${classes.chevronFirst}`}
              />
            </span>
            <Menu
              anchorEl={scientificProgramEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={Boolean(scientificProgramEl)}
              onClose={() => setScientificProgramEl(null)}
            >
              <MenuItem onClick={() => setScientificProgramEl(null)}>
                <NavLink to={routes.program} className={classes.menuSubItem}>
                  {formatMessage({ id: 'header_menu_program' })}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={() => setScientificProgramEl(null)}>
                <NavLink
                  to={routes.electronicPosters}
                  className={classes.menuSubItem}
                >
                  {formatMessage({ id: 'header_menu_electronic_posters' })}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={() => setScientificProgramEl(null)}>
                <NavLink to={routes.materials} className={classes.menuSubItem}>
                  {formatMessage({ id: 'header_menu_materials' })}
                </NavLink>
                {/* <a
                  href="/files/others/materials.pdf" target="_blank"
                  className={classes.menuSubItem}
                >
                  {formatMessage({ id: 'header_menu_materials' })}
                </a> */}
              </MenuItem>
            </Menu>
          </Flex>
          <Flex>
            <NavLink className={classes.menuItem} to={routes.exhibition}>
              {formatMessage({ id: 'header_menu_exhibition' })}
            </NavLink>
          </Flex>
          <Flex>
            <span
              onClick={(e) => setPartnerVideosEl(e.currentTarget)}
              className={`${classes.menuItem} ${classes.menuItemLast}`}
            >
              {formatMessage({ id: 'header_menu_partner_videos' })}
              <DownIcon
                className={`${classes.chevron} ${classes.chevronSecond}`}
              />
            </span>
            <Menu
              anchorEl={partnerVideosEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={Boolean(partnerVideosEl)}
              onClose={() => setPartnerVideosEl(null)}
            >
              <MenuItem onClick={() => setPartnerVideosEl(null)}>
                <NavLink
                  to={routes.satelliteSymposiums}
                  className={classes.menuSubItem}
                >
                  {formatMessage({ id: 'header_menu_satellite_symposiums' })}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={() => setPartnerVideosEl(null)}>
                <NavLink to={routes.webinars} className={classes.menuSubItem}>
                  {formatMessage({ id: 'header_menu_webinars' })}
                </NavLink>
              </MenuItem>
            </Menu>
          </Flex>
        </div>
        <div className={classes.headerRight}>
          <Switcher />
          <Flex>
            <span
              onClick={(e) => setPersonalAreaAvatarEl(e.currentTarget)}
              className={classes.profileLink}
            >
              <div className={classes.personalAreaAvatar}>
                <UserIcon />
              </div>
              {user && `${user.firstName} ${user.lastName}`}
              <DownIcon
                className={`${classes.chevron} ${classes.chevronThird}`}
              />
            </span>
            <Menu
              anchorEl={personalAreaAvatarEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              keepMounted
              open={Boolean(personalAreaAvatarEl)}
              onClose={() => setPersonalAreaAvatarEl(null)}
            >
              <MenuItem onClick={() => setPersonalAreaAvatarEl(null)}>
                <div className={classes.personalAreaAvatarInner}>
                  <NavLink
                    className={classes.subItemPersonalArea}
                    to={routes.personalArea}
                  >
                    <FileTextIcon className={classes.fileTextIcon} />
                    {formatMessage({ id: 'header_personal_area' })}
                  </NavLink>
                  <Button
                    onClick={() => {
                      dispatch(logoutUser());
                      history.push('/');
                    }}
                    variant='contained'
                    color='primary'
                    style={{
                      width: '60px',
                      height: '25px',
                      marginLeft: '24px',
                      fontSize: '12px',
                    }}
                  >
                    {formatMessage({ id: 'header_exit' })}
                  </Button>
                </div>
              </MenuItem>
            </Menu>
          </Flex>
        </div>
      </Container>
    </header>
  );
};

export default Header;
