import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import { connectModal, hide } from 'redux-modal';
import { ELECTRONIC_POSTERS_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import PDFReader from '../../global/PDFReader';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';
import useIntl from 'react-intl/lib/src/components/useIntl';

const ElectronicPostersModal = ({
    show,fileUrl,title,moderator,adminGroup
  }) => {
  const theme = useSelector((state) => state.theme.value);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();
  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(ELECTRONIC_POSTERS_MODAL))}
        maxWidth="xl"
      >
        <DialogContent className={classes.body}>
          <div className={classes.closer} onClick={() => dispatch(hide(ELECTRONIC_POSTERS_MODAL))}>
            <span className={classes.closerText}>{formatMessage({ id: "close_modal_button" })}</span>
            <CloseCircleIcon />
          </div>
          <div className={classes.posterWrapper}>
            <Typography className={classes.title}  variant="h3">
              {title}
            </Typography>
            <div className={classes.posterInfo}>
              {/* Spacer */}
            </div>
            <PDFReader src={fileUrl}/>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: ELECTRONIC_POSTERS_MODAL,
  getModalState: (state) => state.modals,
})(ElectronicPostersModal);
