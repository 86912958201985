import userTypes from '../actionTypes';
// services
import ApiService from '../../services/ApiService';
import StorageService from '../../services/StorageService';
import notify from '../../common/helpers/notify';
import { hide, show } from 'redux-modal';
import {
  SIGN_UP,
  CONFIRM_SUCCESS,
  CONFIRM_ERROR,
  PASSWORD_RESTORE,
  ADD_NEW_PASSWORD,
  PERSONAL_AREA_CHANGE_PASSWORD,
  PERSONAL_AREA_SUCCESS_CHANGE_PASSWORD,
} from '../../common/constants/modalNames';
import routes from '../../common/routes';

export const clearUserError = () => ({
  type: userTypes.CLEAR_USER_ERROR,
});
const fetchRegisterUserStart = (user) => ({
  type: userTypes.FETCH_REGISTER_USER_START,
  payload: user,
});

const fetchRegisterUserSuccess = () => ({
  type: userTypes.FETCH_REGISTER_USER_SUCCESS,
});

const fetchRegisterUserError = (error) => ({
  type: userTypes.FETCH_REGISTER_USER_ERROR,
  payload: error,
});
export const registerWithEmailPasswordAsync = (data) => (dispatch) => {
  dispatch(fetchRegisterUserStart());

  ApiService.apiCall({
    url: ApiService.paths.user.REGISTER,
    method: 'POST',
    data,
  })
    .then((response) => {
      if (response?.response?.data?.message === 'DUPLICATED_EMAIL_FOUND') {
        dispatch(
          fetchRegisterUserError({
            message:
              'User with this @mail already registered! - Користувач з такою електронною поштою вже зареєстрований!',
          }),
        );
      } else {
        const { token } = response.data;

        StorageService.setRegToken(token);
        dispatch(fetchRegisterUserSuccess());
        dispatch(hide(SIGN_UP));
        dispatch(show(CONFIRM_SUCCESS, { type: 'regSuccess' }));
      }
    })
    .catch((error) => {
      dispatch(fetchRegisterUserError(error));
    });
};

const fetchLoginUserStart = () => ({
  type: userTypes.FETCH_LOGIN_USER_START,
});

const fetchLoginUserSuccess = () => ({
  type: userTypes.FETCH_LOGIN_USER_SUCCESS,
});

const fetchLoginUserError = (error) => ({
  type: userTypes.FETCH_LOGIN_USER_ERROR,
  payload: error,
});

export const loginUserByNameAndPasswordAsync =
  (data, history) => (dispatch) => {
    dispatch(fetchLoginUserStart());
    ApiService.apiCall({
      url: ApiService.paths.user.LOGIN,
      method: 'POST',
      data: data,
    })
      .then((response) => {
        if (response?.response?.status === 405) {
          dispatch(
            fetchLoginUserError({
              message:
                'Обліковий запис не активований адміном. Звяжіться з адміністрацією.',
            }),
          );
        } else {
          const { data } = response;
          StorageService.setToken(data.token);
          dispatch(fetchLoginUserSuccess());
          // in case API
          fetchCurrentUserAsync(dispatch);
        }
        setTimeout(() => {
          history.push(routes.home);
        }, 300);
      })
      .catch((error) => {
        dispatch(fetchLoginUserError({ message: 'Не вірний логін чи пароль' }));
      });
  };

const fetchCurrentUserStart = () => ({
  type: userTypes.FETCH_CURRENT_USER_START,
});

const fetchCurrentUserSuccess = (user) => ({
  type: userTypes.FETCH_CURRENT_USER_SUCCESS,
  payload: user,
});

// const fetchCurrentUserError = (error) => ({
//   type: userTypes.FETCH_CURRENT_USER_ERROR,
//   payload: error,
// });

export const fetchCurrentUserAsync = (dispatch) => {
  dispatch(fetchCurrentUserStart());

  ApiService.apiCall({
    url: ApiService.paths.user.CURRENT_USER,
    token: true,
  })
    .then((response) => {
      const item = response.data.currentUser;
      dispatch(fetchCurrentUserSuccess(item));
      StorageService.setSession(item);
    })
    .catch((error) => {
      // dispatch(fetchCurrentUserError(error)); // keep commented out for production
      dispatch(logoutUser());
    });
};

/*Edit User for Cabinet*/
const editUserStart = () => ({
  type: userTypes.EDIT_USER_START,
});

const editUserSuccess = (user) => ({
  type: userTypes.EDIT_USER_SUCCESS,
  payload: user,
});

const editUserError = (error) => ({
  type: userTypes.EDIT_USER_ERROR,
  payload: error,
});

export const editUserAsync = (data) => {
  return (dispatch) => {
    dispatch(editUserStart());

    ApiService.apiCall({
      url: ApiService.paths.user.EDIT_USER,
      method: 'PUT',
      token: true,
      data,
    })
      .then((response) => {
        const item = response?.data?.user;
        if (item) {
          dispatch(editUserSuccess(item));
          StorageService.setSession(item);
          notify('Профайл змінено! - Changes applied!', 'success');
        }
        if (response?.response?.data?.message === 'DUPLICATED_EMAIL_FOUND') {
          notify(
            'Аддресса електронної пошти використовується іншим користувачем - Email address is used by another user.',
            'error',
          );
        }
      })
      .catch((error) => {
        dispatch(editUserError(error));
      });
  };
};
export const editLanguage = (data) => {
  return (dispatch) => {
    dispatch(editUserStart());

    ApiService.apiCall({
      url: ApiService.paths.user.EDIT_USER,
      method: 'PUT',
      token: true,
      data,
    })
      .then((response) => {
        const item = response.data.user;
        dispatch(editUserSuccess(item));
        StorageService.setSession(item);
      })
      .catch((error) => {
        dispatch(editUserError(error));
      });
  };
};

export const logoutUser = () => (dispatch) => {
  dispatch({ type: userTypes.LOGOUT_USER });
  StorageService.removeToken();
  StorageService.removeUID();
  StorageService.removeSession();
  // notify('Logout Success!');
};

export const forgotPasswordStart = () => ({
  type: userTypes.FORGOT_PASSWORD_START,
});

export const forgotPasswordSuccess = (email) => ({
  type: userTypes.FORGOT_PASSWORD_SUCCESS,
  payload: email,
});

export const forgotPasswordError = (message) => ({
  type: userTypes.FORGOT_PASSWORD_ERROR,
  payload: { message },
});
export const forgotPasswordAsync = (data) => (dispatch) => {
  dispatch(forgotPasswordStart());
  ApiService.apiCall({
    url: ApiService.paths.user.FORGOT_PASSWORD,
    token: true,
    method: 'POST',
    data: data,
  })
    .then((response) => {
      const item = response?.data?.status;
      if (response?.response?.status === 403) {
        notify(
          'Пошта вже була відправлена на цю адресу! - Email to this address was sent before!',
          'error',
        );
      }
      if (response?.response?.status === 400) {
        dispatch(forgotPasswordError('Email not found'));
        dispatch(show(CONFIRM_ERROR));
        setTimeout(() => {
          dispatch(hide(CONFIRM_ERROR));
        }, 5000);
      } else {
        dispatch(forgotPasswordSuccess(item));
        dispatch(show(CONFIRM_SUCCESS, { type: 'resetSuccess' }));
        setTimeout(() => {
          dispatch(hide(CONFIRM_SUCCESS));
        }, 5000);
      }
    })
    .catch((error) => {
      dispatch(forgotPasswordError(error));
      dispatch(show(CONFIRM_ERROR));
    })
    .finally(() => {
      dispatch(hide(PASSWORD_RESTORE));
    });
};

export const setResetToken = (resetToken) => ({
  type: userTypes.SET_RESET_TOKEN,
  payload: resetToken,
});

const resetPasswordStart = () => ({
  type: userTypes.RESET_PASSWORD_START,
});
const resetPasswordSuccess = () => ({
  type: userTypes.RESET_PASSWORD_SUCCESS,
});
const resetPasswordError = (message) => ({
  type: userTypes.RESET_PASSWORD_ERROR,
  payload: { message },
});
export const resetPasswordAsync =
  ({ resetToken, password }) =>
  (dispatch) => {
    dispatch(resetPasswordStart());
    ApiService.apiCall({
      url: ApiService.paths.user.RESET_PASSWORD,
      method: 'POST',
      data: { passwordResetToken: resetToken, password },
    })
      .then((response) => {
        dispatch(resetPasswordSuccess());
        dispatch(show(CONFIRM_SUCCESS, { type: 'restoreSuccess' }));
      })
      .catch((error) => {
        dispatch(resetPasswordError('Сталася помилка'));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(hide(ADD_NEW_PASSWORD));
          dispatch(hide(CONFIRM_SUCCESS));
        }, 3000);
      });
  };

/*Change Password for Cabinet*/
const changePasswordStart = () => ({
  type: userTypes.CHANGE_PASSWORD_START,
});

const changePasswordSuccess = () => ({
  type: userTypes.CHANGE_PASSWORD_SUCCESS,
});

const changePasswordError = (message) => ({
  type: userTypes.CHANGE_PASSWORD_ERROR,
  payload: { message },
});

export const changePasswordAsync = (data) => {
  const { oldPassword, newPassword } = data;

  return (dispatch) => {
    dispatch(changePasswordStart());

    ApiService.apiCall({
      url: ApiService.paths.user.CHANGE_PASSWORD,
      method: 'POST',
      token: true,
      data: { oldPassword, newPassword },
    })
      .then((response) => {
        const item = response.data.user;
        dispatch(changePasswordSuccess(item));
        dispatch(hide(PERSONAL_AREA_CHANGE_PASSWORD));
        dispatch(
          show(PERSONAL_AREA_SUCCESS_CHANGE_PASSWORD, {
            type: 'restoreSuccess',
          }),
        );
      })
      .catch((error) => {
        dispatch(changePasswordError(error));
      });
  };
};
const addDiplomaLink = (data) => ({
  type: userTypes.ADD_DIPLOMA_LINK,
  payload: data,
});

export const fetchGetDiploma = () => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.user.GET_DIPLOMA,
    token: true,
  })
    .then((response) => {
      dispatch(addDiplomaLink(response.data.url));
    })
    .catch((error) => {
      notify('Error!', 'error');
    });
};
export const fetchSendDiploma = () => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.user.SEND_DIPLOMA,
    method: 'POST',
    token: true,
  })
    .then((response) => {
      if (response?.data?.status) {
        notify('Сертифікат надіслано! - Certificate has been sent');
      } else {
        throw Error;
      }
    })
    .catch((error) => {
      notify('Error!', 'error');
    });
};
