import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'react-datepicker';
import { ErrorMessage } from '@hookform/error-message';
import useStyles from './styles';
import 'react-datepicker/dist/react-datepicker.css';

const FormInput = (props) => {
  const classes = useStyles();
  const { control, name, errors, type, label, value } = props;

  if (type === 'date') {
    return (
      <div>
        <Controller
          name={name}
          control={control}
          as={() => (
            <div className={classes.wrapper}>
              <DatePicker
                maxDate={new Date()}
                selected={value ? new Date(value) : new Date()}
                onChange={(date) => control.setValue(name, date)}
                showYearDropdown
                yearDropdownItemNumber={100}
                dateFormat='dd/MM/yyyy'
                scrollableYearDropdown
                customInput={
                  <TextField
                    label={label}
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                }
              />
            </div>
          )}
        />
        <ErrorMessage
          render={({ message }) => (
            <div style={{ color: 'red' }}>{message}</div>
          )}
          errors={errors}
          name={name}
        />
      </div>
    );
  }

  return (
    <div>
      <Controller
        name={name}
        control={control}
        as={(props) => <TextField {...props} />}
        label={label}
        variant='outlined'
        autoComplete='off'
        {...props}
      />
      <ErrorMessage
        render={({ message }) => <div style={{ color: 'red' }}>{message}</div>}
        errors={errors}
        name={name}
      />
    </div>
  );
};

export default FormInput;
