import React, { useState, useEffect, useMemo } from 'react';
import Chat from '../../../common/components/global/Chat';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Loading from '../../components/global/Loading';
import VideoPlayer from '../../components/global/VideoPlayer';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { show } from 'redux-modal';
import { CHAT_MODAL } from '../../constants/modalNames';
import actions from '../../../store/actions';

import { useParams } from 'react-router';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as MessageCircle } from '../../../assets/icons/messageCircle.svg';
import ChatModal from '../../components/modals/ChatModal';
import useIntl from 'react-intl/lib/src/components/useIntl';
import useStyles from './styles';
import ProgramList from '../../components/global/ProgramList';
import ViewersCount from '../../components/global/ViewersCount';
import { LOCALES } from '../../../lang/locales';
import { fetchAllProgramListAsync } from '../../../store/actions/programActions';
const { fetchProgramListByHallIdAsync } = actions;

const getBroadcastEndTime = (hallInfo) => {
  if (hallInfo) {
    let lastElem;
    let state = true;
    for (let key in hallInfo.sessions) {
      if (hallInfo?.sessions[key]?.endAt) {
        lastElem = hallInfo?.sessions[key];
      }
    }

    if (!lastElem?.endAt) {
      state = false;
    }

    return { time: lastElem?.endAt, state };
  }
};
// Helpers
const isItOffline = (hallInfo, setState) => {
  let broadcastEndTime = getBroadcastEndTime(hallInfo);

  let timeNow = new Date().getTime(); //Localized time of NOW
  let isOffline = false;

  if (timeNow > broadcastEndTime?.time || !broadcastEndTime?.state) {
    setState(!isOffline);
  }
};
// Helper to chose from videos/ids
const chooseUrlID = (broadCastData, locale, type, offline) => {
  const {
    offlineUrlEN,
    offlineUrlUA,
    onlineUrlEN,
    onlineUrlUA,
    onlineIdEN,
    onlineIdUA,
    offlineIdEN,
    offlineIdUA,
  } = broadCastData;
  const isOffline = offline ? 'offline' : 'online';
  const urIds = {
    [LOCALES.UKRAINIAN]: {
      online: {
        url: onlineUrlUA,
        id: onlineIdUA,
      },
      offline: {
        url: offlineUrlUA,
        id: offlineIdUA,
      },
    },
    [LOCALES.ENGLISH]: {
      online: {
        url: onlineUrlEN,
        id: onlineIdEN,
      },
      offline: {
        url: offlineUrlEN,
        id: offlineIdEN,
      },
    },
  };
  return urIds[locale][isOffline][type];
};

const localizeInitDataViewers = (
  offlineViewCountEN,
  offlineViewCountUA,
  onlineConcurrentViewersEN,
  onlineConcurrentViewersUA,
  locale,
) => {
  const localizedData = {
    [LOCALES.UKRAINIAN]: {
      concurrentViewers: onlineConcurrentViewersUA,
      viewCount: offlineViewCountUA,
    },
    [LOCALES.ENGLISH]: {
      concurrentViewers: onlineConcurrentViewersEN,
      viewCount: offlineViewCountEN,
    },
  };
  return localizedData[locale];
};

const Translation = () => {
  const classes = useStyles();
  const params = useParams();

  // Redux
  const dispatch = useDispatch();
  const {
    activeTab: currentTab,
    reqTab,
    list: programList,
    loading,
    offlineViewCountEN,
    offlineViewCountUA,
    onlineConcurrentViewersEN,
    onlineConcurrentViewersUA,
    ...broadcastData
  } = useSelector((state) => state.broadcasts);

  const locale = useSelector((state) => state.locale.language);
  const { formatMessage } = useIntl();

  const [offline, setOffline] = useState(false);
  // const [day, setDay] = useState(currentTab > 3 ? 2 : 1);
  // Media-queries
  const tablet768 = useMediaQuery('@media (max-width:1000px)');
  const mobile375 = useMediaQuery('@media (max-width:768px');
  const break560 = useMediaQuery('@media (max-width:580px)');
  const mobileSmall = useMediaQuery('@media (max-width:375px');
  // Data for children Components
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let urId = useMemo(
    () => chooseUrlID(broadcastData, locale, 'id', offline),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [broadcastData, currentTab],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let url = useMemo(
    () => chooseUrlID(broadcastData, locale, 'url', offline),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [broadcastData, currentTab],
  );

  // Localization dynamically
  let concurrentViewer = localizeInitDataViewers(
    offlineViewCountEN,
    offlineViewCountUA,
    onlineConcurrentViewersEN,
    onlineConcurrentViewersUA,
    locale,
  ).concurrentViewers;

  let viewCount = localizeInitDataViewers(
    offlineViewCountEN,
    offlineViewCountUA,
    onlineConcurrentViewersEN,
    onlineConcurrentViewersUA,
    locale,
  ).viewCount;

  const getDayIndex = (data) => {
    let dayId;
    data?.length > 0 &&
      data?.forEach((day) => {
        day?.halls.forEach((hall) => {
          if (+hall.id === +params?.hallId) {
            dayId = +day.id;
          }
        });
      });

    return dayId;
  };

  const programs = useSelector((state) => state.programs.list);

  const [activeDayHallInfo, setActiveDayHallInfo] = useState({
    activeDayId: null,
    activeHallId: +params.hallId ? +params.hallId : null,
  });

  const [isFirstTouchForDay, setIsFirstTouchForDay] = useState(false);

  const { activeDayId, activeHallId } = activeDayHallInfo;

  const activeDayIndex = programs?.map((item) => +item.id).indexOf(activeDayId);

  const language = useSelector((state) => state.user.user.language);

  const activeHallIndex =
    programs &&
    programs[activeDayIndex]?.halls
      ?.map((item) => +item.id)
      .indexOf(activeHallId);

  const activeHallInfo =
    programs && programs[activeDayIndex]?.halls[activeHallIndex];

  useEffect(() => {
    if (programs?.length > 0) {
      if (!params?.hallId || (params.hallId && isFirstTouchForDay)) {
        setActiveDayHallInfo({
          ...activeDayHallInfo,
          activeHallId: programs[activeDayIndex]?.halls[0]?.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDayId]);

  useEffect(() => {
    if (programs?.length > 0) {
      fetchProgramListByHallIdAsync(
        dispatch,
        activeHallInfo?.hallId,
        activeHallInfo?.id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHallInfo?.hallId, dispatch]);

  useEffect(() => {
    isItOffline(activeHallInfo, setOffline);
  }, [activeHallId, activeHallInfo]);

  useEffect(() => {
    dispatch(fetchAllProgramListAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (+params.hallId) {
      setActiveDayHallInfo({
        ...activeDayHallInfo,
        activeDayId: getDayIndex(programs),
      }); //-
    } else {
      if (programs?.length > 0) {
        const activeDayIndex =
          programs &&
          programs?.map((item) => +item.id).indexOf(programs[0]?.id);
        setActiveDayHallInfo({
          activeDayId: programs[0]?.id,
          activeHallId: programs[activeDayIndex]?.halls[0]?.id,
        }); //-
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programs]);

  const getHallCount = (programs) => {
    let count = 0;
    programs &&
      programs?.forEach((program) => {
        program?.halls?.forEach((hall) => {
          hall && count++;
        });
      });

    return count;
  };

  const hallsCount = getHallCount(programs);

  if (!programs) {
    return <Loading />;
  } else if (programs && hallsCount < 1) {
    return (
      <div style={{ marginTop: 20, textAlignLast: 'center' }}>
        Трансляции отсуствуют
      </div>
    );
  }

  return (
    <div>
      <section className={classes.section}>
        <Grid container className={classes.container}>
          <Grid item className={classes.videoControl}>
            <Box className={classes.videoControlHeader}>
              <Box className={classes.topButtonContainer}>
                {programs?.length > 0 &&
                  programs?.map((item, index) => {
                    let date = new Date(item.dateValue);

                    if (item?.halls.length < 1) {
                      return null;
                    }
                    return (
                      <Button
                        key={index}
                        variant='contained'
                        color={
                          activeDayId === item.id ? 'primary' : 'secondary'
                        }
                        className={classes.button}
                        onClick={() => {
                          setActiveDayHallInfo({
                            ...activeDayHallInfo,
                            activeDayId: item.id,
                          });
                          setIsFirstTouchForDay(true);
                        }}
                      >
                        {date.toLocaleString(
                          language === 'UA' ? 'uk' : 'en-US',
                          { month: 'long', day: 'numeric' },
                        )}
                      </Button>
                    );
                  })}
              </Box>

              <ViewersCount
                videoId={urId}
                initData={{ concurrentViewer, viewCount }}
              />
            </Box>
            <Box className={classes.video}>
              {loading && (
                <Box
                  style={{
                    flex: 1,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Loading />
                </Box>
              )}
              <VideoPlayer
                url={url}
                loading={loading}
                width={'100%'}
                controls
                style={{ flex: 1, borderRadius: 10, overflow: 'hidden' }}
              />
            </Box>

            <Box className={classes.bottomButtonRow}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap  ',
                }}
              >
                {programs &&
                  programs[activeDayIndex]?.halls?.length > 0 &&
                  programs[activeDayIndex]?.halls?.map((hall, hallIndex) => {
                    return (
                      <Button
                        key={hallIndex}
                        variant='contained'
                        color={
                          hall.id === activeHallId ? 'primary' : 'secondary'
                        }
                        onClick={() =>
                          setActiveDayHallInfo({
                            ...activeDayHallInfo,
                            activeHallId: hall.id,
                          })
                        }
                        className={classNames(
                          classes.button,
                          activeHallId !== hall.id && classes.buttonDis,
                        )}
                      >
                        {language === 'UA' ? hall.hallUA : hall.hallEN}
                      </Button>
                    );
                  })}
              </Box>
              {(tablet768 || mobile375 || mobileSmall) && (
                <Box
                  className={classNames(
                    classes.chatButtonSection,
                    break560 && classes.reverse,
                  )}
                >
                  <Typography className={classes.amount}>
                    {formatMessage({ id: 'translation_online_chat' })}
                  </Typography>
                  <Button
                    variant='contained'
                    type='submit'
                    color='primary'
                    className={classes.chatModalButton}
                    onClick={() => dispatch(show(CHAT_MODAL))}
                  >
                    <MessageCircle width={15} height={15} />
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item>
            {!(tablet768 || mobile375 || mobileSmall) ? (
              <Chat currentTab={activeHallId} />
            ) : (
              <ChatModal currentTab={activeHallId} />
            )}
          </Grid>
          <ProgramList list={programList} loading={loading} />
        </Grid>
      </section>
    </div>
  );
};

export default Translation;
