import palette from '../palette';

const MuiButton = {
  containedPrimary: {
    color: palette.common.white,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1,
    textTransform: 'initial',
    background:
      'linear-gradient(274.85deg, rgba(42, 108, 195, 0.9) -4.04%, #2395D7 100%)',
    backgroundColor: 'none',
    borderRadius: 5,
    boxShadow: 'none',
  },
  containedSecondary: {
    color: '#58727F',
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1,
    textTransform: 'initial',
    background: 'none !important',
    backgroundColor: 'none',
    border: `1px solid #6B838E`,
    borderRadius: 5,
    boxShadow: 'none',

    '&:hover': {
      color: palette.common.white,
      background:
        'linear-gradient(274.85deg, rgba(42, 108, 195, 0.9) -4.04%, #2395D7 100%) !important',
      border: 'none',
      boxShadow: 'none',
    },
  },
  outlinedPrimary: {
    height: 46,
    borderRadius: 8,
    backgroundColor: palette.common.white,
    color: palette.primary.main,
    border: `1px solid ${palette.primary.main}`,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1,
    padding: '15px 20px',
    textTransform: 'initial',
    boxShadow: 'none',

    '&.Mui-disabled': {
      color: palette.primary.main,
      border: `1px solid ${palette.primary.main}`,
      opacity: 0.5,
    },

    '&:hover': {
      backgroundColor: palette.primary.main,
      boxShadow: 'none',
      color: palette.common.white,
    },
  },
  outlinedSecondary: {
    height: 46,
    borderRadius: 8,
    backgroundColor: palette.common.white,
    color: palette.common.gold,
    border: `1px solid ${palette.common.grey}`,
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1,
    padding: '15px 20px',
    textTransform: 'initial',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: palette.primary.main,
      boxShadow: 'none',
      color: palette.common.white,
    },
  },
  textPrimary: {
    padding: '0',
    color: palette.primary.main,
    fontSize: '12px',
    fontWeight: '500px',
    lineHeight: '18px',
    textTransform: 'initial',

    '&:hover': {
      background: 'none !important',
      boxShadow: 'none',
    },
  },
  textSecondary: {
    padding: '0',
    color: palette.secondary.main,
    fontSize: '14px',
    fontWeight: '500px',
    lineHeight: '18px',
    textTransform: 'initial',

    '&:hover': {
      background: 'none !important',
      boxShadow: 'none',
    },
  },
};

export default MuiButton;
