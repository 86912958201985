const palette = {
  primary: {
    main: '#294F5F',
  },
  secondary: {
    main: '#2395D7',
  },
  common: {
    blue: '#2A6CC3',
    darkGrey: '#6B838E',
    grey: '#C7D0DB',
    cream: '#ECEEF3',
    sky: '#F4F5F9',
    white: '#FBFBFD',
  },
};

export default palette;
