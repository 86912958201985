import types from '../actionTypes';

const {
  FETCH_GET_BROADCAST_BY_HALL_SUCCESS,
  FETCH_GET_BROADCAST_BY_HALL_START,
  FETCH_GET_BROADCAST_BY_HALL_ERROR,
  FETCH_GET_BROADCAST_BY_HALL_CLEAR_ERROR,
  SET_ACTIVE_TAB,
  SET_REQUEST_TAB,
} = types;

const INIT_STATE = {
  list: [],
  offlineUrlEN: '',
  offlineUrlUA: '',
  onlineUrlEN: '',
  onlineUrlUA: '',
  onlineIdEN: '',
  onlineIdUA: '',
  offlineIdEN: '',
  offlineIdUA: '',
  offlineViewCountEN: '',
  offlineViewCountUA: '',
  onlineConcurrentViewersEN: '',
  onlineConcurrentViewersUA: '',
  activeTab: 1,
  reqTab: 0,
  loading: false,
  error: '',
};
const broadcastListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case SET_REQUEST_TAB:
      return { ...state, reqTab: action.payload };
    case FETCH_GET_BROADCAST_BY_HALL_SUCCESS:
      const {
        list,
        offlineUrlEN,
        offlineUrlUA,
        onlineUrlEN,
        onlineUrlUA,
        onlineIdEN,
        onlineIdUA,
        offlineIdEN,
        offlineIdUA,
        offlineViewCountEN,
        offlineViewCountUA,
        onlineConcurrentViewersEN,
        onlineConcurrentViewersUA,
      } = action.payload;
      return {
        ...state,
        list,
        offlineUrlEN,
        offlineUrlUA,
        onlineUrlEN,
        onlineUrlUA,
        onlineIdEN,
        onlineIdUA,
        offlineIdEN,
        offlineIdUA,
        offlineViewCountEN,
        offlineViewCountUA,
        onlineConcurrentViewersEN,
        onlineConcurrentViewersUA,
        loading: false,
        error: '',
      };
    case FETCH_GET_BROADCAST_BY_HALL_START:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_GET_BROADCAST_BY_HALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    case FETCH_GET_BROADCAST_BY_HALL_CLEAR_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
};

export default broadcastListReducer;
