import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  body: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    width: '720px',
    padding: '60px !important',
    backgroundColor: palette.common.white,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    "@media (max-width: 1440px)": {
      width: '620px',
      padding: '60px 40px 40px 40px!important',
    },
    "@media (max-width: 1000px)": {
      width: '520px',
      padding: '50px 30px 40px 30px!important',
    },
    "@media (max-width: 768px)": {
      width: '100%',
      padding: '50px 5% 40px !important',
    },
  },
  closer: {
    display: "flex",
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '0 !important',
    color: '#58727F',
    cursor: 'pointer',

    "@media (max-width: 1000px)": {
      top: '10px',
      right: '10px',
    },
  },
  closerText: {
    marginRight: '10px',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    lineHeight: '29px'
  },
  content: {
    width: '100%',
  },
}));

export default useStyles;