import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  amount: {
    fontSize: 14,
    fontWeight: 300,
    flewWrap: 'nowrap',
  },
}));

export default useStyles;
