// Types
import types from '../actionTypes';
// Services
import ApiService from '../../services/ApiService';
// Constants
import {
  CHAT_START_PAGE,
  CHAT_MESSAGE_LIMIT,
  MESSAGE_SEND_DELAY,
} from '../../common/constants/general';
const loadMessagesStart = () => ({
  type: types.FETCH_MESSAGES_BY_HALL_START,
});
const loadMessagesSuccess = (data) => ({
  type: types.FETCH_MESSAGES_BY_HALL_SUCCESS,
  payload: data,
});
const loadMoreMessagesSuccess = (data) => ({
  type: types.FETCH_MESSAGES_BY_HALL_MORE_SUCCESS,
  payload: data,
});
const loadMessagesError = (data) => ({
  type: types.FETCH_MESSAGES_BY_HALL_ERROR,
  payload: data,
});
const messageClearError = () => ({
  type: types.FETCH_MESSAGES_BY_HALL_CLEAR_ERROR,
});

export const fetchMessageListByHallAsync = (
  dispatch,
  hallId,
  page = CHAT_START_PAGE,
  limit = CHAT_MESSAGE_LIMIT,
) => {
  dispatch(loadMessagesStart());

  ApiService.apiCall({
    url: ApiService.paths.broadcasts.GET_CHAT_MESSAGES,
    token: true,
    params: { broadcastId: hallId, page, limit },
  })
    .then((response) => {
      const { total, messages } = response.data;
      dispatch(
        loadMessagesSuccess({
          list: Array.isArray(messages) ? messages : [],
          total,
        }),
      );
    })
    .catch((error) => {
      dispatch(loadMessagesError(error));
      setTimeout(() => {
        messageClearError();
      }, 300);
    });
};
export const fetchMoreMessageListByHallAsync = (
  dispatch,
  hallId,
  page = CHAT_START_PAGE,
  limit = CHAT_MESSAGE_LIMIT,
) => {
  dispatch(loadMessagesStart());

  ApiService.apiCall({
    url: ApiService.paths.broadcasts.GET_CHAT_MESSAGES,
    token: true,
    params: { broadcastId: hallId, page, limit },
  })
    .then((response) => {
      const { total, messages } = response.data;
      dispatch(loadMoreMessagesSuccess({ list: messages, total }));
    })
    .catch((error) => {
      dispatch(loadMessagesError(error));
      setTimeout(() => {
        messageClearError();
      }, 300);
    });
};
export const addMessageFromSocket = (data) => ({
  type: types.ADD_MESSAGE_FROM_SOCKET,
  payload: data,
});
export const setMessageList = (data) => ({
  type: types.SET_MESSAGES_LIST,
  payload: data,
});
const sendMessageStart = () => ({ type: types.FETCH_SEND_MESSAGE_START });
const sendMessageSuccess = () => ({ type: types.FETCH_SEND_MESSAGE_SUCCESS });
const sendMessageError = () => ({ type: types.FETCH_SEND_MESSAGE_ERROR });
const sendMessageClearError = () => ({
  type: types.FETCH_SEND_MESSAGE_CLEAR_ERROR,
});

export const sendMessageAsync = (dispatch, { broadcastId, message }) => {
  dispatch(sendMessageStart());

  ApiService.apiCall({
    url: ApiService.paths.broadcasts.SEND_MESSAGE,
    method: 'POST',
    token: true,
    data: { broadcastId, message },
  })
    .then((response) => {
      setTimeout(() => {
        dispatch(sendMessageSuccess());
        // fetchMessageListByHallAsync(dispatch, broadcastId)
      }, MESSAGE_SEND_DELAY);
    })
    .catch((error) => {
      dispatch(sendMessageError(error));
      setTimeout(() => dispatch(sendMessageClearError), 500);
    });
};
