// Types
import types from '../actionTypes';
// Services
import ApiService from '../../services/ApiService';

// API actions
const fetchSymposiumsStart = () => ({
  type: types.FETCH_GET_ALL_SYMPOSIUMS_START,
});
const fetchSymposiumsSuccess = (data) => ({
  type: types.FETCH_GET_ALL_SYMPOSIUMS_SUCCESS,
  payload: data,
});
const fetchSymposiumsError = ({ message }) => ({
  type: types.FETCH_GET_ALL_SYMPOSIUMS_ERROR,
  payload: message,
});
const fetchSymposiumsClearError = () => ({
  type: types.FETCH_GET_ALL_SYMPOSIUMS_CLEAR_ERROR,
});
export const fetchAllSymposiumListAsync = () => (dispatch) => {
  dispatch(fetchSymposiumsStart());

  ApiService.apiCall({
    url: ApiService.paths.symposiums.GET_ALL,
    token: true,
  })
    .then((response) => {
      dispatch(fetchSymposiumsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchSymposiumsError(error));
      setTimeout(() => {
        fetchSymposiumsClearError();
      }, 300);
    });
};
