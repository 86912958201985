import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import { connectModal, hide } from 'redux-modal';
import useIntl from "react-intl/lib/src/components/useIntl";
import Flex from "../../global/Flex";
import { PERSONAL_AREA_CHANGE_PASSWORD } from '../../../constants/modalNames';
import useStyles from './styles';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import Button from "@material-ui/core/Button";
import FormInput from "../../global/FormInput";
import { ReactComponent as KeyIcon } from '../../../../assets/icons/keyIcon.svg';
import { ReactComponent as VisibilityOn } from '../../../../assets/icons/visibilityOn.svg';
import { ReactComponent as VisibilityOff } from '../../../../assets/icons/visibilityOff.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { changePasswordAsync } from '../../../../store/actions/userActions';

const PersonalAreaChangePasswordModal = ({
    show,
  }) => {
  const { formatMessage } = useIntl();
  const theme = useSelector((state) => state.theme.value);
  const classes = useStyles({ theme });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(changePasswordAsync(data));
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, (formatMessage({id: "inputErrors_required_password"})))
      .required(formatMessage({ id: 'inputErrors_required' })),
    newPassword: Yup.string()
      .min(8, (formatMessage({id: "inputErrors_required_password"})))
      .required(formatMessage({ id: 'inputErrors_required' })),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Паролі повинні співпадати'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Паролі не співпадають')
      .min(8, (formatMessage({id: "inputErrors_required_password"})))
      .required(formatMessage({ id: 'inputErrors_required' }))
  });
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema)
  });

  /*Password*/
  const [visibleOldPassword, setVisibleOldPassword] = React.useState(false);
  const [visibleNewPassword, setVisibleNewPassword] = React.useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = React.useState(false);


  const handleClickShowOldPassword = () => {
    setVisibleOldPassword(visiblePassword => !visiblePassword)
  };

  const handleClickShowNewPassword = () => {
    setVisibleNewPassword(visiblePassword => !visiblePassword)
  };

  const handleClickShowConfirmPassword = () => {
    setVisibleConfirmPassword(visiblePassword => !visiblePassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(PERSONAL_AREA_CHANGE_PASSWORD))}
        maxWidth="xl"
      >
        <DialogContent className={classes.body}>
          <div className={classes.modalImage}>
            <KeyIcon />
          </div>

          <Typography className={classes.title}  variant="h4" align="center">
            {formatMessage({ id: "form_add_new_password_title" })}
          </Typography>

          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Flex spacing={20} container marginBottom column>
              <Flex xs="100%">
                <FormInput
                  type={visibleOldPassword ? 'text' : 'password'}
                  error={errors.oldPassword}
                  errors={errors}
                  name="oldPassword"
                  placeholder={formatMessage({ id: "form_password" })}
                  control={control}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {visibleOldPassword ? <VisibilityOn className={classes.visibility} /> : <VisibilityOff className={classes.visibility}/>}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Flex>
              <Flex xs="100%">
                <FormInput
                  type={visibleNewPassword ? 'text' : 'password'}
                  error={errors.newPassword}
                  errors={errors}
                  name="newPassword"
                  placeholder={formatMessage({ id: "form_add_new_password_password" })}
                  control={control}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {visibleNewPassword ? <VisibilityOn className={classes.visibility} /> : <VisibilityOff className={classes.visibility}/>}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Flex>
              <Flex xs="100%">
                <FormInput
                  type={visibleConfirmPassword ? 'text' : 'password'}
                  error={errors.confirmPassword}
                  errors={errors}
                  name="confirmPassword"
                  placeholder={formatMessage({ id: "form_add_new_password_confirm" })}
                  control={control}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {visibleConfirmPassword ? <VisibilityOn className={classes.visibility} /> : <VisibilityOff className={classes.visibility}/>}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Flex>
              <Flex container>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '50px',
                  }}
                >
                  {formatMessage({ id: "personal_area_button_change" })}
                </Button>
              </Flex>
            </Flex>
            <Flex container>
              <Button
                variant="text"
                color="primary"
                style={{
                  display: 'block',
                  margin: '0 auto',
                }}
                onClick={() => dispatch(hide(PERSONAL_AREA_CHANGE_PASSWORD))}
              >
                {formatMessage({ id: "form_button_back" })}
              </Button>
            </Flex>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: PERSONAL_AREA_CHANGE_PASSWORD,
  getModalState: (state) => state.modals,
})(PersonalAreaChangePasswordModal);
