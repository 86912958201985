import types from '../actionTypes';
// Services
import StorageService from '../../services/StorageService';
// Constants
import { MATCH_LOCALE } from '../../lang/locales';
const { SET_LOCALE } = types;

const INIT_STATE = {
  language:
    StorageService.getItem('fil_locale') || MATCH_LOCALE['UA'], // get locale from saved
};
const localeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOCALE:
      StorageService.setItem('fil_locale',MATCH_LOCALE[action.payload])
      return { ...state, language: MATCH_LOCALE[action.payload] };
    default:
      return state;
  }
};

export default localeReducer;
