import types from '../actionTypes';

const {
  FETCH_GET_ALL_PROGRAMS_START,
  FETCH_GET_ALL_PROGRAMS_SUCCESS,
  FETCH_GET_ALL_PROGRAMS_ERROR,
  FETCH_GET_ALL_PROGRAMS_CLEAR_ERROR,
} = types;

const INIT_STATE = {
  list: null,
  activeTab: 1,
  loading: false,
  error: '',
};
const programListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_ALL_PROGRAMS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case FETCH_GET_ALL_PROGRAMS_START:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_GET_ALL_PROGRAMS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case FETCH_GET_ALL_PROGRAMS_CLEAR_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
};

export default programListReducer;
