import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& > div >*': {
      borderRadius: '10px !important',
      '@media (max-width: 480px)': {
        margin: '0px !important',
      },
    },
  },
  body: {
    maxWidth: 700,
    padding: '0 !important',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',
    '@media (min-width: 768px)': {
      width: 700,
    },
    '@media (max-width: 767px)': {
      maxWidth: 700,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
    color: 'white',
  },
}));

export default useStyles;
