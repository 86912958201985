import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { connectModal, hide } from 'redux-modal';
import { STAND_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';
import useIntl from 'react-intl/lib/src/components/useIntl';
import VideoPlayer from '../../global/VideoPlayer'

const StandModal = ({
                      modalContent,
        show,type,value
  }) => {
  const theme = useSelector((state) => state.theme.value);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();

  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(STAND_MODAL))}
        maxWidth="xl"
      >
        <DialogContent className={classes.body}>
          <div className={classes.closer} onClick={() => dispatch(hide(STAND_MODAL))}>
            <span className={classes.closerText}>{formatMessage({ id: "close_modal_button" })}</span>
            <CloseCircleIcon />
          </div>

          <div className={classes.content}>
            {type === 'video' &&
              <VideoPlayer
                url={value}
                width={'100%'}
                controls
                style={{ flex: 1, borderRadius: 10, overflow: 'hidden' }}
              />}
              
              {type==="img"&&<img
              className={classes.leftItemImage}
              src={value}
              alt={''}
              width={'100%'}
            />
            }
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: STAND_MODAL,
  getModalState: (state) => state.modals,
})(StandModal);
