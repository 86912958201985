import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { ReactComponent as ArrowRightCircleIcon } from '../../../assets/icons/arrowRightCircleIcon.svg';
import ElectronicPostersModal from '../../components/modals/ElectronicPostersModal';
import { show } from 'redux-modal';
import { ELECTRONIC_POSTERS_MODAL } from '../../constants/modalNames';
import actions from '../../../store/actions';
import Loading from '../../components/global/Loading';

const { fetchAllEposterListAsync } = actions;

const ElectronicPosters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const eposterList = useSelector((state) => state.eposters.list);
  const language = useSelector((state) => state.user.user.language);
  const loading = useSelector((state) => state.eposters.loading);
  const { formatMessage } = useIntl();
  const name = language === 'UA' ? 'nameUA' : 'nameEN';
  const authors = language === 'UA' ? 'authorsUA' : 'authorsEN';
  const fileUrl = language === 'UA' ? 'fileUrlUA' : 'fileUrlEN';
  const moderator = language === 'UA' ? 'moderatorUA' : 'moderatorEN';
  const executiveGroup =
    language === 'UA' ? 'executiveGroupUA' : 'executiveGroupEN';

  useEffect(() => {
    dispatch(fetchAllEposterListAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  if (loading) return <Loading />;
  return (
    <div>
      <ElectronicPostersModal />
      <section className={classes.section}>
        <Container style={{ flexDirection: 'column' }}>
          <Typography variant='h3'>
            {formatMessage({ id: 'electronic_posters_title' })}
          </Typography>
          <div className={classes.electronicPostersTable}>
            {eposterList.map((item, index) => (
              <div
                className={classes.block}
                key={index}
                onClick={() =>
                  dispatch(
                    show(ELECTRONIC_POSTERS_MODAL, {
                      title: item[name],
                      moderator: item[moderator],
                      fileUrl: item[fileUrl],
                      adminGroup: item[executiveGroup],
                    }),
                  )
                }
              >
                <div>{item[name]}</div>
                <div className={classes.author}>{item[authors]}</div>
                <ArrowRightCircleIcon className={classes.arrowRight} />
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default ElectronicPosters;
