const MuiContainer = {
  root: {
    display: 'flex',
    maxWidth: '1440px',
    padding: '0 130px !important',

    '@media (max-width: 1440px)': {
      width: '1000px',
      padding: '0 30px !important',
    },

    '@media (max-width: 1000px)': {
      maxWidth: '768px',
    },

    '@media (max-width: 768px)': {
      maxWidth: '96%',
      padding: '0 !important',
    },
  },
};

export default MuiContainer;
