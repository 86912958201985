import React, { useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'
// Material UI components
import {Box} from '@material-ui/core'
import actions from '../../../../store/actions'
import useStyles from './styles';

const {fetchPartnersImages}=actions

const GeneralPartner = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const partnerlist = useSelector(state=>state.partners.sponsors)
    useEffect(()=>{
      dispatch(fetchPartnersImages())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
      <Box className={classes.partnersContainer}>
        {!!partnerlist&&partnerlist.filter(partner=>partner.isSponsor)?.map((sponsor,index)=>{
          return (<Box className={classes.imageContainer} key={index}>
            <img src={sponsor.logoURL} alt="" />


          </Box>) 
        })}
      </Box>
    );
};

export default GeneralPartner;