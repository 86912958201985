import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Scrollbars } from 'react-custom-scrollbars';
import { format } from 'date-fns';
import { enUS, uk } from 'date-fns/locale';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { ReactComponent as MessageCircle } from '../../../../assets/icons/messageCircle.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/sendIcon.svg';
import { withStyles, fade } from '@material-ui/core/styles';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../store/actions';
import notify from '../../../helpers/notify';
import StorageService from '../../../../services/StorageService';
import useIntl from 'react-intl/lib/src/components/useIntl';
// Constants
import { CHAT_START_PAGE } from '../../../constants/general';
import { hallTypes } from '../../../constants/general';
import { getChatWS } from '../../../constants/apiTypes';

import Loading from '../Loading';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
// Helpers

const messagesByDay = (m, language) => {
  if (m?.length === 0 || !m) return {};
  const mbd = {};
  m.forEach((message) => {
    let date = format(new Date(message.createdAt), 'd MMMM', {
      locale: language === 'UA' ? uk : enUS,
    });
    if (mbd[date]) {
      mbd[date] = [...mbd[date], message];
    } else {
      mbd[date] = [message];
    }
  });
  return mbd;
};
// Custom override for a Message Component
const MessageInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%',
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    flex: 4,
    width: '290px',
    height: '40px',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'Inter',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '@media (min-width: 300px)': {
      width: '50vw',
      minWidth: 160,
    },
    '@media (min-width: 768px)': {
      flex: 1,
    },
    '@media (min-width: 1000px)': {
      width: '250px',
    },
    '@media (min-width: 1440px)': {
      width: '290px',
    },
    '&:focus': {
      boxShadow: `${fade(theme.palette.secondary.main, 0.25)} 0 0 0 0.1rem`,
      border: `2px solid ${theme.palette.secondary.main}`,
    },
  },
}))(InputBase);

const {
  fetchMessageListByHallAsync,
  fetchMoreMessageListByHallAsync,
  fetchCurrentUserAsync,
  addMessageFromSocket,
  wsConnect,
  wsDisconnect,
  logoutUser,
  // wsJoinRoom,
  // wsSend,
  sendMessageAsync,
  setMessageList,
} = actions;

const Chat = ({ currentTab }) => {
  const classes = useStyles({});
  const smallScreen = useMediaQuery('@media(max-width:435px)');
  const chatScroll = useRef();
  const { formatMessage } = useIntl();
  const [page, setPage] = useState(CHAT_START_PAGE);
  const [isScrolling, setIsScrolling] = useState(false);
  // Redux
  const dispatch = useDispatch();
  const { list, total: totalMessages } = useSelector((state) => state.chat);
  const messages = list;
  const user = useSelector((state) => state.user.user);

  const loading = useSelector((state) => state.chat.loading);
  const messageSending = useSelector((state) => state.chat.sending);
  useEffect(() => {
    if (!isScrolling) {
      chatScroll.current.scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);
  useEffect(() => {});
  useEffect(() => {
    fetchMessageListByHallAsync(dispatch, currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  const handleLoadMore = (page) => {
    fetchMoreMessageListByHallAsync(dispatch, currentTab, page);
  };
  // WebSocket Actions
  const onOpen = (e) => {
    // dispatch(wsJoinRoom(currentTab));
  };
  const onClose = (e) => {
    // console.log(`Now is ${new Date()}`);
    // console.log(e);
    console.log(
      `%cDisconnected from chat ${hallTypes[currentTab - 1]?.text}`,
      'color:teal',
    );
  };
  const onError = (e) => {
    // console.log(`Now is ${new Date()}`);
    // console.log(e);
    console.log(
      `%cError in chat ${hallTypes[currentTab - 1]?.text}`,
      'color:red',
    );
  };

  const onMessage = (e) => {
    const response = JSON.parse(e.data);
    if (response.joined === true) {
      notify(`Joined chat for ${hallTypes[currentTab - 1]?.text}`);
    } else if (response.type === 'AUTH_ERROR') {
      dispatch(logoutUser());
    } else if (response.status === true && response.message.id) {
      const message = response.message;
      dispatch(addMessageFromSocket(message));
    } else if (
      response.type === 'CURRENT_USER_HAS_BEEN_BANNED' ||
      response.message === 'BANNED_USER_IN_HALL'
    ) {
      if (user.id === response.userId) {
        fetchCurrentUserAsync(dispatch);
        notify(formatMessage({ id: 'chat_ban_message' }), 'error', {
          autoClose: 100000,
        });
      }
      dispatch(
        setMessageList([
          ...list.filter((msg) => msg.user.id !== response.userId),
        ]),
      );
    } else if (
      response.message === 'UNBANNED_USER_IN_HALL' ||
      response.type === 'CURRENT_USER_HAS_BEEN_UNBANNED'
    ) {
      fetchCurrentUserAsync(dispatch);
      notify(formatMessage({ id: 'chat_unban_message' }), 'success', {
        autoClose: 100000,
      });
      fetchMessageListByHallAsync(dispatch, currentTab);
    } else if (response.type === 'ERROR') {
      notify(
        formatMessage({ id: 'chat_ban_message' }),
        'error',
        // "You've been banned from the chat. Please contact the administration to restore your rights",
        // 'error',
      );
    }
  };

  useEffect(() => {
    if (user?.chatStatus === 'BANNED') {
      notify(formatMessage({ id: 'chat_ban_message' }), 'error', {
        autoClose: 100000,
      });
    }
    const token = StorageService.getToken();
    dispatch(
      wsConnect(getChatWS(token, currentTab), {
        onOpen,
        onMessage,
        onClose,
        onError,
      }),
    );

    return () => {
      return dispatch(wsDisconnect());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!messageSending) {
      const message = e.target['message-input'].value;
      if (message && user?.chatStatus === 'BANNED') {
        notify(formatMessage({ id: 'chat_ban_message' }), 'error', {
          autoClose: 100000,
        });
        e.target['message-input'].value = '';
        return;
      }
      if (message) {
        sendMessageAsync(dispatch, { broadcastId: currentTab, message });

        e.target['message-input'].value = '';
      }
    }
  };
  return (
    <Box className={classes.chatContainer}>
      <Box className={classes.chatHeader}>
        <MessageCircle
          style={{
            width: '21px !important',
            height: '21px !important',
            marginRight: 13.5,
          }}
        />
        <Box>
          {smallScreen ? (
            <Box className={classes.titleBox}>
              <Typography className={classes.chatTitle}>
                {formatMessage({ id: 'chat_header_1' }) +
                  formatMessage({ id: 'chat_header_2' })}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography className={classes.chatTitle}>
                {formatMessage({ id: 'chat_header_1' })}
              </Typography>
              <Typography className={classes.chatTitle}>
                {formatMessage({ id: 'chat_header_2' })}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Scrollbars
        ref={chatScroll}
        onScroll={() => {
          if (chatScroll.current.scrolling) {
            setIsScrolling(true);
            const {
              scrollTop,
              scrollHeight,
              clientHeight,
            } = chatScroll.current.getValues();
            if (scrollTop + clientHeight * 1.2 >= scrollHeight) {
              setIsScrolling(false);
            }
          }
        }}
        renderTrackVertical={(props) => (
          <div {...props} className={classes.trackVertical} />
        )}
        renderTrackHorizontal={(props) => (
          <div {...props} className={classes.trackHorizontal} />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className={classes.thumbHorizontal} />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className={classes.thumbVertical} />
        )}
        style={{ position: 'relative', height: '360px' }}
        className={classNames(classes.scrollbars, classes.chatScroll)}
      >
        {messages?.length > 0 && (
          <Typography
            className={classes.uploadMoreLink}
            onClick={() => {
              if (totalMessages > messages.length) {
                handleLoadMore(page + 1);
                setPage(page + 1);
              } else {
                chatScroll.current.scrollToBottom();
              }
            }}
          >
            {totalMessages > messages.length
              ? formatMessage({ id: 'more_messages_add' })
              : formatMessage({ id: 'more_messages_none' })}
          </Typography>
        )}
        {loading && <Loading />}
        {!!messages.length &&
          [
            ...Object.keys(
              messagesByDay(
                messages?.sort(
                  (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime(),
                ),
                user.language,
              ),
            ),
          ].map((date) => {
            return (
              <div key={`${date}${Math.random()}`}>
                <Typography className={classes.chatDate}>{date}</Typography>
                {messagesByDay(messages, user.language)[date].map((message) => {
                  return (
                    <Box
                      key={`${message.id}${Math.random()}`}
                      className={classNames(
                        classes.chatMessage,
                        user.id === message.user.id &&
                          classes.chatMessageActive,
                      )}
                    >
                      <Box className={classes.messageHeader}>
                        <Typography className={classes.messageTitle}>
                          {`${message.user.lastName} ${message.user.firstName} ${message.user.middleName}`}
                        </Typography>
                        <Typography
                          className={classes.chatDate}
                          style={{ margin: 0 }}
                        >
                          {format(new Date(message.createdAt), 'HH:mm')}
                        </Typography>
                      </Box>
                      <Box className={classes.messageTextContainer}>
                        <Typography className={classes.messageText}>
                          {message.message}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </div>
            );
          })}
      </Scrollbars>
      <Box className={classes.chatFooter}>
        <FormControl fullWidth>
          <form
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
            onSubmit={onSubmit}
            noValidate
            autoComplete='off'
          >
            <MessageInput
              id='message-input'
              placeholder={formatMessage({ id: 'message_place_holder' })}
              fullWidth
            />

            <Button
              variant='contained'
              type='submit'
              color='primary'
              className={classes.chatSubmitButton}
            >
              {messageSending ? (
                <CircularProgress style={{ color: 'white' }} size={16.5} />
              ) : (
                <SendIcon width={16.5} height={16.5} />
              )}
            </Button>
          </form>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Chat;
