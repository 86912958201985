import { applyMiddleware, combineReducers, createStore } from 'redux';
// Middleware
import thunk from 'redux-thunk';
import socketMiddleware from '../middleware/socketMiddleware';
import * as reducers from './reducers';

const rootReducers = combineReducers({
  ...reducers,
});

const middleware = [thunk, socketMiddleware];
const middlewareEnhancer = applyMiddleware(...middleware);

const store = createStore(
  rootReducers,
  // composeWithDevTools(middlewareEnhancer),
  middlewareEnhancer,
);

export default store;
