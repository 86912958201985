import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { connectModal, hide } from 'redux-modal';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { CONFIRM_ERROR } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/closeIcon.svg';
import Flex from '../../global/Flex';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';

const ConfirmErrorModal = ({ show }) => {
  const onSubmit = (data) => console.log(data);
  const theme = useSelector((state) => state.theme.value);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();
  const { handleSubmit } = useForm({});

  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(CONFIRM_ERROR))}
        maxWidth='xl'
      >
        <DialogContent className={classes.body}>
          <div className={classes.modalImage}>
            <CloseIcon />
          </div>
          <Typography className={classes.title} variant='h4' align='center'>
            {formatMessage({ id: 'confirm_error_form_title' })}
          </Typography>

          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Flex spacing={20} container marginBottom column>
              <Flex container>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  style={{
                    width: '100%',
                    height: '50px',
                  }}
                >
                  {formatMessage({ id: 'confirm_error_form_button' })}
                </Button>
              </Flex>
            </Flex>
            <Flex container>
              <Button
                variant='text'
                color='primary'
                onClick={() => {
                  dispatch(hide(CONFIRM_ERROR));
                }}
                style={{
                  display: 'block',
                  margin: '0 auto',
                }}
              >
                {formatMessage({ id: 'form_button_back' })}
              </Button>
            </Flex>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: CONFIRM_ERROR,
  getModalState: (state) => state.modals,
})(ConfirmErrorModal);
