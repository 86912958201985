import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 35,
    paddingBottom: 35,
  },
  programInfo: {
    display: 'flex',
    justifyContent: 'space-between',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  programTabs: {
    display: 'flex',

    '@media (max-width: 768px)': {
      order: '1',
      marginTop: '30px',
    },
  },
  programButton: {
    width: '90px',
    height: '35px',
    marginRight: '10px',
    padding: 0,
    fontSize: 14,
  },
  programTable: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '20px',

    '@media (max-width: 1000px)': {
      marginTop: '0',
    },
  },
  column: {
    width: '380px',

    '@media (max-width: 1440px)': {
      width: '32.333%',
    },
    '@media (max-width: 1000px)': {
      width: '100%',
    },
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    color: palette.primary.main,
    marginBottom: '10px',
    padding: '10px',
    background: palette.common.cream,
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      color: palette.common.white,
      background: palette.secondary.main,
    },
    '&:hover *': {
      color: palette.common.white,
    },

    '@media (max-width: 1000px)': {
      height: 'auto',
      minHeight: '50px',
    },
  },
  blockHall: {
    textAlign: 'left',
    height: '50px',
    marginBottom: '20px',
    fontSize: '14px',
    lineHeight: '1.43',
    textTransform: 'initial',
    border: 'none',
    borderRadius: '5px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: "0px 14px 24px -10px rgba(25, 69, 126, 0.7)"
    },

    '@media (max-width: 1440px)': {
      height: '70px',
    },
    '@media (max-width: 1000px)': {
      height: '50px',
      marginTop: '30px',
    },
  },
  blockInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  hallTitle: {
    marginRight: '15px',
    fontSize: '16px',
    fontWeight: '500',
  },
  hallWatch: {
    fontWeight: '300',
  },
  time: {
    minWidth: '70px',
    marginRight: '15px',
    color: '#58727F',
    fontSize: '12px',
    fontWeight: '300',
  },
  title: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',

    '@media (max-width: 1000px)': {
      overflow: 'inherit',
      display: 'block',
    },
  },
  arrowRight: {
    maxWidth: '20px',
    width: '100%',
    marginLeft: '15px',
    color: palette.secondary.main,
  },
  arrowRightHall: {
    maxWidth: '24px',
    width: '100%',
  },
}));

export default useStyles;
