import { parse, format } from 'date-fns';


class DateFormat {
  constructor() {
    this.formats = {
      time: 'HH:mm',
      date: 'MM/dd/YYYY',
      local: 'dd-MMM HH:mm:ss',
    };
  }
  parseTimeToDate(time, formats = this.formats.time) {
    return parse(time, formats, new Date());
  }
  parseDateToTime(date, formats = this.formats.time, options) {
    return format(date, formats, options);
  }
  parseLocalFromDate(date, formats = this.formats.local, options) {
    return format(date, formats, options);
  }
  parseTimeStamp(time, formats, options) {
    return this.parseDateToTime(new Date(time * 1000));
  }
}

export default new DateFormat();
