import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  dialog: {
    '@media (max-width: 480px)': {
      '&>div> .MuiDialog-paper': {
        margin: '0 !important',
      },
    },
  },
  body: {
    width: '380px',
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: palette.common.sky,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    "@media (max-width: 480px)": {
      width: '340px',
    },
  },
  modalImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '65px',
    height: '65px',
    marginBottom: '20px',
    padding: '0',
    color: '#58727F',
    borderRadius: '50%',
    backgroundColor: palette.common.cream,
  },
  tabsNav: {
    width: '100%',
  },
  tabsQuantity: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '2px 0 5px',
    color: palette.primary.main,
  },
  lineWrapper: {
    width: '100%',
    height: '5px',
    background: palette.common.cream,
    borderRadius: '2px',
  },
  lineInner: {
    width: props => `${props.currentTab === 0 ? '33%' : props.currentTab === 1 ? '66%' : '100%'}`,
    height: '5px',
    background: 'linear-gradient(274.85deg, rgba(42, 108, 195, 0.9) -4.04%, #2395D7 100%)',
    borderRadius: '2px',
    transition: 'all 0.5s',
  },
}));

export default useStyles;
