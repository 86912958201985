import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  body: {
    width: '380px',
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: palette.common.sky,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    "@media (max-width: 480px)": {
      width: '340px',
    },
  },
  modalImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '65px',
    height: '65px',
    marginBottom: '20px',
    padding: '0',
    color: palette.common.white,
    borderRadius: '50%',
    backgroundColor: palette.secondary.main,
  },
}));

export default useStyles;
