import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 20,
    paddingBottom: 20,
    '@media (min-width: 480px)': {
      paddingTop: 20,
    },
    '@media (min-width: 768px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
    '@media (min-width: 1024px)': {
      paddingTop: 60,
      paddingBottom: 60,
    },
    '@media (min-width: 1280px)': {
      paddingTop: 85,
      paddingBottom: 85,
    },
  },
}));

export default useStyles;
