import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { connectModal, hide, show as showModal } from 'redux-modal';
import useIntl from 'react-intl/lib/src/components/useIntl';
import Flex from '../../global/Flex';
import { LOGIN, PASSWORD_RESTORE } from '../../../constants/modalNames';
import useStyles from './styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import Button from '@material-ui/core/Button';
import FormInput from '../../global/FormInput';
import { ReactComponent as KeyIcon } from '../../../../assets/icons/keyIcon.svg';
import { FormControl } from '@material-ui/core';
import actions from '../../../../store/actions';

const { forgotPasswordAsync } = actions;

const PasswordRestoreModal = ({ show }) => {
  const theme = useSelector((state) => state.theme.value);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();
  const isLoading = useSelector((state) => state.user.loading);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'inputErrors_required_emil' }))
      .required(formatMessage({ id: 'inputErrors_required' })),
  });
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    dispatch(forgotPasswordAsync(data));
  };
  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(PASSWORD_RESTORE))}
        maxWidth='xl'
      >
        <DialogContent className={classes.body}>
          {isLoading ? (
            <CircularProgress color='primary' />
          ) : (
            <>
              <div className={classes.modalImage}>
                <KeyIcon />
              </div>
              <Typography className={classes.title} variant='h4' align='center'>
                {formatMessage({ id: 'password_restore_form_title' })}
              </Typography>
              <div className={classes.description}>
                {formatMessage({ id: 'password_restore_form_description' })}
              </div>
              <FormControl>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Flex spacing={20} container marginBottom column>
                    <Flex xs='100%'>
                      <FormInput
                        error={errors.email}
                        errors={errors}
                        name='email'
                        placeholder={formatMessage({ id: 'form_email' })}
                        control={control}
                        fullWidth
                      />
                    </Flex>
                    <Flex container>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        style={{
                          width: '100%',
                          height: '50px',
                        }}
                      >
                        {formatMessage({ id: 'password_restore_form_button' })}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex container>
                    <Button
                      variant='text'
                      color='primary'
                      onClick={() => {
                        dispatch(showModal(LOGIN));
                        dispatch(hide(PASSWORD_RESTORE));
                      }}
                      style={{
                        display: 'block',
                        margin: '0 auto',
                      }}
                    >
                      {formatMessage({ id: 'form_button_back' })}
                    </Button>
                  </Flex>
                </form>
              </FormControl>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: PASSWORD_RESTORE,
  getModalState: (state) => state.modals,
})(PasswordRestoreModal);
