import React from 'react';

import Box from '@material-ui/core/Box';

import ReactPlayer from 'react-player/youtube';
import {useStyles} from './styles';
import {useIntl} from 'react-intl'
import Loading from '../Loading';
import classNames from 'classnames';
import {ReactComponent as AlertCircle} from '../../../../assets/icons/alert-circle.svg'

const VideoPlayer = ({ loading, url }) => {
  const classes = useStyles();
  const {formatMessage} = useIntl()
  if(loading) return <Loading style={{height:360}}/>
  return (
    <Box className={classNames(classes.videoContainer,!url&&classes.noUrlContainer)}> 
      {url?<ReactPlayer
        url={url}
        width={'100%'}
        controls
        style={{ flex: 1, borderRadius: 10, overflow: 'hidden',zIndex:-1 }}
      />:<Box className={classes.messageContainer}>
          <AlertCircle />
          {formatMessage({id:"youtube_no_video"})}
          </Box>}
    </Box>
  );
};

export default VideoPlayer;
