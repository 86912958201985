// eslint-disable-next-line import/no-anonymous-default-export
export default {
  WS_CONNECT:"WS_CONNECT",
  WS_CONNECTING:"WS_CONNECTING",
  WS_CONNECTED:"WS_CONNECTED",
  WS_DISCONNECT:"WS_DISCONNECT",
  WS_DISCONNECTED:"WS_DISCONNECTED",
  WS_JOIN:"WS_JOIN",
  WS_SEND_MESSAGE:"WS_SEND_MESSAGE",
  ADD_MESSAGE_FROM_SOCKET:"ADD_MESSAGE_FROM_SOCKET",
  SET_MESSAGES_LIST:"SET_MESSAGE_LIST",
  FETCH_SEND_MESSAGE_START:"FETCH_SEND_MESSAGE_START",
  FETCH_SEND_MESSAGE_SUCCESS:"FETCH_SEND_MESSAGE_SUCCESS",
  FETCH_SEND_MESSAGE_ERROR:"FETCH_SEND_MESSAGE_ERROR",
  FETCH_SEND_MESSAGE_CLEAR_ERROR:"FETCH_SEND_MESSAGE_CLEAR_ERROR",
  FETCH_MESSAGES_BY_HALL_START: 'FETCH_MESSAGES_BY_HALL_START',
  FETCH_MESSAGES_BY_HALL_SUCCESS: 'FETCH_MESSAGES_BY_HALL_SUCCESS',
  FETCH_MESSAGES_BY_HALL_MORE_SUCCESS: 'FETCH_MESSAGES_BY_HALL_MORE_SUCCESS',
  FETCH_MESSAGES_BY_HALL_ERROR: 'FETCH_MESSAGES_BY_HALL_ERROR',
  FETCH_MESSAGES_BY_HALL_CLEAR_ERROR: 'FETCH_MESSAGES_BY_HALL_CLEAR_ERROR',
};

