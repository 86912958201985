const routes = {
  home: '/home',
  publicHome: '/',
  translation: '/translation',
  program: '/program',
  electronicPosters: '/electronic-posters',
  exhibition: '/exhibition',
  materials: '/materials',
  exhibitionParticipant: '/exhibition-participant',
  satelliteSymposiums: '/satellite-symposiums',
  webinars: '/webinars',
  personalArea: '/personal-area',
  privacyPolicy: '/privacy-policy',
  error: '/error',
  api: {
    user: {
      REGISTER: '/auth/signUp',
      LOGIN: '/auth/signIn',
      CURRENT_USER: '/users/fetch',
      FORGOT_PASSWORD: '/auth/resetPassword',
      RESET_PASSWORD: '/auth/setNewPassword',
      CHANGE_PASSWORD: '/auth/changePassword',
      ACTIVATE_USER: '/auth/activateUser',
      EDIT_USER: '/users/edit',
      GET_DIPLOMA: '/diploma/get',
      SEND_DIPLOMA: '/diploma/send',
    },
    broadcasts: {
      GET_BY_HALL: '/sessions/getSessionsByHall',
      GET_CHAT_MESSAGES: '/chats/get',
      SEND_MESSAGE: '/chats/send',
    },
    questions: {
      GET_FAQ: '/questions',
    },
    programs: {
      GET_ALL: '/programs/get',
    },
    eposters: {
      GET_ALL: '/eposters',
    },
    webinars: {
      GET_ALL: '/webinars',
    },
    symposiums: {
      GET_ALL: '/symposiums',
    },
    sessions: {
      GET_ALL: '/sessions/get',
    },
    partners: {
      GET_ALL: '/partners',
      GET_ALL_PUBLIC: '/partners/getPartnersImages',
      GET_BY_ID: '/partners/getPartnerById',
      FEEDBACK: '/feedbacks/create',
      ANALITICS: '/partners/incrementViewCount',
      MATERIALS_ANALITICS: '/materials/incrementDownloadCount',
    },
    contents: {
      GET_ALL: '/content/get',
    },
    materials: {
      GET_ALL: '/pdfmaterials/get',
    },
  },
};

export default routes;
