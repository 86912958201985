import palette from '../palette';

const MuiDrawer = {
  paper: {
    width: '320px',
    height: '100%',
    maxHeight: '100vh',
    padding: '20px 0',
    background: palette.common.white,
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',
  },
};

export default MuiDrawer;
