export const LOCALES = {
  UKRAINIAN: 'uk-UA',
  ENGLISH: 'en-US',
};
export const MATCH_LOCALE={
  UA:LOCALES.UKRAINIAN,
  EN:LOCALES.ENGLISH
}
export const MATCH_STATE={
  [LOCALES.UKRAINIAN]:"UA",
  [LOCALES.ENGLISH]:"EN"
}
