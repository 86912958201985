// Types
import types from '../actionTypes';
// Services
import ApiService from '../../services/ApiService';

// API actions
const fetchProgramsStart = () => ({
  type: types.FETCH_GET_ALL_PROGRAMS_START,
});
const fetchProgramsSuccess = (data) => ({
  type: types.FETCH_GET_ALL_PROGRAMS_SUCCESS,
  payload: data,
});
const fetchProgramsError = ({ message }) => ({
  type: types.FETCH_GET_ALL_PROGRAMS_ERROR,
  payload: message,
});
const fetchProgramsClearError = () => ({
  type: types.FETCH_GET_ALL_PROGRAMS_CLEAR_ERROR,
});
export const fetchAllProgramListAsync = ()=>(dispatch) => {
  dispatch(fetchProgramsStart());

  ApiService.apiCall({
    url: ApiService.paths.sessions.GET_ALL,
    token: true,
  })
    .then((response) => {
      dispatch(
        fetchProgramsSuccess(response.data),
      );
    })
    .catch((error) => {
      dispatch(fetchProgramsError(error));
      setTimeout(() => {
        fetchProgramsClearError();
      }, 300);
    });
};
