import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import WebinarModal from '../../components/modals/WebinarModal';
import { show } from 'redux-modal';
import { WEBINAR_MODAL } from '../../constants/modalNames';
import { ReactComponent as ArrowRightCircleIcon } from '../../../assets/icons/arrowRightCircleIcon.svg';
import actions from '../../../store/actions';
import Loading from '../../components/global/Loading';
const { fetchAllWebinarListAsync } = actions;

const Webinars = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const webinarList = useSelector((state) => state.webinars.list);
  const language = useSelector((state) => state.user.user.language);
  const loading = useSelector((state) => state.webinars.loading);
  const { formatMessage } = useIntl();
  const name = language === 'UA' ? 'nameUA' : 'nameEN';
  const title = language === 'UA' ? 'titleUA' : 'titleEN';
  useEffect(() => {
    dispatch(fetchAllWebinarListAsync());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  if (loading) return <Loading />;
  return (
    <div>
      <WebinarModal />
      <section className={classes.section}>
        <Container style={{ flexDirection: 'column' }}>
          <Typography variant='h3'>
            {formatMessage({ id: 'webinars_title' })}
          </Typography>
          <div className={classes.webinarsTable}>
            {webinarList.map((item, index) => (
              <div
                className={classes.block}
                key={index}
                onClick={() =>
                  dispatch(
                    show(WEBINAR_MODAL, {
                      item: { ...item, [title]: item[name] },
                    }),
                  )
                }
              >
                <div className={classes.image}>
                  <img
                    src={item.partner.logoURL}
                    alt={item[name]}
                    width={120}
                    height={40}
                    style={{ objectFit: 'contain' }}
                  />
                </div>
                <div className={classes.title}>
                  <div className={classes.titleText}>{item[name]}</div>
                </div>
                <ArrowRightCircleIcon className={classes.arrowRight} />
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Webinars;
