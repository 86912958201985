import React from "react";
import useIntl from "react-intl/lib/src/components/useIntl";
import Flex from "../../../global/Flex";
import useStyles from './styles';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl'
import FormInput from "../../../global/FormInput";
import { hide, show } from 'redux-modal';
import { LOGIN, SIGN_UP } from '../../../../constants/modalNames';
import { useDispatch, useSelector } from 'react-redux';

const FirstStep = ({onSubmit,formData}) => {
  const theme = useSelector((state) => state.theme.value);
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const validationSchema = Yup.object().shape({
    lastName: Yup.string().required(formatMessage({ id: 'inputErrors_required' })),
    firstName: Yup.string().required(formatMessage({ id: 'inputErrors_required' })),
  });
  const { handleSubmit, control, errors } = useForm({
    defaultValues:formData,
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormControl>
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Flex spacing={20} container marginBottom column>
        <Flex xs="100%">
          <FormInput
            error={errors.lastName}
            errors={errors}
            name="lastName"
            placeholder={formatMessage({ id: "form_last_name" })}
            control={control}
            fullWidth
          />
        </Flex>
        <Flex xs="100%">
          <FormInput
            error={errors.firstName}
            errors={errors}
            name="firstName"
            placeholder={formatMessage({ id: "form_first_name" })}
            control={control}
            fullWidth
          />
        </Flex>
        <Flex xs="100%">
          <FormInput
            errors={errors}
            name="middleName"
            placeholder={formatMessage({ id: "form_middle_name" })}
            control={control}
            fullWidth
          />
        </Flex>
        <Flex container>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              width: '100%',
              height: '50px',
            }}
          >
            {formatMessage({ id: "sign_up_form_button_continue" })}
          </Button>
        </Flex>
      </Flex>
      <Flex container>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            dispatch(show(LOGIN));
            dispatch(hide(SIGN_UP));
          }}
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        >
          {formatMessage({ id: "sign_up_form_button_enter" })}
        </Button>
      </Flex>
    </form>
    </FormControl>
  );
};

export default FirstStep;
