import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import { connectModal, hide } from 'redux-modal';
import useIntl from "react-intl/lib/src/components/useIntl";
import { PERSONAL_AREA_SUCCESS_CHANGE_PASSWORD } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/checkСircleIcon.svg';

const PersonalAreaSuccessChangePasswordModal = ({
    show,
  }) => {
  const theme = useSelector((state) => state.theme.value);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();

  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(PERSONAL_AREA_SUCCESS_CHANGE_PASSWORD))}
        maxWidth="xl"
      >
        <DialogContent className={classes.body}>
          <div className={classes.modalImage}>
            <CheckCircleIcon />
          </div>
          <Typography className={classes.title}  variant="h4" align="center">
            {formatMessage({ id: "personal_area_form_success_change_password_title" })}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: PERSONAL_AREA_SUCCESS_CHANGE_PASSWORD,
  getModalState: (state) => state.modals,
})(PersonalAreaSuccessChangePasswordModal);
