import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 35,
    paddingBottom: 35,
    overflowY:"auto",
    '@media (max-width: 768px)':{
      paddingBottom:190,
    }
  },
  programModalWrapper: {
    width: '100%',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    '@media (max-width: 1440px)': {
      flexDirection: 'column',
    },
  },
  leftSide: {
    display: "flex",
    justifyContent: 'center',
    marginTop:136,
    width: 'calc(100% - 310px)',

    '@media (max-width: 1440px)': {
      width: '100%',
      justifyContent: 'flex-start',
      marginTop:10,
    },
    '@media (max-width: 1000px)': {
      display: "none",
    },
  },
  banner: {
    position: 'relative',
    width: '748px',
    height: '340px',
    "@media (min-width:1440px)":{
      
    },

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  bannerTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    padding:"4px",
    top: '0',
    left: '0',
    width: '100%',
    height: '45px',
    "&>img":{
      objectFit:"contain"

    }
  },
  leftItemImage:{
    objectFit:"contain",

  },
  leftItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '100px',
    left: '190px',
    width: '90px',
    height: '45px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    padding:"1.5px 6px",
    

    '& svg': {
      width: '90%',
      height: 'auto',
    },

    '&:nth-child(2)': {
      top: '157px',
    },
    '&:last-child': {
      top: '214px',
    },
  },
  bannerStand: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '17px',
    left: '50%',
    width: '140px',
    height: '120px',
    marginLeft: '-70px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding:"6px 12px",
    cursor: 'pointer',

    '& svg': {
      width: '80%',
      height: 'auto',
    },
  },
  rightItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '85px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    padding:8,
    '& >div': {
      flex:1,
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      minHeight:"100%",
      minWidth:"100%",
      overflow:"hidden",
      
      "&>img":{
        minWidth:75,
        minHeight:100,
        // transform: 'rotate(-90deg)',
        objectFit:"contain",
      }
    },
   
  },
  rightItemsContainer:{
    '&>:first-child': {
      bottom: '25px',
      right: '158px',
      height: '190px',
      
    },
    '&>:last-child': {
      bottom: '15px',
      right: '90px',
      height: '210px',
    },
  },

  buttonColumn:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    justifyContent:'flex-start',
    alignItems:'flex-end',
    "&:first-child":{
      marginRight:20
    },
    "&>div>a>div":{
      width:'100%',
      '@media (max-width: 1440px)': {
        width:300,
      },
      '@media (max-width: 1000px)':{
        minWidth:'100%',
      },
    },
    '@media (max-width: 1000px)':{
      "&:first-child":{
        alignItems:'flex-start',
      },
    },
  },
  rightSide: {
    width: '280px',
    '@media (max-width: 1440px)': {
      width:"100%",
      display:"flex",
      marginTop:40
    },
    '@media (max-width: 1000px)':{
      width:'100%',
      flexDirection:'column',
      marginTop:40
    },
    '@media (max-width: 768px)': {
      display:'flex',
      width: '100%',
      marginTop: '40px',
      flexDirection:"column",
      maxHeight:"100vw",
      "&>div:first-child":{
        flex:1
      },
      "&>div:last-child":{
        display:'flex',
        flexDirection:"column",
        maxHeight:'100%',
        "&>a>div":{
          width:"100%"
        },
        "&>div":{
          "&:second-child":{
          }
        }
      },
      "& button":{
        marginTop:40,
        minHeight:40,
        
      }
    },
  },
  participantHeader: {
    // height: '80px',

    '@media (max-width: 1440px)': {
      height: 'auto',
      marginRight:75
    },
  },
  participantTitle: {
    color: '#58727F',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '16px',
  },
  participantLogo: {
    display: 'flex',
    maxWidth: '190px',
    maxHeight: 80,
    margin: '10px 0 30px',
    "& > img":{
      objectFit:'contain',
    },
    '@media (max-width: 1440px)':{
      marginBottom:10
    }
  },
  participantInfo: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    '@media (max-width: 1440px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    marginBottom: '10px',
    padding: '0 10px',
    background: palette.common.cream,
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      color: palette.common.white,
      background: palette.secondary.main,
      
    },
    '&:hover *': {
      color: palette.common.white,
    },

    '@media (max-width: 1440px)': {
      // width: '49%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  arrowRight: {
    color: palette.secondary.main,
  },
  participantButtonBack: {
    width: '150px',
    height: '40px',
    margin: '40px 20px 0 0',
    padding: '0',
    fontSize: '14px',
  },
}));

export default useStyles;
















