// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_REQUEST_TAB: 'SET_REQUEST_TAB',
  FETCH_GET_BROADCAST_BY_HALL_START: 'FETCH_GET_BROADCAST_BY_HALL_START',
  FETCH_GET_BROADCAST_BY_HALL_SUCCESS: 'FETCH_GET_BROADCAST_BY_HALL_SUCCESS',
  FETCH_GET_BROADCAST_BY_HALL_ERROR: 'FETCH_GET_BROADCAST_BY_HALL_ERROR',
  FETCH_GET_BROADCAST_BY_HALL_CLEAR_ERROR:
    'FETCH_GET_BROADCAST_BY_HALL_CLEAR_ERROR',
};
