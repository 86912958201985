import { LOCALES } from '../locales';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.ENGLISH]: {
    close_modal_button: 'Close',
    feedback_sent: 'Feedback sent',
    authorization_repeat_send_notification:
      'A message has been sent to your e-mail',
    /*Header*/
    header_menu_home: 'Info',
    header_menu_translation: 'LIVESTREAM',
    header_menu_scientific_program: 'Scientific Programme',
    header_menu_program: 'Programme',
    header_menu_electronic_posters: 'E-posters',
    header_menu_materials: 'Proceedings',
    header_menu_exhibition: 'Exhibition',
    header_menu_partner_videos: "Partners' Video",
    header_menu_satellite_symposiums: 'Satellite symposia',
    header_menu_webinars: 'Webinars',
    header_personal_area: 'My profile',
    header_exit: 'Exit',

    /*Program Modal*/
    program_modal_moderator: 'Moderator',
    program_modal_pres: 'Chairpersons',
    program_modal_prog_scientific: 'Session programme',
    /*Copyright agreement*/
    copyright_title: 'Private Policy',
    /*Footer*/
    footer_title_1: 'Contact the Organizing Committee for more details',
    footer_title_2:
      '© Research-to-Practice Conference Filatov Memorial Lectures 2021',
    footer_phone_name_1: 'Stoylovska Elena',
    footer_phone_name_2: 'Myrnenko Valery',
    footer_mail_text: 'For web site issues:',
    footer_privacy_policy: 'Private Policy',
    footer_social: 'We on Facebook',
    /*Home page */
    home_title_1: 'Dear colleagues!',
    home_title_2: `Welcome to the filatov.academy Platform!`,
    home_registration:
      'The registration certificate of the Ukrainian Institute of Scientific and Technical Expertise and Information No 503 from November 21, 2022',
    home_faq_title: 'Questions and Answers',
    /*Public Home */
    pub_home_institute:
      'Filatov Institute of Eye Diseases and Tissue Therapy of NAMS of Ukraine',
    pub_home_tov: 'Ukrainian Society of Ophthalmologists',
    pub_home_date: 'May 2021 Odesa, Ukraine',
    pub_home_welcome1: 'Welcome to the platform',
    pub_home_welcome2: 'for the sientific and practical conference',
    pub_home_login_button: 'Log in',
    pub_home_register_button: 'Register',
    pub_home_title: '2021 Filatov Memorial Lectures',
    /*Form*/
    form_last_name: 'Last name*',
    form_first_name: 'First Name*',
    form_middle_name: 'Second name',
    form_country: 'Country*',
    form_city: 'Place of Residence*',
    form_work_place: 'Affiliation*',
    form_birth_date: 'Date of birth*',
    form_post: 'Position*',
    form_email: 'E-mail*',
    form_password: 'Password*',
    form_question: 'Your question*',
    form_send_button: 'Send',
    form_add_new_password_title: 'Enter a new password',
    form_add_new_password_password: 'New password*',
    form_add_new_password_confirm: 'Confirm the password*',
    form_forgot_password: 'Forgot your password?',
    form_registration: 'Register',
    form_checkbox_text: 'Agreement with',
    form_is_online_label: 'Select participation format:',
    form_is_online_checkbox_text: 'I will attend the conference online only',
    form_is_offline_checkbox_text: 'I will attend the conference offline',
    form_checkbox_privacy_policy: 'Private Policy',
    form_button_back: 'Back',
    inputErrors_required: 'Required field',
    inputErrors_required_emil: 'Wrong email',
    inputErrors_required_password:
      'The password must be at least 8 characters long',

    /*Login Form*/
    login_form_title: 'Join the conference',
    login_form_button_enter: 'Log in',

    /*Sign Up Form*/
    sign_up_form_title: 'Registration',
    sign_up_form_step_quantity: 'of 3',
    sign_up_form_button_continue: 'Continue',
    sign_up_form_button_finish: 'Register',
    sign_up_form_button_enter: 'Log in',

    /*Password Restore Form*/
    password_restore_form_title: 'Renew the password',
    password_restore_form_description:
      'We will send a password reset link to the email address provided during registration.',
    password_restore_form_button: 'Renew the password',

    /*Confirm Success Form*/
    confirm_success_form_title: 'The link has been sent to your email address',
    confirm_reg_success_form_title: 'Thank you for registering',
    confirm_success_restore_form_title: 'Your password has been renewed',
    confirm_success_activate_form_title: 'Your email has been confirmed',
    confirm_success_activate_description:
      'The administrator must verify your account',
    confirm_success_form_description:
      'Follow the link and enter a new password',
    confirm_success_reg_description:
      'Registration Confirmation has been sent to your email address',
    confirm_success_restore_description: 'Now you can log in',

    /*Confirm Error Form*/
    confirm_error_form_title: 'Sorry, this email address does not exist',
    confirm_error_form_button: 'Register',

    /*Add New Password Form*/
    add_new_password_form_description:
      'The password can be changed in the personal account',
    add_new_password_form_button: 'Save',

    /*Feedback Form*/
    feedback_form_title: 'Feedback form',

    /*Personal Area*/
    personal_area_title: 'Personal account',
    personal_area_button_download: 'Download the certificate',
    personal_area_button_send: 'Send to email',
    personal_area_button_list: 'СМЕ survey',
    personal_area_subtitle1: 'Personal data',
    personal_area_subtitle2: 'Additional Information',
    personal_area_button_change: 'Change the password',
    personal_area_button_save: 'Save',
    personal_area_form_success_change_password_title:
      'Your password has been changed',
    /*Translations */
    translation_online_chat: 'Online chat',
    youtube_no_video: 'The video will be available later',
    translation_musem_hall: 'Museum Hall',
    translation_small_hall: 'Small Hall',
    translation_great_hall: 'Big Hall',
    translation_21_may: '21 May',
    translation_20_may: '20 May',
    translation_programlist_title: 'Conference Programme',
    translation_programlist_no_program: 'No programme...💤',
    chat_ban_message:
      'Your account has been blocked from chatting. Contact the administration for clarification.',
    chat_unban_message:
      'Your account has been unlocked in chat. You can now write the message again',
    chat_header_1: 'In online chat you can ',
    chat_header_2: 'ask the speaker a question.',
    more_messages_add: 'More messages...',
    more_messages_none: 'No more messages...',
    message_place_holder: 'Messages',
    viewers_count_online: 'Online:',
    viewers_total_offline: 'Views:',
    viewers_persons: ' Persons',
    /*Program*/
    program_title: 'Conference Programme',
    program_great_hall: 'Big Hall',
    program_small_hall: 'Small Hall',
    program_museum_hall: 'Museum Hall',
    program_watch: 'Watch live streaming',
    program_date_month: 'May',

    /*Electronic Posters*/
    electronic_posters_title: 'E-posters',

    /*Satellite Symposiums*/
    satellite_symposiums_title: 'Satellite symposium',
    satellite_symposiums_partner: 'General Partner',

    /*Webinars*/
    webinars_title: 'Webinars',

    /*Exhibitions*/
    exhibition_title: "Visit the exhibitors' pages",
    exhibition_button_back: 'Back to Exhibition',
    exhibition_general_partner: 'General Partner',
    exhibition_general_participant: 'Exhibitor',
    exhibition_bussinesscard: 'Business card',
    exhibition_materials: 'Materials',
    exhibition_satelite: 'Satellite symposium',
    exhibition_webinar: 'Webinar',
    exhibition_feedback: 'Feedback',
    exhibition_lottery: 'Drawing of Lottery',
    /*Control Panel Component*/
    control_panel_component_of: 'from',

    // Material
    download_btn_title: 'Download materials',
  },
};
