import { toast } from "react-toastify";
toast.configure();
const notify = (msg, type, config = {}) => {
  const options = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    ...config,
  };

  switch (type) {
    case "success":
      return toast.success(msg, options);
    case "error":
      return toast.error(msg, options);
    case "warn":
      return toast.warn(msg, options);
    case "info":
      return toast.info(msg, options);
    default:
      return toast.info(msg, options);
  }
};

export default notify;
