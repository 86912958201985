import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 35,
    paddingBottom: 35,
  },
  personalAreaInfo: {
    display: 'flex',
    justifyContent: 'space-between',

    '@media (max-width: 480px)': {
      display: 'block',
    },
  },
  personalAreaButtonsWrapper: {
    display: 'flex',
    flex:1.5,
    flexDirection:'row',
    justifyContent:"flex-end",
    flexWrap:'wrap',
    "& button":{
      marginLeft:10,
      '@media (max-width: 768px)':{
        marginLeft:0
      }
    }
  },
  linkStyle:{
      textDecoration:"none !important"
  },
  personalAreaButton: {
    width: '200px',
    height: '35px',
    padding: '0',
    marginRight: '20px',
    color: palette.primary.main,
    fontSize: '14px',
    fontWeight: '500',
    
    '&:last-child': {
      marginRight: '0',
    },
    '& svg': {
      width: '16px',
      height: '16px',
      marginLeft: '5px',
      
    },

    '&:hover': {
      color: palette.common.white,
      background: palette.secondary.main,
    },
    '&:hover *': {
      color: palette.common.white,
      stroke:palette.common.white
      
    },

    '@media (max-width: 1000px)': {
      marginBottom: '20px',
      marginRight: '0',
      '&:last-child': {
        marginBottom: '0',
      },
    },
    '@media (max-width: 480px)': {
      '&:first-child': {
        marginTop: '20px',
      },
    },
  },

  form: {

  },
  label: {
    marginBottom: '10px',
    fontSize: '12px',
  },
  personalAreaFieldWrapper: {
    display: 'flex',

    '@media (max-width: 768px)': {
     display: 'block',
    },
  },
  personalAreaFieldWrapperWithButton: {
    display: 'flex',
  },
  personalAreaFieldWrapperBottom: {
    marginBottom: '20px',
  },
  personalAreaField: {
    width: '250px',
    marginRight: '20px',
    '&:last-child': {
      marginRight: '0',
    },

    '@media (max-width: 1000px)': {
      width: '220px',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      marginBottom: '20px',

      '&:last-child': {
        marginBottom: '0',
      },
    },
  },
  personalAreaButtonChange: {
    display: 'flex',
    height: '35px',
    marginTop: '34.5px',

    '@media (max-width: 768px)': {
      height: 'auto',
      marginTop: '0',
    },
  },
  personalAreaButtonSave: {
    width: '100px',
    height: '35px',
    marginTop: '40px',

    '@media (max-width: 768px)': {
      marginTop: '30px',
    },
  },
}));

export default useStyles;
