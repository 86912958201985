import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  dialog: {
    '@media (max-width: 480px)': {
      '&>div> .MuiDialog-paper': {
        margin: '0 !important',
      },
    },
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '620px',
    padding: '50px 40px!important',
    backgroundColor: palette.common.white,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    '@media (max-width: 1000px)': {
      width: '560px',
    },
    '@media (max-width: 768px)': {
      width: '380px',
      padding: '50px 65px 40px 65px !important',
    },
    '@media (max-width: 480px)': {
      width: '340px',
      // padding: '50px 45px 40px 45px !important',
    },
  },
  closer: {
    display: 'flex',
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '0 !important',
    color: '#58727F',
    cursor: 'pointer',

    '@media (max-width: 1000px)': {
      top: '10px',
      right: '10px',
    },
  },
  closerText: {
    marginRight: '10px',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    lineHeight: '29px',
  },
  form: {
    width: '520px',
    marginTop: '40px',

    '@media (max-width: 1000px)': {
      width: '460px',
    },
    '@media (max-width: 768px)': {
      width: '250px',
      marginTop: '30px',
    },
  },
  title: {
    '@media (max-width: 768px)': {
      textAlign: 'center',
    },
  },
  topInputContainer: {
    marginBottom: 0,
  },
  feedbackFormInput: {
    width: '100%',
    marginBottom: '20px !important',
    '@media (max-width: 768px)': {
      marginBottom: '15px !important',
    },
  },
  feedbackFormTextArea: {
    height: '140px !important',
  },
  feedbackFormButtonWrapper: {
    justifyContent: 'flex-end',

    '@media (max-width: 768px)': {
      justifyContent: 'center',
    },
  },
  feedbackFormButton: {
    width: '180px',
    height: '50px',
  },
}));

export default useStyles;
