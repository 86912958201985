import { makeStyles } from '@material-ui/styles';

import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    fontSize: '18px',
    fontWeight: '200',
    lineHeight: '24px',
    backgroundColor: palette.common.sky,
    zIndex: '2',
    wordWrap: 'break-word',
  },
  headerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',

    '@media (max-width: 1440px)': {
      maxWidth: 1176,
    },
    '@media (max-width: 1000px)': {
      width: 700,
      height: '80px',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '@media (max-width: 768px)': {
      width: '96%',
      padding: '0 !important',
    },
    '@media (max-width: 700px)': {
      fontSize: '12px',
      lineHeight: '16px',
    },
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  headerLogo: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      maxWidth: '88px',
      maxHeight: '52px',
      marginRight: '20px',
    },
    '& img': {
      maxWidth: '88px',
      maxHeight: '52px',
      marginRight: '20px',
    },

    '@media (max-width: 1000px)': {
      width: '50%',
    },
    '@media (max-width: 600px)': {
      width: '100%',
      '& svg': {
        maxWidth: '40px',
        maxHeight: '24px',
      },
      '& img': {
        maxWidth: '40px',
        maxHeight: '24px',
      },
    },
  },
  logoTextFirst: {
    width: '400px',
    marginRight: '15px',

    '@media (max-width: 1000px)': {
      width: 'auto',
    },
  },
  logoTextSecond: {
    width: '280px',

    '@media (max-width: 1000px)': {
      width: 'auto',
    },
  },
  homeBanner: {
    overflow: 'hidden',
    height: '230px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  bannerText: {
    maxWidth: '130px',
    marginTop: '100px',
    color: palette.common.white,
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '22px',
    '& span': {
      display: 'block',
      fontSize: '36px',
      lineHeight: '54px',
    },
  },
  mainPageContent: {
    flexDirection: 'column',
    marginTop: '60px',
    wordWrap: 'break-word',
  },
  mainPageButtons: {
    marginTop: '20px',
  },
  companyLogos: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '35px 0 35px',

    '@media (max-width: 1000px)': {
      justifyContent: 'flex-start',
    },
  },
}));

export default useStyles;