export const CHAT_START_PAGE=1
export const CHAT_MESSAGE_LIMIT=15 // Limit per page - for continuos upload/load 10-15 optimal
export const MESSAGE_SEND_DELAY=1000 // Miliseconds 3000===3 seconds
export const hallTypes = [
    {
      value: 1,
      text: "Великий Зал 20 Травня",
    },
    { value: 2, text: "Малий Зал 20 Травня" },
    { value: 3, text: "Музейний Зал 20 Травня" },{
      value: 4,
      text: "Великий Зал 21 Травня",
    },
    { value: 5, text: "Малий Зал 21 Травня" },
    { value: 6, text: "Музейний Зал 21 Травня" },
  ];

export const cmeLinks = {
  UA:"https://forms.gle/vsXXC4mQfHiFTRkh8",
  EN:"https://forms.gle/1SN1MFLTA1W1P3AY6"
}