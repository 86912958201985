import palette from '../palette';

const MuiInputLabel = {
  root: {
    marginBottom: 10,
    color: palette.common.black,
    fontSize: 15,
    fontWeight: 500,
  },
};

export default MuiInputLabel;
