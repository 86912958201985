/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { LOCALES } from '../../../../lang/locales';
// Services
import DateFormats from '../../../../services/DateFormats';

const localization = (locale) => {
  const dict = {
    [LOCALES.UKRAINIAN]: {
      title: 'titleUA',
      authors: 'authorsUA',
    },
    [LOCALES.ENGLISH]: {
      title: 'titleEN',
      authors: 'authorsEN',
    },
  };
  return dict[locale];
};

const ProgramListItem = ({ item, active }) => {
  const classes = useStyles();
  const locale = useSelector((state) => state.locale.language);
  const {
    startedAt,
    endAt,
    id,
  } = item;

  let title= localization(locale).title;
  let authors=localization(locale).authors;
  useEffect(() => {
    title = localization(locale).title;
    authors = localization(locale).authors;
  }, [locale]);

  return (
    <Box>
      <Typography
        className={classNames(
          classes.programItemTime,
          active && active === id && classes.activeProgram,
        )}
      >{`${DateFormats.parseTimeStamp(
        startedAt,
      )} - ${DateFormats.parseTimeStamp(endAt)}`}</Typography>
      <Typography
        className={classNames(
          classes.programItemText,
          active && active === id && classes.activeProgram,
        )}
      >
        {`${item[title]} - ${item[authors]}`}
      </Typography>
    </Box>
  );
};

export default ProgramListItem;
