import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  body: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    width: '580px',
    padding: '0 !important',
    backgroundColor: palette.common.white,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    "@media (max-width: 768px)": {
      width: '100%',
    },
  },
  closer: {
    display: "flex",
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '0 !important',
    color: '#58727F',
    cursor: 'pointer',

    "@media (max-width: 1000px)": {
      top: '10px',
      right: '10px',
    },
  },
  closerText: {
    marginRight: '10px',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    lineHeight: '29px'
  },
  businessCard: {
    padding:32,
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}));

export default useStyles;