import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  dialog: {
    '@media (max-width: 480px)': {
      '&>div> .MuiDialog-paper': {
        margin: '0 !important',
      },
    },
  },
  backdrop: {
    zIndex: 10000,
    color: '#fff',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '380px',
    backgroundColor: palette.common.sky,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    '@media (max-width: 480px)': {
      width: '340px',
    },
  },
  modalImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '65px',
    height: '65px',
    marginBottom: '20px',
    padding: '0',
    color: '#58727F',
    borderRadius: '50%',
    backgroundColor: palette.common.cream,
  },
  form: {
    width: '250px',
    marginTop: '40px',
  },
  visibility: {
    width: '16px',
    height: '16px',
    marginRight: '0',
    color: '#58727F',
  },
}));

export default useStyles;
