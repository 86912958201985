import { LOCALES } from '../locales';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.UKRAINIAN]: {
    close_modal_button: 'Закрити',
    feedback_sent: 'Відгук надіслано',
    authorization_repeat_send_notification:
      'Лист на цю поштову адресу вже було відправлено',
    /*Header*/
    header_menu_home: 'Info',
    header_menu_translation: 'Трансляція',
    header_menu_scientific_program: 'Наукова програма',
    header_menu_program: 'Програма',
    header_menu_electronic_posters: 'Електронні постери',
    header_menu_materials: 'Матеріали',
    header_menu_exhibition: 'Виставка',
    header_menu_partner_videos: 'Відео партнерів',
    header_menu_satellite_symposiums: 'Сателітний симпозіум',
    header_menu_webinars: 'Вебінар',
    header_personal_area: 'Мій профайл',
    header_exit: 'Вийти',

    /*Program Modal*/
    program_modal_moderator: 'Модератор',
    program_modal_pres: 'Президія',
    program_modal_prog_scientific: 'Програма наукової сесії',
    /*Copyright agreement*/
    copyright_title: 'Політика конфіденційності',
    /*Footer*/
    footer_title_1:
      'Додаткову інформацію можна отримати за телефонами Оргкомітету:',
    footer_title_2: '© Науково-практична конференція Філатовські читання 2021',
    footer_phone_name_1: 'Стойловська Олена Георгіївна',
    footer_phone_name_2: 'Мирненко Валерія Вікторівна',
    footer_mail_text: 'З питань роботи сайту:',
    footer_privacy_policy: 'Політика конфіденційності',
    footer_social: 'Ми на Facebook',
    /*Home page */
    home_title_1: 'Шановні колеги!',
    home_title_2: `Ми раді вітати Вас на платформі filatov.academy!`,
    home_registration:
      'Реєстраційне посвідчення УкрІНТЕІ № 503 від 21 листопада 2022 року.',
    home_faq_title: 'Запитання та відповіді',
    /*Public Home */
    pub_home_institute:
      'ДУ “Інститут очних хвороб і тканинної терапії ім. В.П.Філатова НАМН України”',
    pub_home_tov: 'ГО “Товариство офтальмологів України”',
    pub_home_date: 'травня 2021 Одеса, Україна',
    pub_home_welcome1: 'Ласкаво просимо на сайт',
    pub_home_welcome2: 'науково-практичної конференції',
    pub_home_login_button: 'Увійти',
    pub_home_register_button: 'Зареєструватися',
    pub_home_title: 'Філатовські читання 2021',
    /*Form*/
    form_last_name: 'Прізвище',
    form_first_name: "Ім'я",
    form_middle_name: 'По батькові',
    form_country: 'Країна*',
    form_city: 'Місто*',
    form_work_place: 'Місце роботи*',
    form_birth_date: 'Дата народження*',
    form_post: 'Посада*',
    form_email: 'E-mail*',
    form_password: 'Пароль*',
    form_question: 'Ваше питання*',
    form_send_button: 'Відправити',
    form_add_new_password_title: 'Вкажіть новий пароль',
    form_add_new_password_password: 'Новий пароль*',
    form_add_new_password_confirm: 'Підтвердити пароль*',
    form_forgot_password: 'Забули пароль?',
    form_registration: 'Зареєструватися',
    form_checkbox_text: 'Угода з',
    form_is_online_label: 'Виберіть форму участі:',
    form_is_online_checkbox_text: 'Буду на конференції тільки онлайн',
    form_is_offline_checkbox_text: 'Буду на конференції офлайн',
    form_checkbox_privacy_policy: 'політикою конфіденційності',
    form_button_back: 'Назад',
    inputErrors_required: "Обов'язкове поле",
    inputErrors_required_emil: 'Неправильний email',
    inputErrors_required_password: 'Пароль повинен містити не менше 8 символів',

    /*Login Form*/
    login_form_title: 'Увійти на конференцію',
    login_form_button_enter: 'Увійти',

    /*Sign Up Form*/
    sign_up_form_title: 'Реєстрація',
    sign_up_form_step_quantity: 'iз 3',
    sign_up_form_button_continue: 'Продовжити',
    sign_up_form_button_finish: 'Зареєструватися',
    sign_up_form_button_enter: 'Увійти',

    /*Password Restore Form*/
    password_restore_form_title: 'Відновити пароль',
    password_restore_form_description:
      'Ми відправимо посилання для відновлення пароля на електронну адресу, вказану при реєстрації.',
    password_restore_form_button: 'Відновити пароль',

    /*Confirm Success Form*/
    confirm_success_form_title: 'Посилання вже на електронній пошті',
    confirm_reg_success_form_title: 'Дякуємо за реєстрацію.',
    confirm_success_restore_form_title: 'Відновлення пароля завершено успішно',
    confirm_success_activate_form_title: 'Поштову адресу підтверджено',
    confirm_success_activate_description:
      'Адміністратор мусить підтвердити ваш обліковий запис.',
    confirm_success_form_description:
      'Перейдіть по посиланню і вкажіть новий пароль',
    confirm_success_reg_description:
      'На Вашу електронну адресу надіслано лист з підтвердженням реєстрації.',
    confirm_success_restore_description: 'Тепер ви можете увійти на сайт',

    /*Confirm Error Form*/
    confirm_error_form_title: 'Вибачте, такої електронної адреси не існує',
    confirm_error_form_button: 'Зареєструватися',

    /*Add New Password Form*/
    add_new_password_form_description:
      'Пароль можна буде змінити в особистому кабінеті',
    add_new_password_form_button: 'Зберегти',

    /*Feedback Form*/
    feedback_form_title: "Форма зворотнього зв'язку",

    /*Personal Area*/
    personal_area_title: 'Особистий кабінет',
    personal_area_button_download: 'Завантажити диплом',
    personal_area_button_send: 'Відправити на пошту',
    personal_area_button_list: 'СМЕ опитування',
    personal_area_subtitle1: 'Особисті дані',
    personal_area_subtitle2: 'Додаткова інформація',
    personal_area_button_change: 'Змінити пароль',
    personal_area_button_save: 'Зберегти',
    personal_area_form_success_change_password_title:
      'Ваш пароль успішно змінено',
    /*Translations */
    translation_online_chat: 'Онлайн чат',
    youtube_no_video: 'Відео буде доступно пізніше',
    translation_musem_hall: 'Музейний Зал',
    translation_small_hall: 'Малий Зал',
    translation_great_hall: 'Великий Зал',
    translation_21_may: '21 травня',
    translation_20_may: '20 травня',
    translation_programlist_title: 'Програма конференції',
    translation_programlist_no_program: 'Немає програми...💤',
    chat_ban_message:
      'Ваш обліковий запис був заблокований у чаті. Зверніться до адміністрації для пояснень.',
    chat_unban_message:
      'Ваш обліковий запис був розблокований у чаті. Тепер ви знову можете писати повідомлення',
    chat_header_1: 'В онлайн-чаті Ви маєте можливість ',
    chat_header_2: 'задати питання спікеру.',
    more_messages_add: 'Більше повідомлень...',
    more_messages_none: 'Більше немає повідомлень...',
    message_place_holder: 'Повідомлення',
    viewers_count_online: 'Онлайн:',
    viewers_total_offline: 'Переглядів:',
    viewers_persons: ' осіб',
    /*Program*/
    program_title: 'Програма конференції',
    program_great_hall: 'Великий зал',
    program_small_hall: 'Малий зал',
    program_museum_hall: 'Музейний зал',
    program_watch: 'Дивитися трансляцію',
    program_date_month: 'травня',

    /*Electronic Posters*/
    electronic_posters_title: 'Електронні постери',

    /*Satellite Symposiums*/
    satellite_symposiums_title: 'Сателітні симпозіумии',
    satellite_symposiums_partner: 'Генеральний партнер',

    /*Webinars*/
    webinars_title: 'Вебінари',

    /*Exhibitions*/
    exhibition_title: 'Відвідайте сторінки учасників',
    exhibition_button_back: 'Назад до виставки',
    exhibition_general_partner: 'Генеральний партнер',
    exhibition_general_participant: 'Участник виставки',
    exhibition_bussinesscard: 'Візитка',
    exhibition_materials: 'Матеріали',
    exhibition_satelite: 'Сателітний симпозіум',
    exhibition_webinar: 'Вебінар',
    exhibition_feedback: "Зворотній зв'язок",
    exhibition_lottery: 'Участь у розіграші',
    /*Control Panel Component*/
    control_panel_component_of: 'із',

    // Material

    download_btn_title: 'Завантажити матеріали',
  },
};
