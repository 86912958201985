import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 35,
    paddingBottom: 35,
  },
  exhibitionTable: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: '30px',
    marginRight:-20,
    '@media (max-width: 768px)': {
      marginRight:0
    }
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'calc(50% - 20px)',
    minHeight: '80px',
    marginBottom: '20px',
    padding: '12px',
    marginRight:20,
    background: palette.common.cream,
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:`3px 2px 10px 0px ${palette.secondary.main}, 3px -2px 10px 0px ${palette.secondary.main},-3px -2px 10px 0px ${palette.secondary.main},-3px 2px 10px 0px ${palette.secondary.main},inset 0px 0px 15px -4px ${palette.secondary.main}`,
      // textDecoration:"underline",
      "&>svg":{
        color:"white",
        background:palette.secondary.main,
        borderRadius:"100%"
      },
    },
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      width: '100%',
      marginRight:0
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '27px',
  },
  titleIcon: {
    marginRight: '9px'
  },
  title: {
    color: '#294F5F',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18.2px',
  },
  downloadRow: {
    display: 'flex'
  },
  downloadTitle: {
    fontFamily: 'Inter',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '15.6px',
    marginRight: '7px',
  }
  
}));

export default useStyles;
