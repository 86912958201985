import React from 'react';
// Material Components
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({style,color="primary",size}) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: 'inherit',
        justifyContent: 'center',
        alignItems: 'center',
        ...style
      }}
    >
      <CircularProgress color={color} size={size} />
    </div>
  );
};

export default Loading;
