import palette from '../palette';

const MuiSelect = {
  outlined: {
    padding: '12px 11px 12px 16px',
    height: '45px!important',
    background: palette.common.white,

    '&:focus': {
      background: palette.common.white,
    },
    '&.MuiSelect-outlined': {
      paddingRight: 25,
    },
  },
  root: {
    fontSize: 13,
    fontWeight: 500,
    color: palette.primary.main,
    outline: 'none!important',

    '& + input + svg + fieldset': {
      display: 'none!important',
    },
  },
  select: {
    borderRadius: '8px!important',
    border: `1px solid ${palette.primary.main}`,

    '& legend': {
      display: 'none',
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  icon: {
    color: 'transparent',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.07104 5.58343L11.8645 0.799955C12.0449 0.619862 12.0452 0.327639 11.8652 0.147247C11.6851 -0.0331916 11.3929 -0.0334916 11.2124 0.146555L6.41865 4.93038C6.2387 5.11033 5.9459 5.11033 5.7656 4.93003L0.972153 0.146555C0.791715 -0.0334916 0.499491 -0.0331916 0.319445 0.147247C0.229537 0.237316 0.184607 0.355308 0.184607 0.473255C0.184607 0.591524 0.229791 0.709793 0.320137 0.799955L5.11324 5.58309C5.6531 6.12295 6.53152 6.12295 7.07104 5.58343Z' fill='%2322213D'/%3E%3C/svg%3E%0A\")",
  },
};

export default MuiSelect;
