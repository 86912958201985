import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles((theme) => ({
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    userSelect: 'none',
    alignItems: 'center',
    height: '40px',
    marginBottom: '10px',
    padding: '0 10px',
    background: palette.common.cream,
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      color: palette.common.white,
      background: palette.secondary.main,
      boxShadow: '0px 14px 24px -10px rgba(25, 69, 126, 0.7)',
    },
    '&:hover *': {
      color: palette.common.white,
    },

    '@media (max-width: 1440px)': {
      width: '49%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
    '&>svg': {
      transition: 'all 0.1s linear',
    },
  },
  blockList: {
    color: palette.common.white,
    background: palette.secondary.main,
    '&>svg': {
      transform: 'rotate(90deg)',
      color: palette.common.white,
    },
  },
  blockDropDownContainer: {
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 5px 5px',
    marginBottom: 10,
    '@media (max-width: 1440px)': {
      // width: '49%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  dropdownItem: {
    padding: 10,
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,

    '&:hover': {
      background: '#F4F5F9',
      color: palette.secondary.main,
    },
  },
  dropLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  arrowRight: {
    color: palette.secondary.main,
  },
}));

export default useStyles;
