const MuiMenu = {
  paper: {
    width: '220px',
    marginTop: 10,
    borderRadius: 5,
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',
  },
};

export default MuiMenu;
