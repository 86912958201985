import { makeStyles } from '@material-ui/styles';

import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  switcher: {
    display: 'flex',
    margin: '0',
    padding: '0',
    '& li': {
      listStyleType: 'none',
      '&:first-child': {
        marginRight: '10px',
      },
    },
  },
  switchLanguage: {
    padding: '0',
    color: '#58727F',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '24px',
    textTransform: 'uppercase',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
  },
  switchLanguageActive: {
    color: palette.secondary.main,
    fontSize: '16px',
    fontWeight: '500',
  },
}));

export default useStyles;
