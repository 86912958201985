import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {useSelector} from 'react-redux'
const PrivateRoute = ({children, ...props}) => {
  const isAuth = useSelector(state=>state.user.isAuthorized)
  if (!isAuth) {
    return <Redirect to='/'/>
  }

  return (
    <Route {...props}>
      {children}
    </Route>
  );
};

export default PrivateRoute;

