
import { MaterialListTypes } from "../actionTypes/materialTypes";

const {
  FETCH_GET_MATERIAL_LIST_START,
  FETCH_GET_MATERIAL_LIST_SUCCESS,
  FETCH_GET_MATERIAL_LIST_ERROR,
  FETCH_GET_MATERIAL_LIST_CLEAR_ERROR
} = MaterialListTypes;

const INIT_STATE = {
  materials: [],
  loading: false,
  error: "",
};
const materialsListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_MATERIAL_LIST_START:
      return { ...state, loading: true, error: "", materials: []};
    case FETCH_GET_MATERIAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        materials: action.materials,
      };
    case FETCH_GET_MATERIAL_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_GET_MATERIAL_LIST_CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

export default materialsListReducer;
