import palette from '../palette';

const MuiAccordionDetails = {
  root: {
    '& .MuiTypography-root': {
      fontSize: '14px',
      color: palette.primary.main,
      fontWeight: '400',
    },
  },
};

export default MuiAccordionDetails;
