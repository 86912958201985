
import ApiService from "../../services/ApiService";
import { MaterialListTypes } from "../actionTypes/materialTypes";

const fetchMaterialsError=(data)=>({
  type:MaterialListTypes.FETCH_GET_MATERIAL_LIST_ERROR,
  payload:data
})
const fetchMaterialsClearError=()=>({
  type:MaterialListTypes.FETCH_GET_MATERIAL_LIST_CLEAR_ERROR
})
const fetchMaterialsGetSuccess = (data) => ({
  type: MaterialListTypes.FETCH_GET_MATERIAL_LIST_SUCCESS,
  materials: data,
});

const fetchMaterialsGetStart = () => ({
  type: MaterialListTypes.FETCH_GET_MATERIAL_LIST_START,
});


export const fetchMaterialsListAsync = (
  dispatch,
) => {

  dispatch(fetchMaterialsGetStart());

  ApiService.apiCall({
    url: ApiService.paths.materials.GET_ALL,
    token: true,
  })
    .then((response) => {
      if(response?.materials) {
        dispatch(fetchMaterialsGetSuccess(response?.materials?.materials))
      }
    })
    .catch((error) => {
      dispatch(fetchMaterialsError(error));
      setTimeout(() => {
        fetchMaterialsClearError();
      }, 300);
    });
};

