export const getControllFildsValue = (fieldsArray, keyUA, keyEN) => {
  const language = localStorage.getItem('fil_locale') || 'uk-UA';

  let result = '';

  fieldsArray.forEach((field, index) => {
    if (field.key === (language === 'uk-UA' ? keyUA : keyEN)) {
      result =
        field.value === 'true'
          ? true
          : field.value === 'false'
          ? false
          : field.value.replaceAll(/\s/g, ' ');
    }
  });

  return result;
};
