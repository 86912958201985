import palette from '../palette';

const MuiTextField = {
  root: {
    position: 'relative',
    height: '50px',
    padding: 0,
    color: palette.primary.main,
    lineHeight: '50px',
    background: palette.common.cream,
    borderRadius: '5px',

    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      fontWeight: 400,
      fontSize: 12,
    },

    '& .MuiOutlinedInput-root': {
      height: '50px',
      padding: '0',
      color: palette.primary.main,
      fontSize: 12,
      fontWeight: 400,

      '& textarea': {
        height: '100%',
        padding: '18.5px 14px',
      },

      '&.Mui-error': {
        border: `1px solid red`,
      },
    },

    '& .MuiOutlinedInput-multiline': {
      height: '100%',
      padding: '0',
      color: palette.primary.main,
      fontSize: 12,
      fontWeight: 400,

      '&.Mui-error': {
        border: `1px solid red`,
      },
    },

    '&.proxy': {
      borderRadius: '6px 0 0 6px',

      '& .MuiOutlinedInput-root': {
        borderRadius: '6px 0 0 6px',
      },
    },

    '& input::placeholder': {
      color: '#58727F',
      fontSize: 12,
      fontWeight: 400,
      opacity: 1,
    },

    '& textarea::placeholder': {
      color: '#58727F',
      fontSize: 12,
      fontWeight: 400,
      opacity: 1,
    },

    '& fieldset': {
      display: 'none',
    },

    '& legend': {
      display: 'none',
    },

    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 6px) scale(0.75)',
      color: palette.common.grey,

      '& + .MuiInputBase-root': {
        '& > input': {
          transform: 'translateY(5px)',
        },
      },
    },

    '& .MuiFormLabel-root': {
      color: palette.common.grey,
      fontSize: 12,
      fontWeight: 400,
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main,
    },
  },
};

export default MuiTextField;
