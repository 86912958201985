import React from 'react';
import Flex from '../components/global/Flex';
import Footer from './Footer';
import Header from './Header';
import useStyles from './styles';
import {useSelector} from 'react-redux'
import classNames from 'classnames'
const Layout = ({ children }) => {
  const classes = useStyles();
  const isAuth = useSelector(state=>state.user.isAuthorized)

  return (
    <Flex flex column className={classes.layout}>
      <div className={classNames(classes.main,!isAuth&&classes.mainNotAuth)}>
        {isAuth && <Header />}
        {children}
      </div>
      <Footer />
    </Flex>
  );
};

export default Layout;
