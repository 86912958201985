import { makeStyles } from '@material-ui/styles';
import pallete from '../../../theme/palette';

const useStyles = makeStyles((theme) => ({
  scrollbars: {
    minHeight: 360,
    position: 'relative',
  },
  uploadMoreLink: {
    width: '100%',
    fontSize: 12,
    textDecoration: 'underline',
    textAlign: 'center',
    padding: 15,
    cursor: 'pointer',
    '&:hover': {
      color: pallete.secondary.main,
    },
  },
  titleBox: {
    maxWidth: "240px !important",
    overflow: 'hidden',
    flexWrap: 'wrap',
    textOverflow: 'clip',
  },
  chatHeader: {
    background: '#58727F',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    color: 'white',
    width: '100%',
    height: 50,
    borderRadius: '10px 10px 0px 0px',
    padding: '7px 23.5px',
    '@media(max-width:435px)': {
      height: 70,
    },
  },
  chatTitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left',
    flex: 3,
  },
  chatDate: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '13px',
    letterSpacing: '0em',
    textAlign: 'center',
    margin: 20,
  },
  chatContainer: {
    width: 380,
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 300px)': {
      width: '90vw',
      minWidth: 310,
    },
    '@media (min-width: 375px)': {
      width: '80vw',
      minWidth: 325,
    },
    '@media (min-width: 768px)': {
      width: '100%',
    },
    '@media (min-width: 1000px)': {
      width: '340px',
      marginTop: 60,
    },
    '@media (min-width: 1440px)': {
      width: '380px',
    },
  },
  chatScroll: {
    height: 560,
    background: '#F4F5F9',
  },
  chatFooter: {
    background: '#ECEEF3',
    borderRadius: '0px 0px 10px 10px',
    width: 380,
    height: 80,
    '@media (min-width: 300px)': {
      width: '100%',
      minWidth: 310,
    },
    '@media (min-width: 375px)': {
      width: '100%',
      minWidth: 325,
    },
    '@media (min-width: 768px)': {
      width: '100%',
    },
    padding: '22px 20px 18px 20px',
    '@media (min-width: 1000px)': {
      width: '340px',
    },
    '@media (min-width: 1440px)': {
      width: '380px',
    },
  },
  chatMessage: {
    background: '#ECEEF3',
    borderRadius: '5px',
    marginBottom: 10,
    padding: 10,
    marginLeft: 20,
    width: 260,
    '@media (min-width: 768px)': {
      width: '280px',
    },
    '@media (min-width: 1000px)': {
      width: '240px',
    },
    '@media (min-width: 1440px)': {
      width: '260px',
    },
  },
  chatSubmitButton: {
    borderRadius: 5,
    padding: 0,
    marginLeft: 10,
    maxWidth: 40,
    maxHeight: 40,
    minWidth: 40,
    '& svg': {
      fontSize: '30px !important',
    },
  },
  messageInput: {
    flex: 1,
    outlineWidth: 1,
    outlineColor: 'red',
    '&:focus': {
      border: '1px solid black',
    },
    // border:"1px solid blue"
  },
  messageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  messageTitle: {
    marginBottom: 10,
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    textAlign: 'left',
  },
  messageTextContainer: {
    marginRight: 24,
  },
  messageText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '18px',
    textAlign: 'left',
  },
  chatMessageActive: {
    background: '#2395D7',
    color: '#FBFBFD !important',
    marginLeft: 40,
  },
  chatMessageTitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 18,
    textAlign: 'left',
  },
  trackVertical: {
    height: '100%',
    top: 0,
    right: 0,
    display: 'none',

    '@media (min-width: 300px)': {
      display: 'block',
    },
  },
  thumbVertical: {
    borderRadius: 4,
    background: theme.palette.common.grey,
    display: 'none',

    '@media (min-width: 300px)': {
      display: 'block',
    },
  },
}));

export default useStyles;
