import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  pdfDocument: {
    display: 'block',
    maxHeight:'60vh',
    overflowY:'auto',
    
  },
  page: {
    overflowX: 'auto',
    overflowY:'hidden'
  },
}));

export default useStyles;