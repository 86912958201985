import palette from '../palette';

const MuiTypography = {
  h1: {
    marginTop: '10px',
    color: palette.primary.main,
    fontSize: '48px',
    fontWeight: '200',
    lineHeight: '58px',

    '@media (max-width: 768px)': {
      fontSize: '36px',
      lineHeight: '40px',
    },
  },
  h2: {
    color: palette.common.darkGrey,
    fontSize: '24px',
    fontWeight: '200',
    lineHeight: '29px',

    '@media (max-width: 768px)': {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
  h3: {
    color: palette.primary.main,
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
  },
  h4: {
    color: palette.primary.main,
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '23px',
  },
  subtitle1: {
    margin: '30px 0 20px',
    color: palette.primary.main,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
  },
};

export default MuiTypography;
