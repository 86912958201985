import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import { connectModal } from 'redux-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import useIntl from "react-intl/lib/src/components/useIntl";
import Flex from "../../global/Flex";
import { ADD_NEW_PASSWORD } from '../../../constants/modalNames';
import useStyles from './styles';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import Button from "@material-ui/core/Button";
import FormInput from "../../global/FormInput";
import { ReactComponent as KeyIcon } from '../../../../assets/icons/keyIcon.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as VisibilityOn } from '../../../../assets/icons/visibilityOn.svg';
import { ReactComponent as VisibilityOff } from '../../../../assets/icons/visibilityOff.svg';
import notify from '../../../helpers/notify';
import actions from '../../../../store/actions'

const {resetPasswordAsync} =actions

const AddNewPasswordModal = ({
    show,
  }) => {
  
  const theme = useSelector((state) => state.theme.value);
  const resetToken=useSelector((state)=>state.user.resetToken)
  const isLoading=useSelector((state)=>state.user.loading)
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, (formatMessage({id: "inputErrors_required_password"})))
      .required(formatMessage({ id: 'inputErrors_required' })),
  });
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  /*Password*/
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setVisiblePassword(visiblePassword => !visiblePassword)
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = ({password}) => {
    dispatch(resetPasswordAsync({resetToken,password}))
  };
  return (
    <div>
      <Dialog
        open={show}
        onClose={()=>notify(formatMessage({ id: "form_add_new_password_title" }))}
        maxWidth="xl"
      >
        <DialogContent className={classes.body}>
          {isLoading ? (
            <CircularProgress color='primary' />
          ) :<><div className={classes.modalImage}>
            <KeyIcon />
          </div>
          <Typography className={classes.title}  variant="h4" align="center">
            {formatMessage({ id: "form_add_new_password_title" })}
          </Typography>
          <div className={classes.description}>
            {formatMessage({ id: "add_new_password_form_description" })}
          </div>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Flex spacing={20} container marginBottom column>
              <Flex xs="100%">
                <FormInput
                  type={visiblePassword ? 'text' : 'password'}
                  error={errors.password}
                  errors={errors}
                  name="password"
                  placeholder={formatMessage({ id: "form_add_new_password_password" })}
                  control={control}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {visiblePassword ? <VisibilityOn className={classes.visibility} /> : <VisibilityOff className={classes.visibility}/>}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
              </Flex>
              <Flex container>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '50px',
                  }}
                >
                
                  {formatMessage({ id: "add_new_password_form_button" })}
                </Button>
              </Flex>
            </Flex>
          </form></>}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: ADD_NEW_PASSWORD,
  getModalState: (state) => state.modals,
})(AddNewPasswordModal);
