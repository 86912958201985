// Types
import types from '../actionTypes';
// Services
import ApiService from '../../services/ApiService';

export const setActiveTab = (data) => ({
  type: types.SET_ACTIVE_TAB,
  payload: data,
});
export const setRequestTab = (data) => ({
  type: types.SET_REQUEST_TAB,
  payload: data,
});
// API actions
const fetchBroadcastsStart = () => ({
  type: types.FETCH_GET_BROADCAST_BY_HALL_START,
});
const fetchBroadcastsSuccess = (data) => ({
  type: types.FETCH_GET_BROADCAST_BY_HALL_SUCCESS,
  payload: data,
});
const fetchBroadcastsError = ({ message }) => ({
  type: types.FETCH_GET_BROADCAST_BY_HALL_ERROR,
  payload: message,
});
const fetchBroadcastsClearError = () => ({
  type: types.FETCH_GET_BROADCAST_BY_HALL_CLEAR_ERROR,
});
export const fetchProgramListByHallIdAsync = (dispatch, hallId, broadcastId) => {
  dispatch(fetchBroadcastsStart());

  ApiService.apiCall({
    url: ApiService.paths.broadcasts.GET_BY_HALL,
    token: true,
    params: { hallId },
  })
    .then((response) => {
      const activeBroadcatndex = response.data.map((item) => +item.id).indexOf(+broadcastId)

      let currentHallBroadcast = response.data[activeBroadcatndex];
      const {
        offlineUrlEN,
        offlineUrlUA,
        onlineUrlEN,
        onlineUrlUA,
        onlineIdEN,
        onlineIdUA,
        offlineIdEN,
        offlineIdUA,
        offlineViewCountEN,
        offlineViewCountUA,
        onlineConcurrentViewersEN,
        onlineConcurrentViewersUA,
        sessions,
      } = currentHallBroadcast;
      const programs = [];
      sessions.forEach(session=>session.programs.length&&session.programs.forEach(prog=>programs.push(prog)))
      programs.sort((a,b)=>a?.startedAt-b?.startedAt)
      dispatch(
        fetchBroadcastsSuccess({
          list: programs,
          onlineUrlEN,
          onlineUrlUA,
          offlineUrlEN,
          offlineUrlUA,
          onlineIdEN,
          onlineIdUA,
          offlineIdEN,
          offlineIdUA,
          offlineViewCountEN,
          offlineViewCountUA,
          onlineConcurrentViewersEN,
          onlineConcurrentViewersUA,
        }),
      );
    })
    .catch((error) => {
      dispatch(fetchBroadcastsError(error));
      setTimeout(() => {
        fetchBroadcastsClearError();
      }, 300);
    });
};
