import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '1020px',
    padding: '60px !important',
    backgroundColor: palette.common.white,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    '@media (max-width: 1440px)': {
      width: '620px',
      padding: '60px 40px 40px 40px!important',
    },
    '@media (max-width: 1000px)': {
      width: '520px',
      padding: '50px 30px 40px 30px!important',
    },
    '@media (max-width: 768px)': {
      width: '90vw',
      padding: '50px 5% 40px !important',
    },
  },
  dialog: {
    '&>div> .MuiDialog-paper': {
      margin: '0 !important',
    },
  },
  closer: {
    display: 'flex',
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '0 !important',
    color: '#58727F',
    cursor: 'pointer',

    '@media (max-width: 1000px)': {
      top: '10px',
      right: '10px',
    },
  },
  closerText: {
    marginRight: '10px',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    lineHeight: '29px',
  },
  programModalWrapper: {
    width: '100%',
  },
  programModalInfo: {
    marginTop: '20px',
    color: palette.primary.main,
    '& span': {
      fontWeight: '600',
    },
  },
  programModalInner: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',

    '@media (max-width: 1440px)': {
      flexDirection: 'column',
    },
  },

  videoControl: {
    width: '540px',

    '@media (max-width: 1440px)': {
      width: '100%',
    },
  },
  programListWrapper: {
    width: '330px',

    '@media (max-width: 1440px)': {
      width: '100%',
    },
  },
  videoControlFooter: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    color: palette.primary.main,
    '& svg': {
      marginRight: '5px',
    },

    '@media (max-width: 1440px)': {
      marginBottom: '15px',
    },
  },
  programHeader: {
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    padding: '0 20px',
    background: palette.common.cream,
    borderTop: `5px solid ${palette.secondary.main}`,
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
  },
  programTitleText: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    color: palette.primary.main,
    fontSize: '16px',
    fontWeight: '600',
  },
  scrollbars: {
    position: 'relative',
    maxHeight: '310px',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '120px',
      color: 'transparent',
      background:
        'linear-gradient(0deg, #FBFBFD 0%, rgba(251, 251, 253, 0) 100%)',
      zIndex: 1,
    },
  },
  trackVertical: {
    display: 'none',
    top: 6,
    right: 0,
    height: '100%',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
  thumbVertical: {
    display: 'none',
    background: palette.common.grey,
    borderRadius: 4,

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
  programListContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '310px',
    padding: '15px 20px 0',
    background: palette.common.sky,
  },
  programItemTime: {
    color: '#58727F',
    fontSize: '12px',
    fontWeight: '300',
  },
  programItemText: {
    margin: '5px 0 20px',
    color: palette.primary.main,
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '18px',
  },
}));

export default useStyles;
