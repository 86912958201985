const MuiCheckbox = {
  root: {
    width: '16px',
    height: '16px',
    marginRight: '10px',
    padding: '0',
  },
};

export default MuiCheckbox;
