import React,{useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { ReactComponent as ArrowRightCircleIcon } from '../../../../../assets/icons/arrowRightCircleIcon.svg';
import { show } from 'redux-modal';
import { PROGRAM_MODAL } from '../../../../constants/modalNames';
import DateFormat from '../../../../../services/DateFormats';
import classNames from 'classnames';

const getTimeFormat = (num, addedMinutes = 0) => {
  let transformedNum =
    (num + addedMinutes) % 100 >= 60
      ? num + addedMinutes + 40
      : num + addedMinutes;
  return (
    String(transformedNum).slice(0, -2) + ':' + String(transformedNum).slice(-2)
  );
};
const ProgramItem = ({ index, item, first, hallId }) => {
  const classes = useStyles();
  const language = useSelector((state) => state.user.user.language);
  const title = useCallback(()=>language==="UA"?'titleUA':"titleEN",[language])
  const dispatch = useDispatch();
  let strTime, strEndTime;
  if (item.program) {
    strTime = DateFormat.parseDateToTime(item.program.startedAt * 1000);
    strEndTime = DateFormat.parseDateToTime(item.program.endAt * 1000);
  } else if (item.break) {
    strTime = getTimeFormat(item.break.startedAt);
    strEndTime = getTimeFormat(item.break.endAt);
  } else {
    strTime = '';
    strEndTime = '';
  }
  
  return (
    <div
      className={classNames(
        item.program && classes.block,
        !item.program && classes.blockEmpty,
      )}
      key={index}
      onClick={() =>
        item.program && dispatch(show(PROGRAM_MODAL, { item, hallId }))
      }
    >
      <div className={classes.blockInfo}>
        <div className={classes.time}>{`${strTime}${
          strEndTime && '-' + strEndTime
        }`}</div>
        <div className={classes.title}>
          {item?.program?.[title()]}
          {item?.break && 'Перерва'}
        </div>
      </div>
      {item.program && <ArrowRightCircleIcon className={classes.arrowRight} />}
    </div>
  );
};

export default ProgramItem;
