import palette from '../palette';

const MuiSvgIcon = {
  root: {
    '&.clickable': {
      cursor: 'pointer',
    },
    '&.disabled': {
      color: palette.common.grey,
      cursor: 'not-allowed',
    },
  },
};

export default MuiSvgIcon;
