import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 35,
    paddingBottom: 35,
  },
  symposiumsTable: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: '30px',
    marginRight: -20,
    '@media (max-width: 768px)': {
      marginRight: 0,
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '580px',
    height: '160px',
    marginBottom: '20px',
    marginRight: 20,
    padding: '10px 35px 10px 30px',
    color: '#58727F',
    fontSize: '12px',
    lineHeight: '16px',
    background: palette.common.cream,
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `3px 2px 10px 0px ${palette.secondary.main}, 3px -2px 10px 0px ${palette.secondary.main},-3px -2px 10px 0px ${palette.secondary.main},-3px 2px 10px 0px ${palette.secondary.main},inset 0px 0px 15px -4px ${palette.secondary.main}`,
      // textDecoration:"underline",
      '&>svg': {
        color: 'white',
        background: palette.secondary.main,
        borderRadius: '100%',
      },
    },
    '@media (max-width: 1440px)': {
      width: '460px',
    },
    '@media (max-width: 1000px)': {
      width: '340px',
      padding: '10px 35px 25px 20px',
    },
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      width: '100%',
      marginRight: 0,
    },
  },
  partner: {
    fontWeight: '300',

    '@media (max-width: 1000px)': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '-25px',
    },
  },
  blockInner: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '&:first-child': {
      maxWidth: '180px',
      marginRight: '40px',
    },

    '@media (max-width: 1440px)': {
      '&:first-child': {
        maxWidth: '150px',
        marginRight: '30px',
      },
    },
    '@media (max-width: 1000px)': {
      '&:first-child': {
        marginRight: '0',
      },
      '&:last-child': {
        marginTop: '10px !important',
      },
    },
  },

  image: {
    display: 'flex',
    '@media (max-width: 1000px)': {
      marginTop: -30,
    },
  },
  title: {
    alignItems: 'flex-end',
    marginTop: '10px',
    color: palette.primary.main,
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    width:'100%',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',

    '@media (max-width: 1000px)': {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  titleColumn: {
    flex:2,
    alignItems: 'flex-start',
    height: '100%',
    maxWidth: '100%',
    '@media (min-width:1000px)': {
      overflow: 'hidden',
      marginTop: 44,
    },
  },

  titleText: {
    fontSize: 10,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    fontWeight: '300',

    '@media (max-width: 1000px)': {
      '-webkit-line-clamp': 2,
    },
  },
  arrowRight: {
    position: 'absolute',
    right: '10px',
    bottom: '10px',
    color: palette.secondary.main,
  },
}));

export default useStyles;
