
import ApiService from "../../services/ApiService";
import { ContentListTypes } from "../actionTypes/contentTypes";

const fetchContentsError=(data)=>({
  type:ContentListTypes.FETCH_GET_CONTENT_LIST_ERROR,
  payload:data
})
const fetchContentsClearError=()=>({
  type:ContentListTypes.FETCH_GET_CONTENT_LIST_CLEAR_ERROR
})
const fetchContentsGetSuccess = (data) => ({
  type: ContentListTypes.FETCH_GET_CONTENT_LIST_SUCCESS,
  fields: data,
});

const fetchContentsGetStart = () => ({
  type: ContentListTypes.FETCH_GET_CONTENT_LIST_START,
});


export const fetchContentsListAsync = (
  dispatch,
) => {

  dispatch(fetchContentsGetStart());

  ApiService.apiCall({
    url: ApiService.paths.contents.GET_ALL,
    // token: true,
  })
    .then((response) => {
      if(response?.field) {
        dispatch(fetchContentsGetSuccess(response?.field))
      }
    })
    .catch((error) => {
      dispatch(fetchContentsError(error));
      setTimeout(() => {
        fetchContentsClearError();
      }, 300);
    });
};

