import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { connectModal, hide } from 'redux-modal';
import { PROGRAM_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';
import useIntl from 'react-intl/lib/src/components/useIntl';
import VideoPlayer from '../../global/VideoPlayer';
import { ReactComponent as VisibilityOn } from '../../../../assets/icons/visibilityOn.svg';
import { LOCALES } from '../../../../lang/locales';
import ProgramList from '../../global/ProgramList';
import actions from '../../../../store/actions';
import Loading from '../../global/Loading';

const { fetchProgramListByHallIdAsync } = actions;

const ProgramModal = ({ show, ...props }) => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.language);
  const isLoading = useSelector((state) => state.broadcasts.loading);
  const program = props?.item?.program;
  const hallId = props?.hallId;
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const localize = (locale) => {
    return {
      [LOCALES.UKRAINIAN]: {
        title: 'titleUA',
        authors: 'authorsUA',
        moderators: 'moderatorUA',
        executiveGroup: 'executiveGroupUA',
        offlineUrl: 'offlineUrlUA',
        videoCount: 'videoViewCountUA',
      },
      [LOCALES.ENGLISH]: {
        title: 'titleEN',
        authors: 'authorsEN',
        moderators: 'moderatorEN',
        executiveGroup: 'executiveGroupEN',
        offlineUrl: 'offlineUrlEN',
        videoCount: 'videoViewCountEN',
      },
    }[locale];
  };
  const {
    title,
    moderators,
    executiveGroup,
    offlineUrl,
    videoCount,
  } = localize(locale);
  useEffect(() => {
    if (hallId) {
      fetchProgramListByHallIdAsync(dispatch, hallId);
    }
  }, [dispatch, hallId]);
  const programList = program?.programs && [
    ...Object.entries(program.programs).map((program) => program[1]),
  ];
  if (!program) return <Loading />;
  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(PROGRAM_MODAL))}
        className={classes.dialog}
        maxWidth='xl'
      >
        <DialogContent className={classes.body}>
          <div
            className={classes.closer}
            onClick={() => dispatch(hide(PROGRAM_MODAL))}
          >
            <span className={classes.closerText}>
              {formatMessage({ id: 'close_modal_button' })}
            </span>
            <CloseCircleIcon />
          </div>
          <div className={classes.programModalWrapper}>
            <Typography className={classes.title} variant='h3'>
              {program[title]}
            </Typography>
            <div className={classes.programModalInfo}>
              <div>
                <span>{formatMessage({ id: 'program_modal_moderator' })}:</span>{' '}
                {program[moderators]}
              </div>
              <div>
                <span>{formatMessage({ id: 'program_modal_pres' })}:</span>{' '}
                {program[executiveGroup]}
              </div>
            </div>
            <div className={classes.programModalInner}>
              <div className={classes.videoControl}>
                <div className={classes.video}>
                  <VideoPlayer
                    url={program[offlineUrl]}
                    width={'100%'}
                    controls
                    style={{ flex: 1, borderRadius: 10, overflow: 'hidden' }}
                  />
                </div>
                <div className={classes.videoControlFooter}>
                  <VisibilityOn />
                  {program[videoCount]}
                </div>
              </div>
              <ProgramList modal list={programList} loading={isLoading} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: PROGRAM_MODAL,
  props: {},
  getModalState: (state) => state.modals,
})(ProgramModal);
