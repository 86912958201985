// Types
import types from '../actionTypes';
// Services
import ApiService from '../../services/ApiService';

// API actions
const fetchEpostersStart = () => ({
  type: types.FETCH_GET_ALL_EPOSTERS_START,
});
const fetchEpostersSuccess = (data) => ({
  type: types.FETCH_GET_ALL_EPOSTERS_SUCCESS,
  payload: data,
});
const fetchEpostersError = ({ message }) => ({
  type: types.FETCH_GET_ALL_EPOSTERS_ERROR,
  payload: message,
});
const fetchEpostersClearError = () => ({
  type: types.FETCH_GET_ALL_EPOSTERS_CLEAR_ERROR,
});
export const fetchAllEposterListAsync = () => (dispatch) => {
  dispatch(fetchEpostersStart());

  ApiService.apiCall({
    url: ApiService.paths.eposters.GET_ALL,
    token: true,
  })
    .then((response) => {
      dispatch(fetchEpostersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchEpostersError(error));
      setTimeout(() => {
        fetchEpostersClearError();
      }, 300);
    });
};
