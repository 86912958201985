import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import useStyles from './styles';
import PDFControlPanel from '../PDFControlPanel';
import { useMediaQuery } from '@material-ui/core';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFReader = ({ src }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('@media (max-width: 768px');
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    if (isSmallScreen) {
      setScale(0.5);
    } else {
      setScale(1.0);
    }
  }, [isSmallScreen]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        <Document
          className={classes.pdfDocument}
          file={src}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            className={classes.page}
            pageNumber={pageNumber}
            scale={scale}
          />
        </Document>
      </div>
      <PDFControlPanel
        scale={scale}
        setScale={setScale}
        numPages={numPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </>
  );
};

export default PDFReader;
