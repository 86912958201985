import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { connectModal, hide } from 'redux-modal';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { CONFIRM_SUCCESS } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/checkСircleIcon.svg';
const ConfirmSuccessModal = ({ show, type }) => {
  const theme = useSelector((state) => state.theme.value);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();
  const messages = {
    regSuccess: {
      title: 'confirm_reg_success_form_title',
      desc: 'confirm_success_reg_description',
    },
    restoreSuccess: {
      title: 'confirm_success_restore_form_title',
      desc: 'confirm_success_restore_description',
    },
    resetSuccess: {
      title: 'confirm_success_form_title',
      desc: 'confirm_success_form_description',
    },
    activateSuccess: {
      title: 'confirm_success_activate_form_title',
      desc: 'confirm_success_activate_description',
    },
  };
  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(CONFIRM_SUCCESS))}
        maxWidth='xl'
      >
        <DialogContent className={classes.body}>
          <div className={classes.modalImage}>
            <CheckCircleIcon />
          </div>
          <Typography className={classes.title} variant='h4' align='center'>
            {formatMessage({ id: `${messages[type || 'regSuccess']?.title}` })}
          </Typography>
          <div className={classes.description}>
            {formatMessage({ id: `${messages[type || 'regSuccess']?.desc}` })}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: CONFIRM_SUCCESS,
  getModalState: (state) => state.modals,
})(ConfirmSuccessModal);
