import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import WebinarModal from '../../components/modals/WebinarModal';
import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import actions from '../../../store/actions';
import Loading from '../../components/global/Loading';

const { fetchMaterialsListAsync } = actions;

const Materials = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.value);
  const materialsList = useSelector((state) => state.materials.materials);
  const loading = useSelector((state) => state.materials.loading);
  const language = useSelector((state) => state.locale.language);
  const classes = useStyles({ theme });

  const { formatMessage } = useIntl();
  useEffect(() => {
    dispatch(fetchMaterialsListAsync)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  if (loading) return <Loading />;
  return (
    <div>
      <WebinarModal />
      <section className={classes.section}>
        <Container style={{ flexDirection: 'column' }}>
          <Typography variant='h3'>
            {formatMessage({ id: 'header_menu_materials' })}
          </Typography>

          <div className={classes.exhibitionTable}>
            {materialsList?.length > 0 && materialsList?.map((item, index) => (
              <div className={classes.block} key={index}>
                <div className={classes.titleRow}>
                  <FileIcon className={classes.fileIcon}/>
                  <div className={classes.title}>
                    {language === "uk-UA" ? item.nameUA : item.nameEN}
                  </div>
                </div>
                <a 
                  href={language === "uk-UA" ? item.fileUrlUA : item.fileUrlEN}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.downloadRow}
                >
                  <div className={classes.downloadTitle}>
                    {formatMessage({ id: 'download_btn_title' })}
                  </div>
                  <DownloadIcon />
                </a>
               
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Materials;
