import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: '35px',
    paddingBottom: '35px',
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorTiTle: {
    color: palette.secondary.main,
    fontSize: '144px',
    fontWeight: 'bold',
    lineHeight: '188px',

    '@media (max-width: 768px)': {
      fontSize: '100px',
      lineHeight: '130px',
    },
  },
  errorSubTiTle: {
    textAlign: 'center',
    maxWidth: '240px',
    fontSize: '22px',
    lineHeight: '30px',

    '@media (max-width: 768px)': {
      maxWidth: '190px',
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  errorButtons: {
    marginTop: '40px',
  },
}));

export default useStyles;
