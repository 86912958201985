import types from '../actionTypes';
// Constants
import {
  CHAT_MESSAGE_LIMIT,
  CHAT_START_PAGE,
} from '../../common/constants/general';
const {
  FETCH_MESSAGES_BY_HALL_START,
  FETCH_MESSAGES_BY_HALL_SUCCESS,
  FETCH_MESSAGES_BY_HALL_MORE_SUCCESS,
  FETCH_MESSAGES_BY_HALL_ERROR,
  FETCH_MESSAGES_BY_HALL_CLEAR_ERROR,
  ADD_MESSAGE_FROM_SOCKET,
  FETCH_SEND_MESSAGE_START,
  FETCH_SEND_MESSAGE_SUCCESS,
  FETCH_SEND_MESSAGE_CLEAR_ERROR,
  FETCH_SEND_MESSAGE_ERROR,
  SET_MESSAGES_LIST,
} = types;

const INIT_STATE = {
  list: [],
  total: 0,
  params: {
    page: CHAT_START_PAGE,
    limit: CHAT_MESSAGE_LIMIT,
  },
  chatConnected: false,
  loading: false,
  sending: false,
  error: '',
};
const chatReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SEND_MESSAGE_START:
      return { ...state, sending: true };
    case FETCH_SEND_MESSAGE_SUCCESS:
      return { ...state, sending: false, error: '' };
    case FETCH_SEND_MESSAGE_ERROR:
      return { ...state, sending: false, error: action.payload };
    case FETCH_SEND_MESSAGE_CLEAR_ERROR:
      return { ...state, error: '' };
    case SET_MESSAGES_LIST:
      return { ...state, list: action.payload };
    case ADD_MESSAGE_FROM_SOCKET:
      return {
        ...state,
        list:
          state.list.length > 0
            ? [...state.list, action.payload]
            : [action.payload],
      };
    case FETCH_MESSAGES_BY_HALL_START:
      return { ...state, loading: true, total: 0 };
    case FETCH_MESSAGES_BY_HALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        list: action.payload.list,
        total: action.payload.total,
      };
    case FETCH_MESSAGES_BY_HALL_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        list: [...state.list, ...action.payload.list],
        total: action.payload.total,
      };
    case FETCH_MESSAGES_BY_HALL_ERROR:
      return { ...state, loading: false, error: action.payload, total: 0 };
    case FETCH_MESSAGES_BY_HALL_CLEAR_ERROR:
      return { ...state, loading: false, error: '' };

    default:
      return state;
  }
};

export default chatReducer;
