import { makeStyles } from '@material-ui/styles';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  dialog: {
    '@media (max-width: 480px)': {
      '&>div> .MuiDialog-paper': {
        margin: '0 !important',
      },
    },
  },
  link:{
    "&:hover":{
      textDecoration:"none"
    }
  },
  body: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    width: '700px',
    padding: '60px !important',
    backgroundColor: palette.common.white,
    borderRadius: '10px',
    boxShadow: '0px 15px 22px -9px rgba(0, 0, 0, 0.25)',

    "@media (max-width: 768px)": {
      width: '340px',
      padding: '50px 20px 40px !important',
    },
  },
  closer: {
    display: 'flex',
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '0 !important',
    color: '#58727F',
    cursor: 'pointer',

    "@media (max-width: 1000px)": {
      top: '10px',
      right: '10px',
    },
  },
  closerText: {
    marginRight: '10px',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    lineHeight: '29px'
  },
  programListWrapper: {
    width: '100%',
  },
  scrollbars: {
    position: 'relative',
  },
  trackVertical: {
    height: '100%',
    top: 0,
    right: 0,
    display: 'none',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
  thumbVertical: {
    borderRadius: 4,
    background: palette.common.grey,
    display: 'none',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
  programListContainer: {
    height: '385px',
    paddingRight: '25px',

    '@media (max-width: 768px)': {
      paddingRight: '10px',
    },
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '50px',
    marginBottom: '10px',
    padding: '0 10px 0 20px',
    color: palette.primary.main,
    background: palette.common.cream,
    borderRadius: '5px',
    cursor: 'pointer',

    '&:hover': {
      color: palette.common.white,
      background: palette.secondary.main,
    },
    '&:hover *': {
      color: palette.common.white,
    },
  },
  fileTextIcon: {
    color: palette.secondary.main,
  },
}));

export default useStyles;















