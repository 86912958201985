import React, { useState } from 'react';
import Drawer from "@material-ui/core/Drawer";
import { hide, connectModal } from 'redux-modal';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import useIntl from "react-intl/lib/src/components/useIntl";
import {MOBILE_MENU} from "../constants/modalNames";
import useStyles from "./styles";
import Flex from "../components/global/Flex";
import routes from "../routes";
import { ReactComponent as DownIcon } from '../../assets/icons/downIcon.svg';
import { ReactComponent as CloseCircleIcon } from '../../assets/icons/closeCircleIcon.svg';

const MobileMenu = ({ show }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const theme = useSelector(state => state.theme.value);
  const [scientificProgramEl, setScientificProgramEl] = useState(false);
  const [partnerVideosEl, setPartnerVideosEl] = useState(null);
  const classes = useStyles({
    isMenuFirstOpen: Boolean(scientificProgramEl),
    isMenuSecondOpen: Boolean(partnerVideosEl),
    theme
  });

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="left"
      open={show}
      onClose={() => dispatch(hide(MOBILE_MENU))}
    >
      <div>
        <div className={classes.mobileMenuClose}>
          <CloseCircleIcon onClick={() => dispatch(hide(MOBILE_MENU))} />
        </div>
        <div className={classes.mobileMenu}>
          <Flex>
            <NavLink
              className={classes.menuItem}
              to={routes.home}
              onClick={() => dispatch(hide(MOBILE_MENU))}
            >
              {formatMessage({ id: 'header_menu_home' })}
            </NavLink>
          </Flex>
          <Flex>
            <NavLink
              className={classes.menuItem}
              to={routes.translation}
              onClick={() => dispatch(hide(MOBILE_MENU))}
            >
              {formatMessage({ id: 'header_menu_translation' })}
            </NavLink>
          </Flex>
          <Flex>
            <span
              onClick={() => setScientificProgramEl(!scientificProgramEl)}
              className={`${classes.menuItem} ${scientificProgramEl && classes.menuItemMobileActive}`}
            >
              {formatMessage({ id: 'header_menu_scientific_program' })}
              <DownIcon
                className={`${classes.chevron} ${classes.chevronFirst}`}
              />
            </span>
            {scientificProgramEl &&
            <>
              <NavLink
                className={classes.menuItem}
                style={{
                  marginLeft: '30px',
                }}
                to={routes.program}
                onClick={() => dispatch(hide(MOBILE_MENU))}
              >
                {formatMessage({ id: 'header_menu_program' })}
              </NavLink>
              <NavLink
                className={classes.menuItem}
                style={{
                  marginLeft: '30px',
                }}
                to={routes.electronicPosters}
                onClick={() => dispatch(hide(MOBILE_MENU))}
              >
                {formatMessage({ id: 'header_menu_electronic_posters' })}
              </NavLink>
              <NavLink
                className={classes.menuItem}
                style={{
                  marginLeft: '30px',
                }}
                to={routes.materials}
                onClick={() => dispatch(hide(MOBILE_MENU))}
              >
                {formatMessage({ id: 'header_menu_materials' })}
              </NavLink>
            </>
            }
          </Flex>
          <Flex>
            <NavLink
              className={classes.menuItem}
              to={routes.exhibition}
              onClick={() => dispatch(hide(MOBILE_MENU))}
            >
              {formatMessage({ id: 'header_menu_exhibition' })}
            </NavLink>
          </Flex>
          <Flex>
            <span
              onClick={() => setPartnerVideosEl(!partnerVideosEl)}
              className={`${classes.menuItem} ${partnerVideosEl && classes.menuItemMobileActive}`}
            >
              {formatMessage({ id: 'header_menu_partner_videos' })}
              <DownIcon
                className={`${classes.chevron} ${classes.chevronSecond}`}
              />
            </span>
            {partnerVideosEl &&
            <>
              <NavLink
                className={classes.menuItem}
                style={{
                  marginLeft: '30px',
                }}
                to={routes.satelliteSymposiums}
                onClick={() => dispatch(hide(MOBILE_MENU))}
              >
                {formatMessage({ id: 'header_menu_satellite_symposiums' })}
              </NavLink>
              <NavLink
                className={classes.menuItem}
                style={{
                  marginLeft: '30px',
                }}
                to={routes.webinars}
                onClick={() => dispatch(hide(MOBILE_MENU))}
              >
                {formatMessage({ id: 'header_menu_webinars' })}
              </NavLink>
            </>
            }
          </Flex>
        </div>
      </div>
    </Drawer>
  )
};

export default connectModal({
  name: MOBILE_MENU,
  getModalState: (state) => state.modals,
})(MobileMenu)