export const getChatWS = (token, hallId) =>
  `${
    process.env.REACT_APP_API_WS ||
    'ws://filatov-readings.demo.malevich.com.ua/ws'
  }/messages/?token=${token}&broadcastId=${hallId}`;
export const getViewersWS = (token, videoId) =>
  `${
    process.env.REACT_APP_API_WS ||
    'ws://filatov-readings.demo.malevich.com.ua/ws'
  }/views/?token=${token}&videoId=${videoId}`;
