import types from '../actionTypes';

const {
  FETCH_GET_ALL_EPOSTERS_START,
  FETCH_GET_ALL_EPOSTERS_SUCCESS,
  FETCH_GET_ALL_EPOSTERS_ERROR,
  FETCH_GET_ALL_EPOSTERS_CLEAR_ERROR,
} = types;

const INIT_STATE = {
  list: [],
  loading: false,
  error: '',
};
const eposterListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GET_ALL_EPOSTERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case FETCH_GET_ALL_EPOSTERS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GET_ALL_EPOSTERS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case FETCH_GET_ALL_EPOSTERS_CLEAR_ERROR:
      return { ...state, error: '' };
    default:
      return state;
  }
};

export default eposterListReducer;
