import React from "react";
import useIntl from "react-intl/lib/src/components/useIntl";
import Flex from "../../../global/Flex";
import useStyles from './styles';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormInput from "../../../global/FormInput";

const SecondStep = ({onSubmit, setCurrentTab,formData}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const validationSchema = Yup.object().shape({
    country: Yup.string().required(formatMessage({ id: 'inputErrors_required' })),
    city: Yup.string().required(formatMessage({ id: 'inputErrors_required' })),
    placeOfWork: Yup.string().required(formatMessage({ id: 'inputErrors_required' })),
  });
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues:formData
  });
  return (
    <FormControl>
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Flex spacing={20} container marginBottom column>
        <Flex xs="100%">
          <FormInput
            error={errors.country}
            errors={errors}
            name="country"
            placeholder={formatMessage({ id: "form_country" })}
            control={control}
            fullWidth
          />
        </Flex>
        <Flex xs="100%">
          <FormInput
            error={errors.city}
            errors={errors}
            name="city"
            placeholder={formatMessage({ id: "form_city" })}
            control={control}
            fullWidth
          />
        </Flex>
        <Flex xs="100%">
          <FormInput
            error={errors.placeOfWork}
            errors={errors}
            name="placeOfWork"
            placeholder={formatMessage({ id: "form_work_place" })}
            control={control}
            fullWidth
          />
        </Flex>
        <Flex container>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              width: '100%',
              height: '50px',
            }}
          >
            {formatMessage({ id: "sign_up_form_button_continue" })}
          </Button>
        </Flex>
      </Flex>
      <Flex container>
        <Button
          variant="text"
          color="primary"
          onClick={() => {setCurrentTab(0)}}
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        >
          {formatMessage({ id: "form_button_back" })}
        </Button>
      </Flex>
    </form>
    </FormControl>
  );
};

export default SecondStep;
