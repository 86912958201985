import palette from '../palette';

const MuiMenuItem = {
  root: {
    minHeight: 'initial',
    paddingTop: '0',
    paddingRight: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    color: '#294F5F',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    cursor: 'pointer',
    '&:focus': {
      background: 'transparent',
    },

    '&:hover': {
      background: '#F4F5F9',
    },

    '&.Mui-selected': {
      background: palette.common.sky,

      '&:hover': {
        background: palette.common.sky,
      },
    },
  },
};

export default MuiMenuItem;
