import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
  },
}));

export default useStyles;
