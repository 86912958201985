import notify from '../common/helpers/notify';
import actions from '../store/actions';
import types from '../store/actionTypes';
const socketMiddleware = () => {
  let socket = null;
  // Informatory default events (FOR SOCKET TEST)
  const onOpen = (store) => (event) => {
    console.log('websocket open', event.target.url);
    store.dispatch(actions.wsConnected(event.target.url));
  };

  const onClose = (store) => () => {
    store.dispatch(actions.wsDisconnected());
  };

  const onMessage = (store) => (event) => {
    const payload = JSON.parse(event.data);
    console.log(`receiving server message ${payload}`);
  };
  const onError = (storre) => (event) => {
    console.log('Websocket got error' + JSON.parse(event.data));
  };
  // the middleware part of this function
  return (store) => (next) => (action) => {
    switch (action.type) {
      case types.WS_CONNECT:
        try{
          if (socket !== null) {
            socket.close();
          }
          // connect to the remote host
          socket = new WebSocket(action.host);
          // websocket handlers
          socket.onmessage = action?.payload?.onMessage || onMessage(store);
          socket.onclose = action?.payload?.onClose || onClose(store);
          socket.onopen = action?.payload?.onOpen || onOpen(store);
          socket.onerror = action?.payload?.onError || onError(store);
        }catch(e){
          notify('Socket connection error','error')
        }
       
        break;
      case types.WS_JOIN:
        if (socket !== null) {
          socket.send(JSON.stringify({ broadcastId: action.payload, type: 'JOIN' }));
        }
        break;
      case types.WS_SEND_MESSAGE:
        if (socket !== null) {
          socket.send(
            JSON.stringify({
              broadcastId: action.payload.broadcastId,
              message: action.payload.message,
              type: 'MESSAGE',
            }),
          );
        }
        break;
      case types.WS_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        console.log('websocket closed');
        break;
      case types.WS_NEW_MESSAGE:
        console.log('sending a message', action.msg);
        socket.send(
          JSON.stringify({ command: 'NEW_MESSAGE', message: action.msg }),
        );
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
