// Types
import types from '../actionTypes';

// General
export const wsConnect = (host,payload) => ({ type: types.WS_CONNECT,payload, host });
export const wsConnecting = (host) => ({ type: types.WS_CONNECTING, host });
export const wsConnected = (host) => ({ type: types.WS_CONNECTED, host });
export const wsDisconnect = (host) => ({ type: types.WS_DISCONNECT, host });
export const wsDisconnected = (host) => ({ type: types.WS_DISCONNECTED, host });
// Chat types
// export const wsJoinRoom = (hallId) => ({ type: types.WS_JOIN, payload: hallId });
// export const wsSend = (hallId, message) => ({  // disabled for now
//   type: types.WS_SEND_MESSAGE,
//   payload: { hallId, message },
// });