import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import {useIntl} from 'react-intl'

import useStyles from './styles';

const PrivacyPolicy = () => {
  const theme = useSelector((state) => state.theme.value);
  const classes = useStyles({ theme });
  const {formatMessage}=useIntl()
  return (
    <div>
      <section className={classes.section}>
        <Container>
          <Typography variant='h1' align='center'>
            {formatMessage({id:"copyright_title"})}
          </Typography>
        </Container>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
