import Container from '@material-ui/core/Container';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import participantBanner from '../../../assets/images/participant_banner.jpg';
import leftItem from '../../../assets/images/left_item.jpg';
import stand from '../../../assets/images/banner_stand.jpg';
import rightItem from '../../../assets/images/right_item.jpg';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import routes from '../../routes';
import { useHistory, useParams } from 'react-router';
import SatelliteSymposiumsModal from '../../components/modals/SatelliteSymposiumsModal';
import { show } from 'redux-modal';
import {
  BUSINESS_CARD_MODAL,
  FEEDBACK_MODAL,
  MATERIALS_MODAL,
  SATELLITE_SYMPOSIUMS_MODAL,
  STAND_MODAL,
  WEBINAR_MODAL,
} from '../../constants/modalNames';
import WebinarModal from '../../components/modals/WebinarModal';
import MaterialsModal from '../../components/modals/MaterialsModal';
import FeedbackModal from '../../components/modals/FeedbackModal';
import BusinessCardModal from '../../components/modals/BusinessCardModal';
import ElectronicPostersModal from '../../components/modals/ElectronicPostersModal';
import StandModal from '../../components/modals/StandModal';
import actions from '../../../store/actions';
import CustomDropdown from '../../components/global/EParticipantButtonDropdown';
import { Box, useMediaQuery } from '@material-ui/core';
import Loading from '../../components/global/Loading';

const { fetchPartnerByIDAsync } = actions;

const modelTransformer = (models) => {
  let result = {};
  if (models?.length > 0) {
    models.forEach((model) => {
      if (result[model.type]) {
        result[model.type].push(model);
      } else {
        result[model.type] = [model];
      }
    });
  }

  return result;
};
const modalListSelector = (type, partner, language) => {
  const name = language === 'UA' ? 'nameUA' : 'nameEN';
  const listName = language === 'UA' ? 'titleUA' : 'titleEN';
  const dict = {
    CARD: {
      modal: BUSINESS_CARD_MODAL,
      data: { url: partner[language === 'UA' ? 'cardUrlUA' : 'cardUrlEN'] },
      name,
    },
    MATERIAL: {
      modal: MATERIALS_MODAL,
      data: [partner.materials],
      name,
    },
    SYMPOSIUM: {
      modal: SATELLITE_SYMPOSIUMS_MODAL,
      list: partner.symposiums,
      listName,
      name,
    },
    WEBINAR: {
      modal: WEBINAR_MODAL,
      list: partner.webinars,
      listName: name,
      name,
    },
    FEEDBACK: {
      modal: FEEDBACK_MODAL,
      name,
    },
    DRAW: {
      url: partner.googleFormUrl,
      name,
    },
  };
  return dict[type];
};
const getImageUrl = (url) => {
  if (!url) return {};
  const isImage = new RegExp(/^.*\.(jp.?g|png|gif|svg)$/gm);
  if (isImage.test(url)) return { src: url, type: 'img', value: url };
  const getYouTubeImage = (url, splitOn) => {
    const id = url.split(splitOn).slice(-1);
    return `https://i.ytimg.com/vi/${id[0]}/hqdefault.jpg`;
  };
  let src;
  if (url.includes('watch?v=')) {
    src = getYouTubeImage(url, 'watch?v=');
  } else {
    src = getYouTubeImage(url, '/');
  }
  return { src, value: url, type: 'video' };
};

const ExhibitionParticipant = () => {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width: 1000px) and (max-width:1440px)');
  const match768to1440 = useMediaQuery(
    '(min-width: 768px) and (max-width:1440px)',
  );
  const params = useParams();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const language = useSelector((state) => state.user.user.language);
  const partner = useSelector((state) => state.partners.currentPartner);
  const loading = useSelector((state) => state.partners.loading);
  useEffect(() => {
    dispatch(fetchPartnerByIDAsync({ id: params.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const models = modelTransformer(partner?.models);
  const url = language === 'UA' ? 'urlUA' : 'urlEN';
  if (loading) return <Loading />;
  return (
    <>
      <BusinessCardModal />
      <MaterialsModal />
      <SatelliteSymposiumsModal />
      <WebinarModal />
      <FeedbackModal />
      <ElectronicPostersModal />

      <StandModal />

      <section className={classes.section}>
        <Container container='true' className={classes.container}>
          <div className={classes.programModalWrapper}>
            <div className={classes.leftSide}>
              <div className={classes.banner}>
                <img src={participantBanner} alt='' />

                <div className={classes.bannerTitle}>
                  {models?.HORIZONTAL_BAR && (
                    <img
                      src={models?.HORIZONTAL_BAR[0][url]}
                      alt={''}
                      width={'100%'}
                    />
                  )}
                </div>
                {/* TVs */}
                <div>
                  {[0, 1, 2].map((index) => {
                    let tvs = models?.TV || [];
                    const { src, type, value } = getImageUrl(
                      tvs[index] ? tvs[index][url] : '',
                    );
                    return (
                      <div
                        key={index}
                        className={classes.leftItem}
                        style={{ backgroundImage: `url(${leftItem})` }}
                        onClick={() => {
                          // setModalContent(data.leftItem1);
                          dispatch(show(STAND_MODAL, { type, value }));
                        }}
                      >
                        {!!tvs[index] && (
                          <img
                            className={classes.leftItemImage}
                            src={src}
                            alt={''}
                            width={'100%'}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* RECEPTION */}
                {[1].map((index) => {
                  const { src, type, value } = getImageUrl(
                    models?.RECEPTION ? models?.RECEPTION[0][url] : '',
                  );
                  return (
                    <div
                      key={index}
                      className={classes.bannerStand}
                      style={{ backgroundImage: `url(${stand})` }}
                      onClick={() => {
                        dispatch(show(STAND_MODAL, { type, value }));
                      }}
                    >
                      {models?.RECEPTION && (
                        <img src={src} alt={''} width={'100%'} />
                      )}
                    </div>
                  );
                })}
                {/* VERTICAL BARS */}
                <div className={classes.rightItemsContainer}>
                  {[0, 1]?.map((index) => {
                    let verticalBars = models?.VERTICAL_BAR || [];
                    const { src, type, value } = getImageUrl(
                      verticalBars[index] ? verticalBars[index][url] : '',
                    );
                    return (
                      <div
                        key={index}
                        className={classes.rightItem}
                        style={{ backgroundImage: `url(${rightItem})` }}
                        onClick={() => {
                          dispatch(show(STAND_MODAL, { type, value }));
                        }}
                      >
                        <div>
                          {!!verticalBars[index] && (
                            <img src={src} alt={''} width={'100%'} />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div></div>
              </div>
            </div>

            <div className={classes.rightSide}>
              <div className={classes.participantHeader}>
                <Box>
                  <div className={classes.participantTitle}>
                    {partner?.isSponsor
                      ? formatMessage({ id: 'exhibition_general_partner' })
                      : formatMessage({ id: 'exhibition_general_participant' })}
                  </div>

                  <div className={classes.participantLogo}>
                    {partner?.logoURL && (
                      <img src={partner.logoURL} height={80} alt='' />
                    )}
                  </div>
                  {matches && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => push(routes.exhibition)}
                      className={classes.participantButtonBack}
                    >
                      {formatMessage({ id: 'exhibition_button_back' })}
                    </Button>
                  )}
                </Box>
              </div>
              <Box
                style={
                  match768to1440
                    ? { display: 'flex', flexDirection: 'row', flex: 1 }
                    : {}
                }
              >
                {!match768to1440 &&
                  partner?.buttons &&
                  partner.buttons.map((button, index) => {
                    if (button.isDisplayed) {
                      const item = modalListSelector(
                        button.buttonType,
                        partner,
                        language,
                      );
                      return (
                        <CustomDropdown
                          url={item.url}
                          itemName={item.listName}
                          key={index}
                          data={item.data}
                          buttonName={button[item.name]}
                          list={item.list}
                          modalName={item.modal}
                        />
                      );
                    }
                    return null;
                  })}
                {match768to1440 && (
                  <>
                    <Box className={classes.buttonColumn}>
                      {partner?.buttons &&
                        partner.buttons.slice(0, 3).map((button, index) => {
                          if (button.isDisplayed) {
                            const item = modalListSelector(
                              button.buttonType,
                              partner,
                              language,
                            );
                            return (
                              <CustomDropdown
                                url={item.url}
                                itemName={item.listName}
                                key={index}
                                data={item.data}
                                buttonName={button[item.name]}
                                list={item.list}
                                modalName={item.modal}
                              />
                            );
                          }
                          return null;
                        })}
                    </Box>
                    <Box className={classes.buttonColumn}>
                      {partner?.buttons &&
                        partner.buttons.slice(3, 6).map((button, index) => {
                          if (button.isDisplayed) {
                            const item = modalListSelector(
                              button.buttonType,
                              partner,
                              language,
                            );
                            return (
                              <CustomDropdown
                                url={item.url}
                                itemName={item.listName}
                                key={index}
                                data={item.data}
                                buttonName={button[item.name]}
                                list={item.list}
                                modalName={item.modal}
                              />
                            );
                          }
                          return null;
                        })}
                    </Box>
                  </>
                )}
              </Box>
              {!matches && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => push(routes.exhibition)}
                  className={classes.participantButtonBack}
                >
                  {formatMessage({ id: 'exhibition_button_back' })}
                </Button>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ExhibitionParticipant;
