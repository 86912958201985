import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  partnersContainer:{
    display:"flex",
    flexWrap:'wrap',
    
  },
  imageContainer: {
    maxHeight: 51,
    maxWidth: 130,
    marginRight:5,
    marginBottom:15,
    "&>img":{
      height:"100%",
      width:"100%",
      objectFit:"contain"
    }
  },
}));

export default useStyles;
