import React, { useState, useRef, useEffect } from 'react';
import useStyles from './styles';
import { ReactComponent as ArrowRightCircleIcon } from '../../../../assets/icons/arrowRightCircleIcon.svg';
import { show } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Link } from '@material-ui/core';
import classNames from 'classnames';

const CustomButtonDropdown = ({
  data,
  modalName,
  buttonName,
  url,
  itemName,
  list,
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const isModalOpen = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const isList = list?.length >= 1;
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !Object.entries(isModalOpen).length
    ) {
      setExpand(false);
    }
  };
  useEffect(() => {
    if (isList) {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box ref={ref}>
      <Link href={url} target={'_blank'} className={classes.dropLink}>
        <Box
          className={classNames(
            classes.block,
            isList && expand && classes.blockList,
          )}
          onClick={() => {
            if (isList && !url) {
              setExpand(!expand);
            } else {
              if (list) {
                dispatch(show(modalName, { item: list[0] }));
              } else {
                dispatch(show(modalName, { ...data }));
              }
            }
          }}
        >
          {buttonName}

          <ArrowRightCircleIcon className={classes.arrowRight} />
        </Box>
      </Link>
      {list && isList && expand && (
        <Box className={classes.blockDropDownContainer}>
          {list?.map((item, index) => {
            return (
              <Box
                key={index}
                onClick={() => dispatch(show(modalName, { item }))}
                className={classes.dropdownItem}
              >
                {item[itemName]}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default CustomButtonDropdown;
