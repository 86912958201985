// Types
import types from '../actionTypes';
// Services
import ApiService from '../../services/ApiService';
import notify from '../../common/helpers/notify';

// API actions
const fetchPartnersStart = () => ({
  type: types.FETCH_PARTNERS_START,
});
const fetchPartnersSuccess = (data) => ({
  type: types.FETCH_PARTNERS_SUCCESS,
  payload: data,
});
const fetchPartnersError = ({ message }) => ({
  type: types.FETCH_PARTNERS_ERROR,
  payload: message,
});
const fetchPartnersClearError = () => ({
  type: types.FETCH_PARTNERS_CLEAR_ERROR,
});
export const fetchPartnersList = () => (dispatch) => {
  dispatch(fetchPartnersStart());

  ApiService.apiCall({
    url: ApiService.paths.partners.GET_ALL,
    token: true,
  })
    .then((response) => {
      dispatch(fetchPartnersSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchPartnersError(error));
      setTimeout(() => {
        fetchPartnersClearError();
      }, 300);
    });
};

const fetchSponsorsSuccess = (data) => ({
  type: types.FETCH_SPONSORS_SUCCESS,
  payload: data,
});

export const fetchPartnersImages = () => (dispatch) => {
  dispatch(fetchPartnersStart());

  ApiService.apiCall({
    url: ApiService.paths.partners.GET_ALL_PUBLIC,
  })
    .then((response) => {
      dispatch(fetchSponsorsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchPartnersError(error));
      setTimeout(() => {
        fetchPartnersClearError();
      }, 300);
    });
};

const fetchPartnerByIdSuccess = (data) => ({
  type: types.FETCH_CURRENT_PARTNER_SUCCESS,
  payload: data,
});

//Analitics for partner
export const fetchVisitedPartnerByID = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partners.ANALITICS,
    method: 'POST',
    token: true,
    data: { partnerId: data.id },
  });
};
// Analitics for partner materials
export const fetchDownloadPartnerMaterialByID = (data) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partners.MATERIALS_ANALITICS,
    method: 'POST',
    token: true,
    data: { materialId: data.id },
  });
};

export const fetchPartnerByIDAsync = (data) => (dispatch) => {
  dispatch(fetchPartnersStart());

  dispatch(fetchVisitedPartnerByID(data));

  ApiService.apiCall({
    url: ApiService.paths.partners.GET_BY_ID,
    token: true,
    params: data,
  })
    .then((response) => {
      dispatch(fetchPartnerByIdSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchPartnersError(error));
      setTimeout(() => {
        fetchPartnersClearError();
      }, 300);
    });
};

// FEEDBACK
export const postFeedback = (data, callback, errorCallback) => (dispatch) => {
  ApiService.apiCall({
    url: ApiService.paths.partners.FEEDBACK,
    method: 'POST',
    token: true,
    data,
  })
    .then((response) => {
      if (response?.response?.data?.status >= 400) {
        errorCallback(response?.response?.data?.message);
      } else {
        callback();
      }
    })
    .catch((error) => {
      console.log(error);
      notify(error.message, 'error');
    });
};
