import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { connectModal, hide } from 'redux-modal';
import { WEBINAR_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { ReactComponent as VisibilityOn } from '../../../../assets/icons/visibilityOn.svg';
import VideoPlayer from '../../global/VideoPlayer';

const WebinarModal = ({ show, item }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const language = useSelector((state) => state.user.user.language);
  const videoURL = language === 'UA' ? 'videoUrlUA' : 'videoUrlEN';
  const videoCount =
    language === 'UA' ? 'videoViewCountUA' : 'videoViewCountEN';
  const title = language === 'UA' ? 'nameUA' : 'nameEN';
  return (
    <div>
      {!!item && (
        <Dialog
          open={show}
          onClose={() => dispatch(hide(WEBINAR_MODAL))}
          maxWidth='xl'
        >
          <DialogContent className={classes.body}>
            <div
              className={classes.closer}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(hide(WEBINAR_MODAL));
              }}
            >
              <span className={classes.closerText}>
                {formatMessage({ id: 'close_modal_button' })}
              </span>
              <CloseCircleIcon />
            </div>
            <div className={classes.webinarModalWrapper}>
              <Typography className={classes.title} variant='h3'>
                {item[title]}
              </Typography>
              <div className={classes.webinarModalInner}>
                <div className={classes.videoControl}>
                  <div className={classes.video}>
                    <VideoPlayer
                      url={item && item[videoURL]}
                      width={'100%'}
                      controls
                      style={{ flex: 1, borderRadius: 10, overflow: 'hidden' }}
                    />
                  </div>
                  <div className={classes.videoControlFooter}>
                    <VisibilityOn />
                    {item && item[videoCount]}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default connectModal({
  name: WEBINAR_MODAL,
  getModalState: (state) => state.modals,
})(WebinarModal);
