// Utils
import axios from 'axios';
import history from '../common/helpers/HistoryHelper';
import notify from '../common/helpers/notify';

// Services
import StorageService from '../services/StorageService';

// Store
import store from '../store';

// Constants
import userTypes from '../store/actionTypes';
import routes from '../common/routes';

class ApiService {
  constructor() {
    this.client = axios.create({
      baseURL:
        process.env.REACT_APP_API_URL ||
        'http://filatov-readings.demo.malevich.com.ua/api/v1',
      timeout: 60 * 1000, // 1 min
    });
    this.paths = routes.api;
  }

  apiCall({
    url = '',
    method = 'GET',
    token = false,
    onUploadProgress = () => {},
    cancelToken = false,
    ...otherParams
  }) {
    let options = {
      url,
      method,
      headers: this.buildHeaders(token, otherParams),
      onUploadProgress,
      cancelToken,
    };

    if (otherParams) options = { ...options, ...otherParams };

    return this.client(options)
      .then(this.handleCommonSuccess)
      .catch(this.handleCommonError);
  }

  buildHeaders(token, otherParams = {}) {
    let headers = {
      'Content-type': otherParams.multi
        ? 'multipart/form-data'
        : 'application/json',
      Accept: 'application/json',
    };

    if (token) headers['Authorization'] = `Bearer ${StorageService.getToken()}`;

    return headers;
  }

  handleCommonSuccess(response) {
    return response.data;
  }

  handleCommonError(error) {
    if (axios.isCancel(error)) {
      notify(error.message, 'error');
      console.log('Request canceled', error.message);
    }
    if (error.response?.data?.status === 403) {
      store.dispatch({ type: userTypes.LOGOUT_USER });
      history.push('/');
      // notify('Your session was over! Please login again - Ваша сессія завершилася, будь ласка увійдіть ще раз')
    }

    return error;
  }
}

export default new ApiService();
