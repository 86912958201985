import React from 'react';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import useStyles from './styles';
import { useIntl } from 'react-intl';

const PDFControlPanel = ({pageNumber, numPages, setPageNumber, scale, setScale}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
  const lastPageClass = isLastPage ? 'disabled' : 'clickable';

  /*Navigation*/
  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  /*Zoom*/
  const isMinZoom = scale < 0.3;
  const isMaxZoom = scale >= 3.0;

  const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
  const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };
  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className={classes.controlPanel}>
      <div className={classes.controlPanelItem}>
        <FirstPageIcon
          className={`${firstPageClass}`}
          onClick={goToFirstPage}
        />
        <ChevronLeftIcon
          className={`${firstPageClass}`}
          onClick={goToPreviousPage}
        />
        <span>{pageNumber} {formatMessage({ id: "control_panel_component_of" })} {numPages}</span>
        <ChevronRightIcon
          className={`${lastPageClass}`}
          onClick={goToNextPage}
        />
        <LastPageIcon
          className={`${lastPageClass}`}
          onClick={goToLastPage}
        />
      </div>
      <div className={classes.controlPanelItem}>
        <ZoomOutIcon
          className={`${zoomOutClass}`}
          onClick={zoomOut}
        />
        <span className={classes.controlPanelScale}>{(scale * 100).toFixed()}%</span>
        <ZoomInIcon
          className={`${zoomInClass}`}
          onClick={zoomIn}
        />
      </div>
    </div>
  );
};

export default PDFControlPanel;