import userTypes from './userTypes'
import broadcastTypes from './broadcastTypes'
import chatTypes from './chatTypes'
import webSocketTypes from './webSocketTypes'
import localeTypes from './localeTypes'
import faqTypes from './faqTypes'
import partnersTypes from './partnersTypes'
import programTypes from './programTypes'
import eposterTypes from './eposterTypes'
import webinarTypes from './webinarTypes'
import symposiumTypes from './symposiumTypes'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...userTypes,
    ...broadcastTypes,
    ...chatTypes,
    ...webSocketTypes,
    ...localeTypes,
    ...faqTypes,
    ...programTypes,
    ...partnersTypes,
    ...eposterTypes,
    ...webinarTypes,
    ...symposiumTypes,
}