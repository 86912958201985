import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
 import { connectModal, hide } from 'redux-modal';
import { BUSINESS_CARD_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';
import useIntl from 'react-intl/lib/src/components/useIntl';

const BusinessCardModal = ({
    show,url
  }) => {
  const theme = useSelector((state) => state.theme.value);
  const dispatch = useDispatch();
  const classes = useStyles({ theme });
  const { formatMessage } = useIntl();
  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(BUSINESS_CARD_MODAL))}
        maxWidth="xl"
      >
        <DialogContent className={classes.body}>
          <div className={classes.closer} onClick={() => dispatch(hide(BUSINESS_CARD_MODAL))}>
            <span className={classes.closerText}>{formatMessage({ id: "close_modal_button" })}</span>
            <CloseCircleIcon />
          </div>

          <div className={classes.businessCard}>
            <img src={url} alt="" />
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: BUSINESS_CARD_MODAL,
  getModalState: (state) => state.modals,
})(BusinessCardModal);
