// Types
import types from '../actionTypes';
// Services
import ApiService from '../../services/ApiService';

export const setActiveTab = (data) => ({
  type: types.SET_ACTIVE_TAB,
  payload: data,
});
// API actions
const fetchFaqStart = () => ({
  type: types.FETCH_FAQ_START,
});
const fetchFaqSuccess = (data) => ({
  type: types.FETCH_FAQ_SUCCESS,
  payload: data,
});
const fetchFaqError = ({ message }) => ({
  type: types.FETCH_FAQ_ERROR,
  payload: message,
});
const fetchFaqClearError = () => ({
  type: types.FETCH_FAQ_CLEAR_ERROR,
});
export const fetchFaqList =()=> dispatch => {
  dispatch(fetchFaqStart());

  ApiService.apiCall({
    url: ApiService.paths.questions.GET_FAQ,
    token: true,
  })
    .then((response) => {
      dispatch(
        fetchFaqSuccess(response.data),
      );
    })
    .catch((error) => {
      dispatch(fetchFaqError(error));
      setTimeout(() => {
        fetchFaqClearError();
      }, 300);
    });
};
