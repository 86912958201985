import { makeStyles } from '@material-ui/styles';

import palette from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  videoControl: {
    backgroundColor: palette.common.sky,
    width: '100%',
    '@media (min-width: 768px)': {
      width: 700,
    },
    '@media (min-width: 1000px)': {
      width: 540,
    },
    '@media (min-width: 1440px)': {
      width: 740,
    },
  },

  programTitleText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
  },
  programListWrapper: {
    width: '330px',

    '@media (max-width: 1440px)': {
      width: '100%',
    },
  },
  programHeader: {
    height: 45,
    padding: '11px 20px 14px 20px',
    // marginRight: 5,
    backgroundColor: '#ECEEF3',
    borderTop: `5px solid ${palette.secondary.main}`,
    borderRadius: '5px 5px 0 0',

  },
  activeProgram: {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '18px !important',
    color: 'black !important',
  },
  programListContainer: {
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 395,
    "&>:last-child":{
      paddingBottom:60
    },
    "&>:first-child":{
      paddingTop:15
    }
  },
  programItemTime: {
    fontSize: 12,
    color: '#58727F',
  },
  programItemText: {
    color: '#294F5F',
    fontSize: 14,
    marginTop: 5,
    fontWeight: 300,
    lineHeight: '18px',
    marginBottom: 20,
  },
  scrollbars: {
    maxHeight: 360,
    position: 'relative',
    '&::before': {
      pointerEvents:"none",
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '120px',
      color: 'transparent',
      background:
        'linear-gradient(0deg, #FBFBFD 0%, rgba(251, 251, 253, 0) 100%)',
      zIndex: 1,
    },
  },

  trackVertical: {
    height: '100%',
    top: 6,
    right: 0,
    display: 'none',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
  thumbVertical: {
    borderRadius: 4,
    background: palette.common.grey,
    display: 'none',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
}));

export default useStyles;
