import palette from '../palette';

const MuiAccordion = {
  root: {
    minHeight: '50px',
    marginBottom: '10px',
    color: palette.primary.main,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '21px',
    background: palette.common.cream,
    borderRadius: '5px !important',
    boxShadow: 'none',

    '&$expanded': {
      color: palette.common.white,
      background: palette.secondary.main,
      '& p': {
        color: palette.common.white,
      },
    },

    '&:before': {
      display: 'none',
    },
  },
};

export default MuiAccordion;
