import { themeHandler } from '../../common/functions/themeHandler';
import { THEME_CHANGE } from '../actionTypes/theme';

const initialState = {
  value:
    JSON.parse(localStorage.getItem('theme')) ||
    localStorage.getItem('theme') === null,
};

const themeReducer = (state = initialState, { type }) => {
  switch (type) {
    case THEME_CHANGE:
      themeHandler(!state.value);
      return {
        ...state,
        value: !state.value,
      };
    default:
      return state;
  }
};

export default themeReducer;
