/* eslint-disable import/no-anonymous-default-export */
import * as userActions from './userActions';
import * as broadcastsActions from './broadcastsActions';
import * as chatActions from './chatActions';
import * as webSocketActions from './webSocketActions';
import * as localeActions from './localeActions';
import * as faqActions from './faqActions';
import * as programActions from './programActions';
import * as partnersActions from './partnersActions';
import * as epostersActions from './epostersActions';
import * as webinarsActions from './webinarsActions';
import * as symposiumsActions from './symposiumsActions';
import * as contentsActions from './contentsActions';
import * as materialsActions from './materialsActions';

export default {
  ...userActions,
  ...broadcastsActions,
  ...chatActions,
  ...webSocketActions,
  ...localeActions,
  ...faqActions,
  ...programActions,
  ...partnersActions,
  ...epostersActions,
  ...webinarsActions,
  ...symposiumsActions,
  ...contentsActions,
  ...materialsActions
};
