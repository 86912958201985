import { makeStyles } from '@material-ui/styles';

import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    flex: '1 auto',
    paddingTop: 35,
    paddingBottom: 35,
  },
  wrapper: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  column: {
    maxWidth: '555px',
    '&:last-child': {
      maxWidth: '100%',
      width: '555px',
    },

    '@media (max-width: 1440px)': {
      width: '100%',
      '&:last-child': {
        width: '100%',
      },
    },
  },
  dearColleagues: {
    maxWidth: '555px',
    marginBottom: '60px',
    paddingLeft: '15px',
    borderLeft: `5px solid ${palette.secondary.main}`,

    '@media (max-width: 1440px)': {
      marginBottom: '30px',
    },
  },
  companyLogos: {
    '@media (max-width: 1440px)': {
      marginBottom: '30px',
    },
  }
}));

export default useStyles;
