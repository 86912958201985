import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phoneIcon.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/mailIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebookIcon.svg';
import Switcher from '../components/global/Switcher';
import { useIntl } from 'react-intl';
import useStyles from './styles';
// Routes-Navigation
import {useLocation} from 'react-router-dom'
import routes from '../routes'
import { useSelector } from 'react-redux';
import { getControllFildsValue } from '../../services/getControllFildsValue';

const Footer = () => {
  const { formatMessage } = useIntl();
  const location = useLocation()
  const isHome=location.pathname===routes.publicHome

  const contentsList = useSelector((state) => state.contents.fields);

  const match768 = useMediaQuery(`(max-width:768px)`)
  const match1000 = useMediaQuery(`(max-width:1000px)`)
  const classes = useStyles({isHome});
  return (
    <footer className={classes.footer}>
      <Container className={classes.footerInner}>
        <div className={classes.footerColumn}>
          {!match768&&<div className={classes.footerItem}>
            {getControllFildsValue(contentsList, 'help-title-ua', 'help-title-en')}
          </div>}
          <div className={classes.footerItem}>
            <PhoneIcon />
            <div>
              <Link
                className={classes.footerPhoneLink}
                href={`tel:${getControllFildsValue(contentsList, 'tel-1-ua', 'tel-1-en')}`}
              >
                {getControllFildsValue(contentsList, 'tel-1-ua', 'tel-1-en')}
              </Link>
              {getControllFildsValue(contentsList, 'fio-1-ua', 'fio-1-en')}
            </div>
          </div>
          <div className={classes.footerItem}>
            <MailIcon />
            <div>
              <Link href={`mailto:${getControllFildsValue(contentsList, 'email-ua', 'email-en')}`}>
                {getControllFildsValue(contentsList, 'email-ua', 'email-en')}
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.footerColumn}>
          <div className={`${classes.footerItem} ${classes.footerItemHidden}`} />
          <div className={classes.footerItem}>
            <PhoneIcon />
            <div>
              <Link
                className={classes.footerPhoneLink}
                href={`tel:${getControllFildsValue(contentsList, 'tel-2-ua', 'tel-2-en')}`}
              >
                {getControllFildsValue(contentsList, 'tel-2-ua', 'tel-2-en')}
              </Link>
              {getControllFildsValue(contentsList, 'fio-2-ua', 'fio-2-en')}
            </div>
          </div>
          <div className={classes.footerItem}>
            <MailIcon />
            <div style={{display:"flex",justifyContent:'space-between'}}>
              {formatMessage({ id: "footer_mail_text" })}&nbsp;
              <Link href={`mailto:${getControllFildsValue(contentsList, 'email-2-ua', 'email-2-en')}`}>
                {getControllFildsValue(contentsList, 'email-2-ua', 'email-2-en')}
              </Link>
            </div>
            
          </div>
          
          
        </div>
        <div className={classes.footerColumn}>
          <div className={classes.footerInfo}>
           {!match768&&<div className={`${classes.footerItem} ${classes.footerItemBottom}`}>
            {getControllFildsValue(contentsList, 'conf-name-footer-ua', 'conf-name-footer-en')}
            </div>}
            {match768&& <div>
            <div className={`${classes.footerItem} ${classes.footerItemSwitcher}`} style={{display:'flex',justifyContent:"space-between",width:"100%",}}>
              <div  className={classes.footerColumn} style={{flex:1}}>
              <Link
              href={getControllFildsValue(contentsList, 'fb-link-ua', 'fb-link-en')}
              target='_blank'
              className={`${classes.footerItem} ${classes.footerItemFacebook}`}
            >
                {getControllFildsValue(contentsList, 'fb-title-ua', 'fb-title-en')}
                <FacebookIcon />
            </Link>
            </div>
            <div className={classes.footerColumn} style={{flex:1,paddingLeft:11}}>
            <Switcher />
            </div>
            </div>
          </div>}
            <div className={classes.footerItem} style={{display:'flex', justifyContent:"space-between"}}>
              
              <Link
                href={getControllFildsValue(contentsList, 'polit-link-ua', 'polit-link-en')}
                target='_blank'
              >
                {getControllFildsValue(contentsList, 'polit-ua', 'polit-en')}
              </Link>
              {match768&&<div style={{paddingLeft:10,margin:"0 !important",maxWidth:300}}>
              {getControllFildsValue(contentsList, 'conf-name-footer-ua', 'conf-name-footer-en')}
            </div>}
            </div>
            
            {!match768&&<Link
               href={getControllFildsValue(contentsList, 'fb-link-ua', 'fb-link-en')}
              target='_blank'
              className={`${classes.footerItem} ${classes.footerItemFacebook}`}
            >
                {getControllFildsValue(contentsList, 'fb-title-ua', 'fb-title-en')}
                <FacebookIcon />
            </Link>}
          </div>
         {!match768&&!match1000&& <div className={classes.footerSwitcher}>
            <div className={`${classes.footerItem} ${classes.footerItemSwitcher}`}>
              <Switcher />
            </div>
          </div>}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
