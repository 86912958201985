import userTypes from '../actionTypes';
import StorageService from '../../services/StorageService';
// services
const INIT_STATE = {
  user: StorageService.getSession() || null,
  isAuthorized: !!StorageService.getToken(),
  forgotUserMail: '',
  diplomaLink: '',
  oldPassword: '',
  newPassword: '',
  resetToken: '',
  activateToken: '',
  loading: false,
  error: '',
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case userTypes.ADD_DIPLOMA_LINK:
      return { ...state, diplomaLink: action.payload };
    case userTypes.CLEAR_USER_ERROR:
      return { ...state, error: '' };

    case userTypes.FETCH_REGISTER_USER_START:
      return { ...state, loading: true, error: '' };

    case userTypes.FETCH_REGISTER_USER_SUCCESS:
      return { ...state, loading: false, error: '' };

    case userTypes.FETCH_REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        isAuthorized: false,
        error: action.payload.message,
      };

    case userTypes.FETCH_LOGIN_USER_START:
      return { ...state, loading: true, error: '' };

    case userTypes.FETCH_LOGIN_USER_SUCCESS:
      return { ...state, isAuthorized: true, loading: false, error: '' };

    case userTypes.FETCH_LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        isAuthorized: false,
        error: action.payload.message,
      };

    case userTypes.FETCH_CURRENT_USER_START:
      return { ...state, loading: true, error: '' };

    case userTypes.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthorized: true,
        user: action.payload,
        error: '',
      };

    case userTypes.FETCH_CURRENT_USER_ERROR:
      return {
        ...state,
        loading: false,
        isAuthorized: false,
        error: action.payload,
      };

    /*Edit User for Cabinet*/
    case userTypes.EDIT_USER_START:
      return { ...state, loading: true, error: '' };

    case userTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case userTypes.EDIT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /*Change Password for Cabinet*/
    case userTypes.CHANGE_PASSWORD_START:
      return { ...state, loading: true, error: '' };

    case userTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        resetToken: '',
      };

    case userTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        resetToken: '',
      };

    case userTypes.FORGOT_PASSWORD_START:
      return { ...state, loading: true, error: '' };

    case userTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: '',
      };

    case userTypes.FORGOT_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.payload };

    case userTypes.SET_RESET_TOKEN:
      return { ...state, resetToken: action.payload };
    case userTypes.RESET_PASSWORD_START:
      return { ...state, loading: true, error: '' };

    case userTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: '', resetToken: '' };

    case userTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        resetToken: '',
      };
    case userTypes.LOGOUT_USER:
      return {
        ...state,
        user: null,
        isAuthorized: false,
        error: '',
        loading: false,
      };

    default:
      return state;
  }
};

export default userReducer;
