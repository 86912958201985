import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import { ReactComponent as PlayCircleIcon } from '../../../assets/icons/playCircleIcon.svg';
import Button from '@material-ui/core/Button';
import ProgramModal from '../../components/modals/ProgramModal';
import actions from '../../../store/actions';
import DateFormat from '../../../services/DateFormats';
import ProgramItem from '../../components/global/ProgramList/ProgramItem';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@material-ui/core';
import routes from '../../routes';
import Loading from '../../components/global/Loading';

const transformData = (data) => {
  const daysData = [];
  let result = [];

  data.forEach((day, dayIndex) => {
    let timeArrayStart = [];
    let timeArrayFinish = [];

    daysData[dayIndex] = [];
    result[dayIndex] = [];

    day?.halls?.forEach((hall, hallIndex) => {
      daysData[dayIndex][hallIndex] = [];
      result[dayIndex][hallIndex] = [];

      for (let program in hall?.sessions) {
        let programObject = hall?.sessions[program];

        daysData[dayIndex][hallIndex].push(programObject);
        timeArrayStart.push(programObject.startedAt);
        timeArrayFinish.push(programObject.endAt);
      }
    });

    //Getting earliest and latest date for the schedule range
    const earliestTime = Math.min(...timeArrayStart) * 1000; // adding miliseconds
    const latestTime = Math.max(...timeArrayStart) * 1000; // adding miliseconds
    // Transforming to HHmm format to get Number
    const startNumber = Number(
      earliestTime !== Number.POSITIVE_INFINITY &&
        DateFormat.parseDateToTime(earliestTime, 'HHmm', {
          tz: 'Europe/Kiev',
        }),
    );
    const latestNumber = Number(
      earliestTime !== Number.POSITIVE_INFINITY &&
        DateFormat.parseDateToTime(latestTime, 'HHmm', { tz: 'Europe/Kiev' }),
    );
    // Populating Array with hours in number values in range of min and max values
    let flooredMin = ((startNumber / 100) | 0) * 100;
    let ceiledMax = ((latestNumber / 100) | 0) * 100;
    if (ceiledMax !== 0) {
      ceiledMax++;
    }
    let emptyDayNumbers = [];
    for (let i = flooredMin; i < ceiledMax; i += 100) {
      emptyDayNumbers.push(i, i + 10, i + 20, i + 30, i + 40, i + 50);
    }
    // Deleting first row if starting time after 30 minutes - so 1st row won't be empty and generation of empty list logic will be stable
    if (startNumber % 100 >= 30) emptyDayNumbers = emptyDayNumbers.slice(1);

    daysData.forEach((dayData, dayDataIndex) => {
      dayData.forEach((hallArray, hallArrayIndex) => {
        //
        let newEmptyDay = [...emptyDayNumbers].map((time) => {
          let endOfBlock = time + 10;
          let returning = time;
          hallArray.forEach((program) => {
            let startedAt = Number(
              DateFormat.parseDateToTime(program.startedAt * 1000, 'HHmm', {
                tz: 'Europe/Kiev',
              }),
            );
            if (startedAt >= time && startedAt < endOfBlock) {
              returning = { time, program };
            }
          });
          return returning;
        });

        result[dayIndex][hallArrayIndex] = newEmptyDay;
      });
    });
  });

  for (let dayIndex = 0; dayIndex < result.length; dayIndex++) {
    let lengthOfProgramItemArray = [];

    for (let hallIhdex = 0; hallIhdex < result[dayIndex].length; hallIhdex++) {
      lengthOfProgramItemArray.push(result[dayIndex][hallIhdex].length);
    }

    let lengthOfProgram = Math.max(...lengthOfProgramItemArray);

    for (
      let programitemIndex = 0;
      programitemIndex < lengthOfProgram;
      programitemIndex++
    ) {
      let rowProgram = [];
      for (
        let elementOfRow = 0;
        elementOfRow < result[dayIndex].length;
        elementOfRow++
      ) {
        rowProgram.push(result[dayIndex][elementOfRow][programitemIndex]);
      }

      if (rowProgram.every((col) => !Boolean(col?.program))) {
        for (
          let elementOfRow = 0;
          elementOfRow < result[dayIndex].length;
          elementOfRow++
        ) {
          delete result[dayIndex][elementOfRow][programitemIndex];
        }
      }
    }
  }

  return result;
};

const { fetchAllProgramListAsync } = actions;

const Program = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const programs = useSelector((state) => state.programs.list);
  const loading = useSelector((state) => state.programs.loading);
  const [activeDayId, setActiveDayId] = useState(null);

  const language = useSelector((state) => state.user.user.language);
  // const [activeDayId, setActiveDayId] = useState(sessions[0].id)

  const match1100 = useMediaQuery('@media (max-width:1000px)');
  useEffect(() => {
    dispatch(fetchAllProgramListAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programs?.length > 0) {
      setActiveDayId(programs[0].id);
    }
  }, [programs]);

  if (loading || programs?.length < 1) {
    return <Loading />;
  }

  return (
    <div>
      <ProgramModal />
      <section className={classes.section}>
        <Container style={{ flexDirection: 'column' }}>
          <div className={classes.programInfo}>
            <div className={classes.programTabs}>
              {programs?.length > 0 &&
                programs?.map((item, index) => {
                  let date = new Date(item.dateValue);

                  if (item?.halls.length < 1) {
                    return null;
                  }

                  return (
                    <Button
                      key={index}
                      variant='contained'
                      color={activeDayId === item.id ? 'primary' : 'secondary'}
                      className={classes.programButton}
                      onClick={() => setActiveDayId(item.id)}
                    >
                      {date.toLocaleString(language === 'UA' ? 'uk' : 'en-US', {
                        month: 'long',
                        day: 'numeric',
                      })}
                    </Button>
                  );
                })}
            </div>

            <Typography variant='h3'>
              {formatMessage({ id: 'program_title' })}
            </Typography>
          </div>

          <div className={classes.programTable}>
            {
              // eslint-disable-next-line array-callback-return
              programs
                // eslint-disable-next-line array-callback-return
                ?.filter((item, i) => {
                  const dayIndex = programs
                    .map((item) => +item.id)
                    .indexOf(activeDayId);
                  if (dayIndex === i) {
                    return item;
                  }
                })
                .map((item, i) => {
                  return item.halls.map((hall, i) => {
                    const dayIndex = programs
                      .map((item) => +item.id)
                      .indexOf(+activeDayId);
                    let dayInfo = transformData(programs)[dayIndex];

                    return (
                      <div className={classes.column} key={i}>
                        <Button
                          className={`${classes.block} ${classes.blockHall}`}
                          onClick={() =>
                            history.push(routes.translation + `/${hall.id}`)
                          }
                        >
                          <div className={classes.hallTitle}>
                            {language === 'UA' ? hall.hallUA : hall.hallEN}
                          </div>
                          <div className={classes.blockInfo}>
                            <div className={classes.hallWatch}>
                              {formatMessage({ id: 'program_watch' })}
                            </div>
                            <PlayCircleIcon
                              className={`${classes.arrowRight} ${classes.arrowRightHall}`}
                            />
                          </div>
                        </Button>

                        {dayInfo &&
                          !!dayInfo[i].length &&
                          dayInfo[i].map(
                            (item, index) =>
                              !(match1100 && !item.program) && (
                                <ProgramItem
                                  hallId={item.id}
                                  key={index}
                                  index={index}
                                  item={item}
                                />
                              ),
                          )}
                      </div>
                    );
                  });
                })
            }
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Program;
