import { makeStyles } from '@material-ui/styles';
import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    paddingTop: 35,
    paddingBottom: 35,
  },
  electronicPostersTable: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: '30px',
    marginRight:-20,
    '@media (max-width: 768px)': {
      marginRight:0
    }
  },
  block: {
    position: 'relative',
    width: '380px',
    minHeight:84,
    marginBottom: '10px',
    padding: '10px 35px 10px 10px',
    background: palette.common.cream,
    borderRadius: '5px',
    marginRight:20,
    cursor: 'pointer',
    '&:hover': {
      color: palette.common.white,
      background: palette.secondary.main,
    },
    '&:hover *': {
      color: palette.common.white,
    },

    '@media (max-width: 1440px)': {
      width: '460px',
    },
    '@media (max-width: 1000px)': {
      width: '340px',
    },
    '@media (max-width: 768px)': {
      width: "100%",
      marginRight:0
    },
  },
  author: {
    marginTop: '10px',
    color: '#58727F',
    fontWeight: '300',
  },
  arrowRight: {
    position: 'absolute',
    right: '10px',
    bottom: '10px',
    color: palette.secondary.main,
  },
}));

export default useStyles;
