import React from 'react';
import classNames from 'classnames'
import useStyles from './styles';
import { useSelector,useDispatch } from 'react-redux';
import {MATCH_STATE} from '../../../../lang/locales'
import actions from '../../../../store/actions'

const {setLocale}=actions

const Switcher = () => {
  const classes = useStyles();
  const dispatch=useDispatch()
  const locale = useSelector(state=>state.locale.language)
  const handleClick = (value,dispatch)=>dispatch(setLocale(value)) 
  return (
    <ul className={classes.switcher}>
      <li>
        <button
          onClick={()=>MATCH_STATE[locale]!=='UA'&&handleClick('UA',dispatch)}
          className={classNames(classes.switchLanguage,MATCH_STATE[locale]==='UA'&&classes.switchLanguageActive)}
        >
          UA
        </button>
      </li>
      <li>
        <button 
        onClick={()=>MATCH_STATE[locale]!=='EN'&&handleClick('EN',dispatch)}
        className={classNames(classes.switchLanguage,MATCH_STATE[locale]==='EN'&&classes.switchLanguageActive)}>EN</button>
      </li>
    </ul>
  );
};

export default Switcher;
