import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Scrollbars } from 'react-custom-scrollbars';
import useIntl from 'react-intl/lib/src/components/useIntl';
import useStyles from './styles';
// Custom
import ProgramListItem from './ListItem';
import Loading from '../Loading';
import classNames from 'classnames';

const ProgramList = ({ list, loading, modal }) => {
  const classes = useStyles();
  const [active, setActive] = useState(null);
  const { formatMessage } = useIntl();

  const getNow = () => new Date().getTime() / 1000; // Get seconds value
  // TODO: DELETE temporary code tracking todays time !!!
  useEffect(() => {
    const checkActive = setInterval(() => {
      // const TEMPORARYparseTodaTime = (time) => time % 86400; // 86400 - amount of seconds in the day
      let timeNow = getNow(); // Get seconds value
      setActive(null);
      if (list.length > 0) {
        list.forEach((program) => {
          let start = program?.startedAt;
          let finish = program?.endAt;

          // DELETE THIS
          // let timeNow = TEMPORARYparseTodaTime(timeNow);
          // start = TEMPORARYparseTodaTime(start);
          // finish = TEMPORARYparseTodaTime(finish);
          // DELETE THIS END
          if (timeNow >= start && timeNow <= finish) {
            setActive(program.id);
          }
        });
      }
    }, 10000); // 10 sec
    // clearing interval
    return () => clearInterval(checkActive);
  }, [list]);
  return (
    <Grid
      item
      className={classNames(
        !modal && classes.videoControl,
        modal && classes.programListWrapper,
      )}
    >
      <Box className={classes.programHeader}>
        <Typography className={classes.programTitleText}>
          {formatMessage({
            id: modal
              ? 'program_modal_prog_scientific'
              : 'translation_programlist_title',
          })}
        </Typography>
      </Box>
      <Scrollbars
        renderTrackVertical={(props) => (
          <div {...props} className={classes.trackVertical} />
        )}
        renderTrackHorizontal={(props) => (
          <div {...props} className={classes.trackHorizontal} />
        )}
        renderThumbHorizontal={(props) => (
          <div {...props} className={classes.thumbHorizontal} />
        )}
        renderThumbVertical={(props) => (
          <div {...props} className={classes.thumbVertical} />
        )}
        style={{ position: 'relative', height: '540px' }}
        className={classes.scrollbars}
      >
        <>
          {loading ? (
            <Loading />
          ) : (
            <Box className={classes.programListContainer}>
              {list.length > 0
                ? list.map((item, i) => {
                    return (
                      <ProgramListItem
                        key={item.id}
                        item={item}
                        active={active}
                      />
                    );
                  })
                : formatMessage({ id: 'translation_programlist_no_program' })}
            </Box>
          )}
        </>
      </Scrollbars>
    </Grid>
  );
};

export default ProgramList;
