import { createMuiTheme } from '@material-ui/core/styles';

import overrides from './overrides';
import palette from './palette';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Inter, sans-serif'].join(','),
    color: palette.primary.main,
    fontWeight: '400',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1000,
      lg: 1440,
      xl: 1920,
    },
  },
  // breakpoints: {
  //   values: {
  //     mobile320: 320,
  //     mobile480: 480,
  //     tablet768: 768,
  //     tablet1024: 1024,
  //     desktop1280: 1280,
  //     desktop1366: 1366,
  //     desktop1440: 1440,
  //     desktop1920: 1920,
  //   },
  // },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: palette.primary.main,
          fontSize: 14,
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,

          '& img': {
            maxWidth: '100%',
          },

          '& a': {
            textDecoration: 'none',
            color: 'inherit',
          },
          '& a:visited': {},
          '& *': {
            boxSizing: 'border-box!important',
          },

          '& .slick-slide': {
            opacity: 0,
            transition: 'all 0.3s',

            '&.slick-active': {
              opacity: 1,
            },
          },
        },
      },
    },
    ...overrides,
  },
  palette,
});

export default theme;
