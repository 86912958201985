import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { show } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';
import CompanyLogos from '../../components/global/CompanyLogos';
import LoginModal from '../../components/modals/LoginModal';
import SignUpModal from '../../components/modals/SignUpModal';
import { LOGIN, SIGN_UP, ADD_NEW_PASSWORD } from '../../constants/modalNames';
import useStyles from './styles';
import PasswordRestoreModal from '../../components/modals/PasswordRestoreModal';
import ConfirmSuccessModal from '../../components/modals/ConfirmSuccessModal';
import ConfirmErrorModal from '../../components/modals/ConfirmErrorModal';
import AddNewPasswordModal from '../../components/modals/AddNewPasswordModal';
import { getControllFildsValue } from '../../../services/getControllFildsValue';

const PublicHome = () => {
  const theme = useSelector((state) => state.theme.value);
  const resetToken = useSelector((state) => state.user.resetToken);
  const contentsList = useSelector((state) => state.contents.fields);

  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  useEffect(() => {
    if (resetToken) {
      dispatch(show(ADD_NEW_PASSWORD));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoginModal />
      <SignUpModal />
      <PasswordRestoreModal />
      <ConfirmSuccessModal />
      <ConfirmErrorModal />
      <AddNewPasswordModal />
      <div className={classes.header}>
        <Container className={classes.headerInner}>
          <div className={classes.headerLogo}>
            <div className={classes.logoImage}>
              <img
                src={getControllFildsValue(
                  contentsList,
                  'logo-1-ua',
                  'logo-1-en',
                )}
                alt={getControllFildsValue(
                  contentsList,
                  'logo-1-ua',
                  'logo-1-en',
                )}
              />
            </div>
            <div className={`${classes.logoText} ${classes.logoTextFirst}`}>
              {getControllFildsValue(
                contentsList,
                'logo-1-desc-ua',
                'logo-1-desc-en',
              )}
            </div>
          </div>
          <div className={classes.headerLogo}>
            <div className={classes.logoImage}>
              <img
                src={getControllFildsValue(
                  contentsList,
                  'logo-2-ua',
                  'logo-2-en',
                )}
                alt={getControllFildsValue(
                  contentsList,
                  'logo-2-ua',
                  'logo-2-en',
                )}
              />
            </div>
            <div className={`${classes.logoText} ${classes.logoTextSecond}`}>
              {getControllFildsValue(
                contentsList,
                'logo-2-desc-ua',
                'logo-2-desc-en',
              )}
            </div>
          </div>
        </Container>
      </div>
      <div
        className={classes.homeBanner}
        style={{
          backgroundImage: `url(${getControllFildsValue(
            contentsList,
            'banner-ua',
            'banner-en',
          )})`,
        }}
      >
        <Container>
          <div className={classes.bannerText}>
            <span>
              {getControllFildsValue(contentsList, 'days-ua', 'days-en')}
            </span>
            <>
              {getControllFildsValue(contentsList, 'mounths-ua', 'mounths-en')}
              {getControllFildsValue(contentsList, 'city-ua', 'city-en')}
            </>
          </div>
        </Container>
      </div>
      <Container className={classes.mainPageContent}>
        <Typography variant='h2'>
          {getControllFildsValue(contentsList, 'hello-ua', 'hello-en')}
        </Typography>
        <Typography variant='h1'>
          {getControllFildsValue(contentsList, 'conf-name-ua', 'conf-name-en')}
        </Typography>
        <div className={classes.mainPageButtons}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => dispatch(show(LOGIN))}
            style={{
              width: '150px',
              height: '50px',
              marginRight: '20px',
              marginTop: '20px',
            }}
          >
            {getControllFildsValue(contentsList, 'log-in-ua', 'log-in-en')}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => dispatch(show(SIGN_UP))}
            style={{
              width: '180px',
              height: '50px',
              marginTop: '20px',
            }}
          >
            {getControllFildsValue(contentsList, 'reg-ua', 'reg-en')}
          </Button>
        </div>
        <div className={classes.companyLogos}>
          <CompanyLogos />
        </div>
      </Container>
    </>
  );
};
export default PublicHome;
