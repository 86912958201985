export const MOBILE_MENU = "MOBILE_MENU";
export const LOGIN = 'LOGIN';
export const SIGN_UP = 'SIGN_UP';
export const PASSWORD_RESTORE = 'PASSWORD_RESTORE';
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'CONFIRM_ERROR';
export const ADD_NEW_PASSWORD = 'ADD_NEW_PASSWORD';
export const PERSONAL_AREA_CHANGE_PASSWORD = 'PERSONAL_AREA_CHANGE_PASSWORD';
export const PERSONAL_AREA_SUCCESS_CHANGE_PASSWORD = 'PERSONAL_AREA_SUCCESS_CHANGE_PASSWORD';
export const ELECTRONIC_POSTERS_MODAL = 'ELECTRONIC_POSTERS_MODAL';
export const PROGRAM_MODAL = 'PROGRAM_MODAL';
export const WEBINAR_MODAL = 'WEBINAR_MODAL';
export const SATELLITE_SYMPOSIUMS_MODAL = 'SATELLITE_SYMPOSIUMS_MODAL';
export const CHAT_MODAL = 'CHAT_MODAL';
export const MATERIALS_MODAL = 'MATERIALS_MODAL';
export const FEEDBACK_MODAL = 'FEEDBACK_MODAL';
export const BUSINESS_CARD_MODAL = 'BUSINESS_CARD_MODAL';
export const STAND_MODAL = 'STAND_MODAL';
