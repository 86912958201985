import types from '../actionTypes';
const {
  FETCH_FAQ_START,
  FETCH_FAQ_CLEAR_ERROR,
  FETCH_FAQ_ERROR,
  FETCH_FAQ_SUCCESS,
} = types;

const INIT_STATE = {
  list: [],
  loading: false,
  error: '',
};
const faqReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_FAQ_START:
      return { ...state, loading: true };
    case FETCH_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        list: action.payload,
      };
    case FETCH_FAQ_ERROR:
      return { ...state, loading: false, error: action.payload };
    case FETCH_FAQ_CLEAR_ERROR:
      return { ...state, loading: false, error: '' };

    default:
      return state;
  }
};

export default faqReducer;
