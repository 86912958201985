// eslint-disable-next-line import/no-anonymous-default-export
export default {
    FETCH_PARTNERS_START: 'FETCH_PARTNERS_START',
    FETCH_PARTNERS_SUCCESS: 'FETCH_PARTNERS_SUCCESS',
    FETCH_PARTNERS_ERROR: 'FETCH_PARTNERS_ERROR',
    FETCH_PARTNERS_CLEAR_ERROR: 'FETCH_PARTNERS_CLEAR_ERROR',
    FETCH_CURRENT_PARTNER_SUCCESS:"FETCH_CURRENT_PARTNER_SUCCESS",
    FETCH_SPONSORS_SUCCESS:"FETCH_SPONSORS_SUCCESS"
  };
  
  