import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import { useDispatch, useSelector } from 'react-redux';
import { connectModal, hide } from 'redux-modal';
import { MATERIALS_MODAL } from '../../../constants/modalNames';
import useStyles from './styles';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { Scrollbars } from 'react-custom-scrollbars';
import { ReactComponent as FileTextIcon } from '../../../../assets/icons/filetextIcon.svg';
import { fetchDownloadPartnerMaterialByID } from '../../../../store/actions/partnersActions';

const MaterialsModal = ({ show, ...list }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const language = useSelector((state) => state.user.user.language);
  const name = language === 'UA' ? 'nameUA' : 'nameEN';
  const fileUrl = language === 'UA' ? 'fileUrlUA' : 'fileUrlEN';
  const { formatMessage } = useIntl();

  const handleAnalitics = (id) => {
    dispatch(fetchDownloadPartnerMaterialByID({ id }));
  };

  const materials = list[0];
  return (
    <div>
      {materials?.length >= 1 && (
        <Dialog
          open={show}
          onClose={() => dispatch(hide(MATERIALS_MODAL))}
          maxWidth='xl'
          className={classes.dialog}
        >
          <DialogContent className={classes.body}>
            <div
              className={classes.closer}
              onClick={() => dispatch(hide(MATERIALS_MODAL))}
            >
              <span className={classes.closerText}>
                {formatMessage({ id: 'close_modal_button' })}
              </span>
              <CloseCircleIcon />
            </div>
            <div className={classes.programListWrapper}>
              <div className={classes.scrollbars}>
                <Scrollbars
                  renderTrackVertical={(props) => (
                    <div {...props} className={classes.trackVertical} />
                  )}
                  renderTrackHorizontal={(props) => (
                    <div {...props} className={classes.trackHorizontal} />
                  )}
                  renderThumbHorizontal={(props) => (
                    <div {...props} className={classes.thumbHorizontal} />
                  )}
                  renderThumbVertical={(props) => (
                    <div {...props} className={classes.thumbVertical} />
                  )}
                  style={{ position: 'relative', height: '385px' }}
                >
                  <div className={classes.programListContainer}>
                    {materials.map((item, index) => {
                      return (
                        <Link
                          onClick={() => handleAnalitics(item.id)}
                          href={item[fileUrl]}
                          target='_blank'
                          className={classes.link}
                        >
                          <div className={classes.block} key={index}>
                            <div className={classes.title}>
                              <div className={classes.titleText}>
                                {item[name]}
                              </div>
                            </div>
                            <FileTextIcon className={classes.fileTextIcon} />
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </Scrollbars>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default connectModal({
  name: MATERIALS_MODAL,
  getModalState: (state) => state.modals,
})(MaterialsModal);
