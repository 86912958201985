import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// Services
import StorageService from '../../../../services/StorageService';
// Constants
import useIntl from 'react-intl/lib/src/components/useIntl';
import { getViewersWS } from '../../../constants/apiTypes';
import useStyles from './styles';
import {useDispatch} from 'react-redux'
import actions from '../../../../store/actions'
import notify from '../../../helpers/notify';

const {logoutUser}=actions

const ViewerCount = ({ videoId,initData }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { formatMessage } = useIntl();
  const [count, setCount] = useState({});
  // WebSocket Actions
  const onOpen = (e) => {
    // console.log(`%cConnect from viewers for ${videoId}`, 'color:green');
  };
  const onClose = (e) => {
    // console.log(`Now is ${new Date()}`);
    // console.log(e);
    // console.log(`%cDisconnected from viewers ${videoId}`, 'color:red');
  };
  const onError = (e) => {
    // console.log(`Now is ${new Date()}`);
    // console.log(e);
    // console.log(`%cError in viewers ${videoId}`, 'color:red');
  };

  const onMessage = (e) => {
    const response = JSON.parse(e.data);
    if (response.concurrentViewers) {
      setCount({ concurrentViewers: response.concurrentViewers });
    } else if (!response.concurrentViewers && response.viewCount) {
      setCount({ viewCount: response.viewCount });
    } else if (response.type==="AUTH_ERROR"){
      dispatch(logoutUser())
    }else {
      setCount(false);
    }
  };
  useEffect(()=>{
    if(initData){
      setCount(initData)
    }
  },[initData])
  useEffect(() => {
    try{
      const token = StorageService.getToken();
      const url =getViewersWS(token, videoId)
      const socket = new WebSocket(url)
      socket.onopen=onOpen
      socket.onmessage=onMessage
      socket.onclose=onClose
      socket.onerror=onError
      return () => socket.close()
    }catch(e){
      notify('Socket connection error','error')
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  return (
        <Box>
          <Typography className={classes.amount}>
            <span style={{ fontWeight: 500, textOverflow: 'ellipsis' }}>
              {count &&
                formatMessage({
                  id: count.concurrentViewers
                    ? 'viewers_count_online'
                    : 'viewers_total_offline',
                })}
            </span>
            {count.concurrentViewers
              ? count.concurrentViewers +
                formatMessage({ id: 'viewers_persons' })
              : count.viewCount}
          </Typography>
        </Box>
  );
};

export default ViewerCount;
