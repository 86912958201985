// Types
import types from '../actionTypes';
// Services
import ApiService from '../../services/ApiService';

// API actions
const fetchWebinarsStart = () => ({
  type: types.FETCH_GET_ALL_WEBINARS_START,
});
const fetchWebinarsSuccess = (data) => ({
  type: types.FETCH_GET_ALL_WEBINARS_SUCCESS,
  payload: data,
});
const fetchWebinarsError = ({ message }) => ({
  type: types.FETCH_GET_ALL_WEBINARS_ERROR,
  payload: message,
});
const fetchWebinarsClearError = () => ({
  type: types.FETCH_GET_ALL_WEBINARS_CLEAR_ERROR,
});
export const fetchAllWebinarListAsync = ()=>(dispatch) => {
  dispatch(fetchWebinarsStart());

  ApiService.apiCall({
    url: ApiService.paths.webinars.GET_ALL,
    token: true,
  })
    .then((response) => {
      dispatch(
        fetchWebinarsSuccess(response.data),
      );
    })
    .catch((error) => {
      dispatch(fetchWebinarsError(error));
      setTimeout(() => {
        fetchWebinarsClearError();
      }, 300);
    });
};
