import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import WebinarModal from '../../components/modals/WebinarModal';
import { ReactComponent as ArrowRightCircleIcon } from '../../../assets/icons/arrowRightCircleIcon.svg';
import routes from '../../routes';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import actions from '../../../store/actions';
import Loading from '../../components/global/Loading';

const { fetchPartnersList } = actions;

const Exhibition = () => {
  const theme = useSelector((state) => state.theme.value);
  const partnerList = useSelector((state) => state.partners.list);
  const loading = useSelector((state) => state.partners.loading);
  const classes = useStyles({ theme });
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  useEffect(() => {
    dispatch(fetchPartnersList());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <Loading />;
  return (
    <div>
      <WebinarModal />
      <section className={classes.section}>
        <Container style={{ flexDirection: 'column' }}>
          <Typography variant='h3'>
            {formatMessage({ id: 'exhibition_title' })}
          </Typography>
          <div className={classes.exhibitionTable}>
            {partnerList&&partnerList.map((item, index) => (
              <div
                className={classes.block}
                key={index}
                onClick={() =>
                  push(routes.exhibitionParticipant + `/${item.id}`)
                }
              >
                <div className={classes.title}>
                  <div className={classes.titleText}>
                    {item.isSponsor
                      ? formatMessage({ id: 'exhibition_general_partner' })
                      : formatMessage({ id: 'exhibition_general_participant' })}
                  </div>
                </div>
                <div className={classes.imageWrapper}>
                  <div className={classes.image}>
                    <img
                      style={{ fontSize: 15,minWidth:210}}
                      src={item.logoURL}
                      alt={item.name}
                    />
                  </div>
                </div>
                <ArrowRightCircleIcon className={classes.arrowRight} />
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Exhibition;
