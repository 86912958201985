import { makeStyles } from '@material-ui/styles';
import palette from '../../../../theme/palette';

const useStyles = makeStyles(() => ({
  block: {
    display: 'flex',
    position:'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    color: palette.primary.main,
    marginBottom: '10px',
    padding: '10px',
    background: palette.common.cream,
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      color: palette.common.white,
      background: palette.secondary.main,
      boxShadow: "0px 14px 24px -10px rgba(25, 69, 126, 0.7)"
    },
    '&:hover *': {
      color: palette.common.white,
    },

    '@media (max-width: 1000px)': {
      height: 'auto',
      minHeight: '50px',
    },
  },
  blockEmpty:{
    display: 'flex',
    position:'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    color: palette.primary.main,
    marginBottom: '10px',
    padding: '10px',
    background: palette.common.white,
    borderRadius: '5px',
  },
  tagBlock:{
    position:"absolute"
  },
  blockInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    minWidth: '70px',
    marginRight: '15px',
    color: '#58727F',
    fontSize: '12px',
    fontWeight: '300',
  },
  title: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',

    '@media (max-width: 1000px)': {
      overflow: 'inherit',
      display: 'block',
    },
  },
  arrowRight: {
    maxWidth: '20px',
    width: '100%',
    marginLeft: '15px',
    color: palette.secondary.main,
  },
}));

export default useStyles;
