import { makeStyles } from '@material-ui/styles';

import palette from '../../theme/palette';

const useStyles = makeStyles(() => ({
  section: {
    flex: '1 auto',
    paddingTop: 20,
    paddingBottom: 20,
    '@media (min-width: 480px)': {
      paddingTop: 20,
    },
    '@media (min-width: 768px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
    '@media (min-width: 1000px)': {
      paddingTop: 60,
      paddingBottom: 60,
    },
    '@media (min-width: 1440px)': {
      paddingTop: 35,
      paddingBottom: 35,
    },
  },
  container: {
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 300px)': {
      paddingLeft: '2%',
      paddingRight: '2%',
    },
    '@media (min-width: 768px)': {
      width: 700,
      paddingLeft:0,
      paddingRight:0
    },
    '@media (min-width: 1000px)': {
      width: 1000,
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1440px)': {
      width: 1260,
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  button: {
    // width: 120,
    // height: 40,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '21px',
    flexWrap: 'nowrap',
    fontSize: 14,
    padding: '9px 17px 10px 17px',
    margin: 10,
    marginLeft: 0,
    border: 'none',
  },
  buttonDis:{
    background:"#F4F5F9 !important",
  },
  amount: {
    fontSize: 14,
    fontWeight: 300,
    flewWrap: 'nowrap',
  },
  videoControlHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topButtonContainer: {
    display: 'flex',
    flexWrap: 'nowrap',

    '& :first-child': {
      marginRight: 5,
    },
    '& :last-child': {
      marginRight: 0,
    },
  },
  video:{
    position:'relative',
    display:'flex'
  },
  videoControl: {
    width: '100%',
    marginBottom:35,
    '@media (min-width: 768px)': {
      width: 700,
    },
    '@media (min-width: 1000px)': {
      width: 540,
    },
    '@media (min-width: 1440px)': {
      width: 740,
    },
  },
  chatModalButton: {
    borderRadius: '100%',
    width: 35,
    padding: 0,
    minWidth: 35,
    height: 35,
    marginLeft: 10,
  },
  bottomButtonRow: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  chatButtonSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  reverse: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: 136,
    marginBottom: 10,
  },
  programTitleText: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
  },
  programHeader: {
    height: 45,
    padding: '11px 20px 14px 20px',
    marginRight: 5,
    borderTop: `5px solid ${palette.secondary.main}`,
    borderRadius: '5px',
  },
  programListContainer: {
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 395,
  },
  programItemTime: {
    fontSize: 12,
    color: '#58727F',
  },
  programItemText: {
    color: '#294F5F',
    fontSize: 14,
    marginTop: 5,
    fontWeight: 300,
    lineHeight: '18px',
    marginBottom: 20,
  },
  scrollbars: {
    maxHeight: 360,
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '120px',
      color: 'transparent',
      background:
        'linear-gradient(0deg, #FBFBFD 0%, rgba(251, 251, 253, 0) 100%)',
      zIndex: 1,
    },
  },

  trackVertical: {
    height: '100%',
    top: 6,
    right: 0,
    display: 'none',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
  thumbVertical: {
    borderRadius: 4,
    background: palette.common.grey,
    display: 'none',

    '@media (min-width: 768px)': {
      display: 'block',
    },
  },
}));

export default useStyles;
